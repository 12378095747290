import React from "react";
import { useNavigate } from "react-router-dom";
import { wait } from "@testing-library/user-event/dist/utils";
import ConfirmActionModal from "../../../../../../components/modals/models/confirmAction";
import { useCompanyStore } from "../../../../../../configs/stores/company";
import { useCategoryStore } from "../../../../../../configs/stores/category";

const DeleteCategory = () => {
  const company = useCompanyStore((e) => e.company);
  const refetchCategories = useCompanyStore((e) => e.refetchCategories);
  const deleteCategory = useCategoryStore((e) => e.deleteCategory);
  const reset = useCategoryStore((e) => e.reset);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] =
    React.useState(false);
  const category = useCategoryStore((e) => e.category);
  function closeModal() {
    setOpenDeleteConfirmation(false);
  }
  const navigate = useNavigate();
  async function confirmAction() {
    await deleteCategory();
    return refetchCategories().then(async (t) => {
      closeModal();
      await wait(250);
      reset();
      navigate(`/company/${company?.company_slug ?? ""}`);
    });
  }
  return (
    <React.Fragment>
      <button
        onClick={() => setOpenDeleteConfirmation(true)}
        className="flex w-fit rounded-md px-5 py-[10px] text-[17px] font-medium bg-red-500/20 text-red-500 hover:bg-red-500/30 active:bg-red-500/50"
      >
        <span className="flex">
          Supprimer cette categorie ({category?.category_type.categorytype_name}
          )
        </span>
      </button>
      <ConfirmActionModal
        confirm={confirmAction}
        message="Voulez-vous supprimer cette categorie ?"
        open={openDeleteConfirmation}
        handleClose={closeModal}
      />
    </React.Fragment>
  );
};

export default DeleteCategory;
