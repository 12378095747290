import React from "react";
import ReactMapGL, { Marker, NavigationControl, Popup } from "react-map-gl";
import { primaryColor } from "../../../../constants";
import { useNavigate } from "react-router-dom";
import { useCompanyStore } from "../../../../configs/stores/company";

const GeolocationBloc = () => {
  const navigate = useNavigate()
  const [showPopup, setShowPopup] = React.useState(false);
  const company = useCompanyStore((e)=>e.company);
  let haveGeolocation =
    parseFloat(company?.company_geolocation.lat.toString()??"0") !== 0;
  const defaultPos = {
    longitude: -3.983821,
    latitude: 5.377342,
    zoom: 13,
  };
  const position = {
    latitude: parseFloat(company?.company_geolocation.lat.toString()??"0"),
    longitude: parseFloat(company?.company_geolocation.lng.toString()??"0"),
    zoom: parseFloat(company?.company_geolocation.zoom.toString()??"0"),
    ...(haveGeolocation ? {} : defaultPos),
  };

  const gotoGeolocation = ()=>{
    navigate("./geolocation")
  }

  return (
    <div
      style={{
        height: "35vh",
      }}
      className="flex max-h-[400px] max-w-[1200px] phone:max-w-[100vw] rounded-md bg-white border-[4px] border-white relative"
    >
      <ReactMapGL
        initialViewState={position}
        style={{ width: "100%", height: "100%", borderRadius: 6 }}
        mapboxAccessToken={process.env.REACT_APP_MapboxAccessToken}
        mapStyle="mapbox://styles/mapbox/streets-v9"
        attributionControl={false}
      >
        {showPopup && haveGeolocation && (
          <Popup
            longitude={position.longitude}
            latitude={position.latitude}
            anchor="top"
            onClose={() => setShowPopup(false)}
          >
            {company?.company_name}
          </Popup>
        )}
        {haveGeolocation && (
          <Marker
            color={primaryColor}
            longitude={position.longitude}
            latitude={position.latitude}
            anchor="top"
            onClick={() => setShowPopup(!showPopup)}
          />
        )}
        <NavigationControl />
      </ReactMapGL>
      <div
        style={{
          display: !haveGeolocation ? "flex" : "none",
        }}
        className="flex flex-1 z-10 absolute h-full w-full bg-black/20 justify-center items-center select-none"
      >
        <div className="flex bg-white p-5 rounded-md flex-col space-y-4 items-center">
          <span className="flex text-[18px]">
            Actualiser la géolocalisation de votre établissement
          </span>
          <button  onClick={gotoGeolocation} className="flex px-4 py-2 duration-150 transition-all rounded-md bg-primary active:bg-active hover:bg-hover">
            <span className="flex text-[16px] text-white font-medium">
              Géolocaliser maintenant
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default GeolocationBloc;
