import React from "react";
import CategoryNavItem from "./item";
import SelectedCategory from "./selectedCategory";
import { SubRoutes } from "../../../../contexts/dashboard/defaults/subroutes";

const CategoryNav = () => {
  return (
    <div className="flex flex-col flex-1">
      <SelectedCategory />
      <div
        className={`flex flex-col mt-1 max-h-[calc(100vh-190px)] overflow-y-auto`}
      >
        {SubRoutes.map(function (item, index) {
          return <CategoryNavItem key={index} item={item} />;
        })}
      </div>
    </div>
  );
};

export default CategoryNav;
