import React from "react";
import ModalCustom from "../../modal";
import { Button } from "@mui/material";
import {
  ToastErrorNotifier,
  ToastSuccessNotifier,
  primaryColor,
} from "../../../../constants";
import SimpleSelect from "../../../selects";
import { updateCompanyCurrencyData } from "../../../../contexts/dashboard/functions";
import { useBasicsStore } from "../../../../configs/stores/basics";
import { useCompanyStore } from "../../../../configs/stores/company";

const UpdateCurrency = ({ handleClose = () => {}, open = false }) => {
  const { currencies } = useBasicsStore();
  const company = useCompanyStore((e) => e.company);
  const refetch = useCompanyStore((e) => e.refetchRole);
  const [isLoading, setisLoading] = React.useState(false);
  const [formdata, setFormdata] = React.useState({
    company_currency: "",
  });
  const [closable, setClosable] = React.useState(true);
  function onValueChange(slug = "") {
    return (value: any) => {
      setFormdata((e) => ({ ...e, [slug]: value }));
    };
  }
  function close() {
    setFormdata({
      company_currency: "",
    });
    handleClose();
  }
  function checkForm() {
    let result = false;
    if (formdata.company_currency.length === 0) {
      result = true;
      ToastErrorNotifier({
        message: "Selectionner la nouvelle devise !",
        position: "top-right",
      });
    }
    return result;
  }
  function saveForm() {
    if (checkForm()) {
      return;
    }
    setisLoading(true);
    setClosable(false);
    updateCompanyCurrencyData({
      company_id: company?.company_id,
      company_currency: formdata.company_currency,
    }).then(async (reponse) => {
      if (reponse.success) {
        setClosable(true);
        await refetch();
        ToastSuccessNotifier({
          message: "Enregistrement reussie",
          position: "top-center",
          duration: 1000,
        });
        setisLoading(false);
        setTimeout(() => {
          handleClose();
        }, 700);
      }
    });
  }
  return (
    <ModalCustom open={open} handleClose={closable ? close : undefined}>
      <div className="flex flex-col w-[400px] phone:w-[calc(100vw-50px)] gap-5 px-4 pb-4">
        <span className="flex text-[20px] leading-tight text-darkgrey font-medium">
          Modifier la devise de l'etablissement
        </span>
        <div className="flex w-full flex-col gap-5">
          <SimpleSelect
            label="Selectionner une devise"
            defaultOptions={
              company?.company_currency
                ? {
                    label: company.company_currency.currency_name,
                    value: company.company_currency.currency_id,
                  }
                : undefined
            }
            options={currencies.map((e) => ({
              label: e.currency_name,
              value: e.currency_id,
            }))}
            onChange={(value) => {
              onValueChange("company_currency")(value?.value ?? "");
            }}
          />
        </div>
        <Button
          style={{
            backgroundColor: primaryColor,
            paddingBlock: 10,
            color: "white",
          }}
          sx={{
            px: 8,
          }}
          onClick={saveForm}
        >
          {!isLoading ? (
            <span className="flex font-medium">Enregistrer</span>
          ) : (
            <>
              <svg
                className="animate-spin h-6 w-6 text-black/50"
                xmlns="http://www.w3.org/2000/svg"
                fill={"transparent"}
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke={"white"}
                  strokeWidth="2"
                ></circle>
                <path
                  className="opacity-75"
                  fill={"white"}
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <span className="flex ml-3 normal-case">
                Patientez un instant
              </span>
            </>
          )}
        </Button>
      </div>
    </ModalCustom>
  );
};

export default UpdateCurrency;
