import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { useTableViewHook } from "./hook";

const TableHeader = () => {
  const {
    activeActions = false,
    checkTitle = "Click",
    checkable = false,
    columns = [],
  } = useTableViewHook();
  return (
    <TableHead>
      <TableRow>
        {checkable && (
          <TableCell
            padding="checkbox"
            align={"left"}
            sx={{ width: "fit" }}
            style={{
              fontWeight: "700",
            }}
          >
            {checkTitle}
          </TableCell>
        )}
        {columns.map((column, index) => (
          <TableCell
            key={column.slug}
            align={"left"}
            sx={{ width: "fit" }}
            style={{
              minWidth: column.minWidth,
              maxWidth: column.maxWidth,
              fontWeight: "700",
            }}
          >
            {column.label}
          </TableCell>
        ))}
        {activeActions && (
          <TableCell
            align={"right"}
            sx={{ width: "fit" }}
            style={{
              fontWeight: "700",
            }}
          >
            Actions
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
