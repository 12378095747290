import useLayoutDimension from "../../functions/useLayoutDimension";
import HeaderCompanies from "./header";
import CompaniesContainer from "./container";
import PhoneCompanies from "./phone";

const CompaniesPage = () => {
  const { height } = useLayoutDimension("#DashboardPageContentWrapper");
  return (
    <div className="flex flex-1 h-full flex-col">
      <div className="phone:hidden flex flex-1 h-full flex-col">
        <HeaderCompanies />
        <div className="flex flex-1 flex-row gap-x-1">
          {/* <Sidebar /> */}
          <div className="flex flex-1" id="DashboardPageContentWrapper">
            <div
              className="flex flex-col w-full overflow-y-scroll py-7 pb-16 px-5 pr-7"
              style={{
                maxHeight: height,
              }}
            >
              <CompaniesContainer />
            </div>
          </div>
        </div>
      </div>
      <div className="phone:flex hidden h-[100vh] items-center flex-col p-4">
        <PhoneCompanies />
      </div>
    </div>
  );
};

export default CompaniesPage;
