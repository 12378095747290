import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { produce } from "immer";
import { ZustandLocalStorageCrypted } from "./utils";
import { UserRoleModel } from "../../models/models";
import {
  getManagerRoles,
  newCompany,
  updateCompanyLogo,
} from "./functions/roles";
import { generateSlug } from "../../functions/generateSlug";
import { uploadFiles } from "../../functions/uploadFiles";
import {
  ToastErrorNotifier,
  ToastSuccessNotifier,
  generateImageMiniature,
} from "../../constants";
import { getPropValue } from "../../functions/getPropValue";

const LOCAL_DATASTORE_NAME = "coinchicpro-roles";

type ContextProps = {
  roles: Array<UserRoleModel>;
  isCreatingNewCompany: boolean;
  isLoadingRoles: boolean;
  isRefetchRoles: boolean;
  getRoles: () => Promise<any>;
  refetchRoles: () => Promise<any>;
  newCompany: (data: any) => Promise<boolean>;
};

export const useRolesStore = create<
  ContextProps,
  [["zustand/persist", ContextProps]]
>(
  persist(
    (set, get) => ({
      roles: Array<UserRoleModel>(),
      isCreatingNewCompany: false,
      isLoadingRoles: true,
      isRefetchRoles: false,
      getRoles: async () => {
        set(
          produce((state: ContextProps) => {
            state.isLoadingRoles = true;
          })
        );
        const res = await getManagerRoles();
        const data = (res?.data ??
          Array<UserRoleModel>()) as Array<UserRoleModel>;
        set(
          produce((state: ContextProps) => {
            state.roles = data;
            state.isLoadingRoles = false;
          })
        );
      },
      refetchRoles: async () => {
        set(
          produce((state: ContextProps) => {
            state.isRefetchRoles = true;
          })
        );
        const res = await getManagerRoles();
        const data = (res?.data ??
          Array<UserRoleModel>()) as Array<UserRoleModel>;
        set(
          produce((state: ContextProps) => {
            state.roles = data;
            state.isRefetchRoles = false;
          })
        );
      },
      newCompany: async (data) => {
        set(
          produce((state: ContextProps) => {
            state.isCreatingNewCompany = true;
          })
        );
        const state = get();
        const { manager, formdata, image } = data;
        const res = await newCompany({
          user_id: manager?.user_id,
          company: {
            company_name: formdata.company_name,
            company_slug: generateSlug(formdata.company_name),
            company_email_pro: formdata.company_email_pro,
          },
        });
        if (res.success) {
          let files: File[] = [];
          if (image?.file) {
            files.push(image.file);
          }
          const reponses = await uploadFiles(
            files,
            `LeCoinChicDuMoment/uploads/company/${res.data.role_company}/logo`
          );
          const dts = reponses.filter((r: any) => !!r.file_id);
          let resultReponse = "";
          if (dts.length !== 0) {
            let miniature = await generateImageMiniature(files[0], {
              width: 10,
              height: 10,
            });
            const imageData = {
              image_parent: res.data.role_company,
              image_parent_model: "company",
              image_miniature: miniature,
              image_link: getPropValue(dts[0], "download_url"),
              image_key: getPropValue(dts[0], "file_id"),
            };
            await updateCompanyLogo(imageData.image_parent, imageData);
            resultReponse = "Enregistrement reussie !";
          } else {
            resultReponse = "Enregistrement reussie, aucune image charger !";
          }
          await state.refetchRoles();
          ToastSuccessNotifier({ message: resultReponse, duration: 1000 });
        } else {
          ToastErrorNotifier({ message: res.message.toString() });
        }

        set(
          produce((state: ContextProps) => {
            state.isCreatingNewCompany = false;
          })
        );
        return res.success ? true : false;
      },
    }),
    {
      name: LOCAL_DATASTORE_NAME,
      storage: createJSONStorage(() => ZustandLocalStorageCrypted("dat_rls")),
    }
  )
);
