import React from "react";
import { Popover } from "@mui/material";
import AccountPopup from "./popup";
import { useAuthStore } from "../../../../configs/stores/auth";

const AccoutHead = () => {
  const manager = useAuthStore((e)=>e.manager);

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleAnchor = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className="group flex flex-row h-full pr-5 items-center space-x-2 select-none">
      {!!manager?.user_profile ? (
        <div
          className="flex h-[48px] w-[48px] cursor-pointer relative"
          onClick={handleAnchor}
        >
          <img
            className="flex flex-1 rounded-full overflow-hidden object-cover"
            src={manager.user_profile.image_link.toString()}
            alt="profile"
          />
          <div className="flex absolute bottom-[1px] ml-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="white"
              strokeWidth={3}
              className="hidden w-4 h-4 group-hover:flex group-hover:animate-bounce"
            >
              <path
                fillRule="evenodd"
                d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        </div>
      ) : (
        <div
          onClick={handleAnchor}
          className="flex rounded-full h-[48px] cursor-pointer w-[48px] bg-[rgba(150,150,150,.8)] justify-center items-center relative"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="white"
            className="w-7 h-7"
          >
            <path d="M10 8a3 3 0 100-6 3 3 0 000 6zM3.465 14.493a1.23 1.23 0 00.41 1.412A9.957 9.957 0 0010 18c2.31 0 4.438-.784 6.131-2.1.43-.333.604-.903.408-1.41a7.002 7.002 0 00-13.074.003z" />
          </svg>
          <div className="flex absolute bottom-[1px]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="white"
              strokeWidth={3}
              className="hidden w-4 h-4 group-hover:flex group-hover:animate-bounce"
            >
              <path
                fillRule="evenodd"
                d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        </div>
      )}
      <div className="flex flex-col justify-center gap-[2px] leading-tight h-[70%]">
        <span className="flex text-[15px] text-darkgrey font-medium">
          {manager?.user_name}
        </span>
        <span className="flex text-[15px] text-darkgrey font-medium">
          {manager?.user_surname}
        </span>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{
          marginTop: 2,
          padding: 0,
        }}
        elevation={3}
      >
        <AccountPopup close={handleClose} />
      </Popover>
    </div>
  );
};

export default AccoutHead;
