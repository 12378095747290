import { Outlet } from "react-router-dom";
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";

const DashboardPage = () => {
  return (
    <div className="flex flex-1 h-full flex-col">
      <Header />
      <div className="flex flex-1 flex-row space-x-1">
        <Sidebar />
        <div className="flex flex-1 flex-col h-full max-h-[calc(100vh-70px)] py-7 pb-10 px-5 phone:py-5 phone:px-3 phone:max-w-[100vw] border-t overflow-y-auto phone:max-h-fit phone:h-fit">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
