import React from "react";
import { useCategoryStore } from "../../../../../../configs/stores/category";

const ReservationState = () => {
  const { updateCategory, category } = useCategoryStore();
  const [isLoading, setIsLoading] = React.useState(false);
  function update() {
    setIsLoading(true);
    updateCategory({
      category_reservation_active: !category?.category_reservation_active,
    }).then(() => {
      setIsLoading(false);
    });
  }
  return (
    <div className="flex w-fit py-3 px-5 flex-row phone:flex-col phone:items-start phone:w-[calc(100vw-30px)] phone:space-y-3 phone:space-x-0 items-center bg-white rounded-[8px] space-x-10">
      <div className="flex flex-col gap-[2px]">
        <span className="flex text-[20px] font-medium">
          Status des réservations (
          {category?.category_reservation_active ? "Activé" : "Désactivé"})
        </span>
        <span className="flex text-[17px] leading-tight max-w-[500px] w-[50vw] min-w-[350px] phone:min-w-0 phone:w-auto phone:max-w-auto">
          Cette fonctionnalité vous permet a tout instant de "Désactiver" ou de
          "Réactiver" les réservations.
        </span>
      </div>
      <button
        onClick={!isLoading ? update : undefined}
        className="flex py-2 px-4 rounded-[10px] bg-primary active:bg-active hover:bg-hover"
      >
        <span className="flex text-[19px] font-semibold text-white">
          {!isLoading
            ? category?.category_reservation_active
              ? "Désactiver les réservations"
              : "Réactiver les réservations"
            : category?.category_reservation_active
            ? "Désactivation patientez..."
            : "Réactivation patientez..."}
        </span>
      </button>
    </div>
  );
};

export default ReservationState;
