import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { produce } from "immer";
import { ZustandLocalStorageCrypted } from "./utils";
import {
  LangModel,
  CurrencyModel,
  LocalityModel,
  UserRoleTypeModel,
  CategoryTypeModel,
} from "../../models/models";
import { getDefaultDatas } from "./functions/basics";

const LOCAL_DATASTORE_NAME = "coinchicpro-basics";

type ContextProps = {
  isLoading: boolean;
  isRefreshing: boolean;
  categoryTypes: Array<CategoryTypeModel>;
  langs: Array<LangModel>;
  currencies: Array<CurrencyModel>;
  countries: Array<LocalityModel>;
  roleTypes: Array<UserRoleTypeModel>;
  getAll: () => Promise<void>;
  refreshAll: () => Promise<void>;
};

export const useBasicsStore = create<
  ContextProps,
  [["zustand/persist", ContextProps]]
>(
  persist(
    (set, get) => ({
      isLoading: true,
      isRefreshing: false,
      langs: [],
      categoryTypes: [],
      countries: [],
      currencies: [],
      roleTypes: [],
      getAll: async () => {
        set(
          produce((state: ContextProps) => {
            state.isLoading = true;
          })
        );
        const res = await getDefaultDatas();
        const data = (res?.data ?? {
          langs: [],
          categoryTypes: [],
          localities: [],
          currencies: [],
          roleTypes: [],
        }) as {
          categoryTypes: Array<CategoryTypeModel>;
          langs: Array<LangModel>;
          currencies: Array<CurrencyModel>;
          localities: Array<LocalityModel>;
          roleTypes: Array<UserRoleTypeModel>;
        };
        set(
          produce((state: ContextProps) => {
            state.categoryTypes = data.categoryTypes;
            state.langs = data.langs;
            state.currencies = data.currencies;
            state.countries = data.localities;
            state.roleTypes = data.roleTypes;
            state.isLoading = false;
          })
        );
      },
      refreshAll: async () => {
        set(
          produce((state: ContextProps) => {
            state.isRefreshing = true;
          })
        );
        const res = await getDefaultDatas();
        const data = (res?.data ?? {
          langs: [],
          categoryTypes: [],
          localities: [],
          currencies: [],
          roleTypes: [],
        }) as {
          categoryTypes: Array<CategoryTypeModel>;
          langs: Array<LangModel>;
          currencies: Array<CurrencyModel>;
          localities: Array<LocalityModel>;
          roleTypes: Array<UserRoleTypeModel>;
        };
        set(
          produce((state: ContextProps) => {
            state.categoryTypes = data.categoryTypes;
            state.langs = data.langs;
            state.currencies = data.currencies;
            state.countries = data.localities;
            state.roleTypes = data.roleTypes;
            state.isRefreshing = false;
          })
        );
      },
    }),
    {
      name: LOCAL_DATASTORE_NAME,
      storage: createJSONStorage(() => ZustandLocalStorageCrypted("dat_bsc")),
    }
  )
);
