import React from "react";
import PageDescription from "../../../../../../components/descriptionCard/pageDescription";
import DeleteCategory from "./delete";
import UpdateDescription from "./updateDescription";

const CategorySetting = () => {
  return (
    <React.Fragment>
      <div className="flex h-auto flex-col space-y-5">
        <span className="flex text-[30px] phone:text-[25px] font-medium text-darkgrey">
          REGLAGES
        </span>
        <PageDescription
          title="Description de la page"
          description="Bienvenue sur la page des reglages."
        />
        <div className="flex flex-col space-y-6">
          <UpdateDescription />
          <DeleteCategory />
        </div>
      </div>
    </React.Fragment>
  );
};

export default CategorySetting;
