import React from "react";
import { useCategoryStore } from "../../../../../../configs/stores/category";

const ReservationState = () => {
  const { updateCategory } = useCategoryStore();
  const [isLoading, setIsLoading] = React.useState(false);
  function activate() {
    setIsLoading(true);
    updateCategory({ category_reservation_active: true }).then(() => {
      setIsLoading(false);
    });
  }
  return (
    <div className="flex w-fit py-3 px-5 flex-row items-center bg-white rounded-[8px] gap-5">
      <span className="flex text-[20px] font-medium">
        Réactiver les réservations
      </span>
      <button
        onClick={!isLoading ? activate : undefined}
        className="flex py-2 px-4 rounded-[10px] bg-primary active:bg-active hover:bg-hover"
      >
        <span className="flex text-[19px] font-semibold text-white">
          {!isLoading ? "Réactiver" : "Réactivation patientez..."}
        </span>
      </button>
    </div>
  );
};

export default ReservationState;
