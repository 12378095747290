import { TextField } from "@mui/material";
import React from "react";

function TextInput({
  label = "",
  placeholder = "",
  value = "",
  onTextChange = (e: string) => {},
  multiline = false,
  minlines = 2,
  maxlines = 4,
  ...args
}) {
  return (
    <TextField
      label={label}
      value={value ?? undefined}
      placeholder={placeholder ?? label}
      multiline={multiline}
      maxRows={maxlines}
      rows={minlines}
      size={"medium"}
      margin="none"
      onChange={(e) => {
        onTextChange(e.currentTarget.value);
      }}
      {...args}
    />
  );
}
export default TextInput;
