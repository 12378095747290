import React from "react";
import { MenuCardModel } from "../../../../../../models/models";
import ManageMenuCard from "../../../../../../components/modals/models/managemenucard";
import NewMenu from "../../../../../../components/modals/models/newmenu";
import { ToastWarnNotifier } from "../../../../../../constants";
import ConfirmActionModal from "../../../../../../components/modals/models/confirmAction";
import { useCompanyStore } from "../../../../../../configs/stores/company";
import { useCategoryStore } from "../../../../../../configs/stores/category";
import ViewMenuCard from "../../../../../../components/modals/models/viewmenucard";

const MenuCardItem = ({ item = Array<MenuCardModel>()[0] }) => {
  const company = useCompanyStore((e) => e.company);
  const deleteMenucard = useCategoryStore((e) => e.deleteMenucard);
  const getMenus = useCategoryStore((e) => e.getMenus);
  const menus_all = useCategoryStore((e) => e.menus);
  const menus =
    menus_all.find((e) => e.menucard === item.menucard_id)?.menus ?? [];
  const [openManage, setOpenManage] = React.useState(false);
  const [openView, setOpenView] = React.useState(false);
  const [openNewMenu, setOpenNewMenu] = React.useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] =
    React.useState(false);
  function handleCloseManage() {
    setOpenManage(false);
  }
  function handleCloseView() {
    setOpenView(false);
  }
  function handleCloseNewMenu() {
    setOpenNewMenu(false);
  }
  function closeModal() {
    setOpenDeleteConfirmation(false);
  }
  async function confirmAction() {
    await deleteMenucard(item.menucard_id);
    return closeModal();
  }
  function openNewMenuModal() {
    if (!!company?.company_currency) {
      setOpenNewMenu(true);
    } else {
      ToastWarnNotifier({
        message:
          "Action refuser, vous devez dabord selectionner la devise principale dans les parametres de votre etablissement",
      });
    }
  }

  React.useEffect(() => {
    getMenus(item.menucard_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <div className="flex relative select-none h-[150px] w-full flex-row phone:flex-col phone:h-auto phone:space-x-0 phone:space-y-3 bg-white border rounded-xl p-4 space-x-5">
        <img
          src={item.menucard_image.image_link.toString()}
          alt={item.menucard_name.toString()}
          className="flex h-[100px] w-[100px] phone:h-[80px] phone:w-[80px] rounded-full border object-cover"
        />
        <div className="flex flex-1 flex-col gap-y-3">
          <div className="flex flex-col flex-1 justify-center gap-y-1">
            <span className="flex text-[22px] font-medium line-clamp-1">
              {item.menucard_name}
            </span>
            <span className="flex text-[17px] ">
              {menus.length} menus enregistrees
            </span>
          </div>
          <div className="flex flex-row gap-5 pb-2 transition-colors duration-100">
            <button
              onClick={() => setOpenView(true)}
              className="flex px-5 py-2 gap-2 items-center border rounded-md hover:bg-hoverGrey active:bg-activeGrey"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="black"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
              <span className="flex text-[16px] phone:text-[14px]">Afficher la carte</span>
            </button>
            <button
              onClick={openNewMenuModal}
              className="flex px-5 py-2 gap-2 items-center flex-row rounded-md bg-primary text-white hover:bg-hover active:bg-active"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="white"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>
              <span className="flex text-[16px] phone:text-[14px]">Ajouter un menu</span>
            </button>
          </div>
        </div>
        <div
          onClick={() => setOpenManage(true)}
          className="flex absolute top-3 right-[65px] p-[6px] rounded-md border hover:bg-primary text-activeGrey hover:text-white active:bg-primary/75 transition-colors duration-100"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
            />
          </svg>
        </div>
        <div
          onClick={() => setOpenDeleteConfirmation(true)}
          className="flex absolute top-3 right-3 p-[6px] rounded-md border hover:bg-red-500 text-activeGrey hover:text-white active:bg-red-500/75 transition-colors duration-100"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
            />
          </svg>
        </div>
      </div>
      <ManageMenuCard
        open={openManage}
        handleClose={handleCloseManage}
        menucard={item}
      />
      <ViewMenuCard
        open={openView}
        handleClose={handleCloseView}
        menucard={item}
      />
      <NewMenu
        open={openNewMenu}
        handleClose={handleCloseNewMenu}
        menucard={item}
      />
      <ConfirmActionModal
        confirm={confirmAction}
        message="Voulez-vous suppprimer cette carte ?"
        open={openDeleteConfirmation}
        handleClose={closeModal}
      />
    </React.Fragment>
  );
};

export default MenuCardItem;
