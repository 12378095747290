import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { produce } from "immer";
import { ZustandLocalStorageCrypted } from "./utils";
import {
  CategoryModel,
  ConvenienceModel,
  ImageModel,
  MenuCardModel,
  MenuModel,
  PlaceModel,
  ReservationModel,
  ServiceModel,
} from "../../models/models";
import {
  deleteCategory,
  deleteCategoryConvenience,
  deleteCategoryImage,
  deleteCategoryMenu,
  deleteCategoryMenuCard,
  deleteCategoryPlace,
  deleteCategoryService,
  getCategoryById,
  getCategoryConveniences,
  getCategoryImages,
  getCategoryMenuCards,
  getCategoryMenus,
  getCategoryPlaces,
  getCategoryReservations,
  getCategoryServices,
  newCategoryConvenience,
  newCategoryImage,
  newCategoryMenu,
  newCategoryMenuCard,
  newCategoryPlace,
  newCategoryService,
  oneCategoryByCompanyIdAndCategoryType,
  updateCategory,
  updateCategoryDescription,
  updateCategoryImage,
  updateCategoryMenu,
  updateCategoryMenuCard,
  updateCategoryMenuCardImage,
  updateCategoryMenuImage,
  updateCategoryPlace,
  updateCategoryPlaceImage,
  updateCategoryService,
  updateCategoryServiceImage,
} from "./functions/category";
import { getStats } from "./functions/basics";
import {
  ToastErrorNotifier,
  ToastSuccessNotifier,
  generateImageMiniature,
} from "../../constants";
import { getPropValue } from "../../functions/getPropValue";
import { uploadFiles } from "../../functions/uploadFiles";

const LOCAL_DATASTORE_NAME = "coinchicpro-category";

type ContextProps = {
  stats: any;
  // Category
  isLoadingCategory: boolean;
  isRefetchCategory: boolean;
  category: CategoryModel | null;
  // Route
  selectedRoute: string;
  // Services
  isLoadingServices: boolean;
  isRefetchServices: boolean;
  services: Array<ServiceModel>;
  // Menucards
  isLoadingMenucards: boolean;
  isRefetchMenucards: boolean;
  menucards: Array<MenuCardModel>;
  // Menus
  menus: Array<{ menucard: string; menus: Array<MenuModel> }>;
  // Places
  isLoadingPlaces: boolean;
  isRefetchPlaces: boolean;
  places: Array<PlaceModel>;
  // Reservations
  isLoadingReservations: boolean;
  isRefetchReservations: boolean;
  reservations: Array<ReservationModel>;
  // Images
  isLoadingImages: boolean;
  isRefetchImages: boolean;
  images: Array<ImageModel>;
  // Conveniencies
  isLoadingConveniencies: boolean;
  isRefetchConveniencies: boolean;
  conveniencies: Array<ConvenienceModel>;
  // Category
  getCategory: (companyId: string, categoryType: string) => Promise<any>;
  refetchCategory: () => Promise<any>;
  refetchCategoryOnly: () => Promise<any>;
  deleteCategory: () => Promise<any>;
  updateCategoryDescription: (data: any) => Promise<any>;
  updateCategory: (update: any) => Promise<any>;
  // Stats
  getStats: () => Promise<any>;
  // RefetchAll
  refetchAll: () => Promise<any>;
  // Images
  getImages: () => Promise<any>;
  refetchImages: () => Promise<any>;
  newImage: (data: any) => Promise<any>;
  updateImage: (id: string, data: any) => Promise<any>;
  deleteImage: (data: any) => Promise<any>;
  // Services
  getServices: () => Promise<any>;
  refetchServices: () => Promise<any>;
  newService: (data: any) => Promise<any>;
  updateService: (id: string, data: any) => Promise<any>;
  updateServiceImage: (id: string, data: any) => Promise<any>;
  deleteService: (id: any) => Promise<any>;
  // Menucards
  getMenucards: () => Promise<any>;
  refetchMenucards: () => Promise<any>;
  newMenucard: (data: any) => Promise<any>;
  deleteMenucard: (id: any) => Promise<any>;
  updateMenucard: (id: string, data: any) => Promise<any>;
  updateMenucardImage: (id: string, data: any) => Promise<any>;
  // Menus
  getMenus: (id: any) => Promise<any>;
  newMenu: (data: any) => Promise<any>;
  deleteMenu: (id: any, card: string) => Promise<any>;
  updateMenu: (id: string, card: string, data: any) => Promise<any>;
  updateMenuImage: (id: string, card: string, data: any) => Promise<any>;
  // Places
  getPlaces: () => Promise<any>;
  refetchPlaces: () => Promise<any>;
  newPlace: (data: any) => Promise<any>;
  deletePlace: (id: any) => Promise<any>;
  updatePlace: (id: string, data: any) => Promise<any>;
  updatePlaceImages: (id: string, data: any) => Promise<any>;
  // Conveniences
  getConveniencies: () => Promise<any>;
  refetchConveniencies: () => Promise<any>;
  newCategoryConvenience: (id: string) => Promise<any>;
  deleteCategoryConvenience: (id: string) => Promise<any>;
  // Reservations
  getReservations: () => Promise<any>;
  manageLocalReservations: (
    manage: (reservations: Array<ReservationModel>) => Array<ReservationModel>
  ) => void;
  refetchReservations: () => Promise<any>;
  // Route
  selectRoute: (route: string) => void;
  // Reset
  reset: () => void;
};

export const useCategoryStore = create<
  ContextProps,
  [["zustand/persist", ContextProps]]
>(
  persist(
    (set, get) => ({
      // Category
      isLoadingCategory: true,
      isRefetchCategory: false,
      category: null,
      // Route
      selectedRoute: "",
      // Images
      isLoadingImages: true,
      isRefetchImages: false,
      images: [],
      // Services
      isLoadingServices: true,
      isRefetchServices: false,
      services: [],
      // Menucards
      isLoadingMenucards: true,
      isRefetchMenucards: false,
      menucards: [],
      // Menus
      menus: [],
      // Places
      isLoadingPlaces: true,
      isRefetchPlaces: false,
      places: [],
      // Reservations
      isLoadingReservations: true,
      isRefetchReservations: false,
      reservations: [],
      // Conveniencies
      isLoadingConveniencies: true,
      isRefetchConveniencies: false,
      conveniencies: [],
      // Stats
      stats: null,
      // Category
      getCategory: async (companyId, categoryType) => {
        const state = get();
        set(
          produce((state: ContextProps) => {
            state.isLoadingCategory = true;
          })
        );
        const res = await oneCategoryByCompanyIdAndCategoryType(
          companyId,
          categoryType
        );
        const data = (res?.data ?? {}) as CategoryModel;
        set(
          produce((state: ContextProps) => {
            state.category = data;
            state.isLoadingCategory = false;
          })
        );
        if (res.success) {
          await state.getStats();
        }
      },
      refetchCategory: async () => {
        const state = get();
        set(
          produce((state: ContextProps) => {
            state.isRefetchCategory = true;
          })
        );
        const res = await getCategoryById(
          state.category?.category_id.toString() ?? ""
        );
        const data = (res?.data ?? {}) as CategoryModel;
        set(
          produce((state: ContextProps) => {
            state.category = data;
            state.isRefetchCategory = false;
          })
        );
        if (res.success) {
          await state.getStats();
        }
      },
      refetchCategoryOnly: async () => {
        const state = get();
        set(
          produce((state: ContextProps) => {
            state.isRefetchCategory = true;
          })
        );
        const res = await getCategoryById(
          state.category?.category_id.toString() ?? ""
        );
        const data = (res?.data ?? {}) as CategoryModel;
        set(
          produce((state: ContextProps) => {
            state.category = data;
            state.isRefetchCategory = false;
          })
        );
      },
      deleteCategory: async () => {
        const state = get();
        const res = await deleteCategory(
          state.category?.category_id.toString() ?? ""
        );
        return res;
      },
      updateCategoryDescription: async (data: any) => {
        const state = get();
        const res = await updateCategoryDescription(data);
        if (res.success) {
          await state.refetchCategoryOnly();
        }
        return res;
      },
      updateCategory: async (update: any = {}) => {
        const state = get();
        const res = await updateCategory({
          category_id: state.category?.category_id,
          update,
        });
        if (res.success) {
          await state.refetchCategoryOnly();
        }
        return res;
      },
      // Route
      selectRoute: (route) => {
        set(
          produce((state: ContextProps) => {
            state.selectedRoute = route;
          })
        );
      },
      // Stats
      getStats: async () => {
        const state = get();
        const res = await getStats(
          state.category?.category_id.toString() ?? ""
        );
        set(
          produce((state: ContextProps) => {
            state.stats = res.data;
          })
        );
        return res;
      },
      // RefetchAll
      refetchAll: async () => {
        const state = get();
        state.refetchCategory();
        state.refetchPlaces();
        state.refetchReservations();
        state.refetchConveniencies();
        state.refetchImages();
        state.refetchMenucards();
        state.refetchServices();
      },
      // Images
      getImages: async () => {
        const state = get();
        set(
          produce((state: ContextProps) => {
            state.isLoadingImages = true;
          })
        );
        const res = await getCategoryImages(state.category?.category_id ?? "");
        const data = (res?.data ?? Array<ImageModel>()) as Array<ImageModel>;
        set(
          produce((state: ContextProps) => {
            state.images = data;
            state.isLoadingImages = false;
          })
        );
      },
      refetchImages: async () => {
        const state = get();
        set(
          produce((state: ContextProps) => {
            state.isRefetchImages = true;
          })
        );
        const res = await getCategoryImages(state.category?.category_id ?? "");
        const data = (res?.data ?? Array<ImageModel>()) as Array<ImageModel>;
        set(
          produce((state: ContextProps) => {
            state.images = data;
            state.isRefetchImages = false;
          })
        );
      },
      newImage: async (data: any) => {
        const state = get();
        await newCategoryImage(data);
        await state.refetchImages();
      },
      updateImage: async (id, data: any) => {
        const state = get();
        await updateCategoryImage(id, data);
        await state.refetchImages();
      },
      deleteImage: async (id: any) => {
        const state = get();
        await deleteCategoryImage(id);
        await state.refetchImages();
      },
      // Services
      getServices: async () => {
        const state = get();
        set(
          produce((state: ContextProps) => {
            state.isLoadingServices = true;
          })
        );
        const res = await getCategoryServices(
          state.category?.category_id ?? ""
        );
        const data = (res?.data ??
          Array<ServiceModel>()) as Array<ServiceModel>;
        set(
          produce((state: ContextProps) => {
            state.services = data;
            state.isLoadingServices = false;
          })
        );
      },
      refetchServices: async () => {
        const state = get();
        set(
          produce((state: ContextProps) => {
            state.isRefetchServices = true;
          })
        );
        const res = await getCategoryServices(
          state.category?.category_id ?? ""
        );
        const data = (res?.data ??
          Array<ServiceModel>()) as Array<ServiceModel>;
        set(
          produce((state: ContextProps) => {
            state.services = data;
            state.isRefetchServices = false;
          })
        );
      },
      newService: async (props: any) => {
        const state = get();
        const { data = {}, image = null } = props;
        if (!data || !image) {
          ToastErrorNotifier({
            message: "Veuillez renseigner tous les champs !",
          });
          return;
        }
        const res = await newCategoryService(data);
        if (res.success) {
          let files: File[] = [];
          if (image?.file) {
            files.push(image.file);
          }
          const reponses = await uploadFiles(
            files,
            `LeCoinChicDuMoment/uploads/company/${state.category?.category_company.company_id}/category/${state.category?.category_id}/services`
          );
          const dts = reponses.filter((r: any) => !!r.file_id);
          await Promise.all(
            files.map(async (file, index) => {
              let miniature = await generateImageMiniature(file, {
                width: 10,
                height: 10,
              });
              const imageData = {
                image_parent: res.data.service_id,
                image_parent_model: "service",
                image_miniature: miniature,
                image_link: getPropValue(dts[index], "download_url"),
                image_key: getPropValue(dts[index], "file_id"),
              };
              return await updateCategoryServiceImage(
                imageData.image_parent,
                imageData
              );
            })
          );
          await state.refetchServices();
          ToastSuccessNotifier({
            message: "Enregistrement reussie !",
            position: "top-center",
            duration: 1000,
          });
        } else {
          ToastErrorNotifier({ message: res.message.toString() });
        }
        return res;
      },
      updateService: async (id: string, props: any) => {
        const state = get();
        const data = props;
        if (!id || !data) {
          ToastErrorNotifier({
            message: "Veuillez renseigner tous les champs !",
          });
          return;
        }
        const res = await updateCategoryService(id, data);
        if (res.success) {
          await state.refetchServices();
          ToastSuccessNotifier({
            message: "Mise a jour reussie !",
            position: "top-center",
            duration: 700,
          });
        } else {
          ToastErrorNotifier({ message: res.message.toString() });
        }
        return res;
      },
      updateServiceImage: async (id: string, props: any) => {
        const state = get();
        const image = props;
        if (!id || !image) {
          ToastErrorNotifier({
            message: "Formulaire incorrecte",
          });
          return;
        }
        let files: File[] = [];
        if (image?.file) {
          files.push(image.file);
        }
        const uploadResponse = await uploadFiles(
          files,
          `LeCoinChicDuMoment/uploads/company/${state.category?.category_company.company_id}/category/${state.category?.category_id}/services`
        );
        const dts = uploadResponse.filter((r: any) => !!r.file_id);
        const ress = await Promise.all(
          files.map(async (file, index) => {
            let miniature = await generateImageMiniature(file, {
              width: 10,
              height: 10,
            });
            const imageData = {
              image_parent: id,
              image_parent_model: "service",
              image_miniature: miniature,
              image_link: getPropValue(dts[index], "download_url"),
              image_key: getPropValue(dts[index], "file_id"),
            };
            return await updateCategoryServiceImage(id, imageData);
          })
        );
        if (ress.length !== 0 && !!ress?.[0].success) {
          await state.refetchServices();
          ToastSuccessNotifier({
            message: "Enregistrement reussie !",
            position: "top-center",
            duration: 1000,
          });
        } else {
          ToastErrorNotifier({ message: ress?.[0].message.toString() });
        }
        return !!ress?.[0] ? ress[0] : null;
      },
      deleteService: async (id: any) => {
        const state = get();
        await deleteCategoryService(id);
        await state.refetchServices();
      },
      // Menucards
      getMenucards: async () => {
        const state = get();
        set(
          produce((state: ContextProps) => {
            state.isLoadingMenucards = true;
          })
        );
        const res = await getCategoryMenuCards(
          state.category?.category_id ?? ""
        );
        const data = (res?.data ??
          Array<MenuCardModel>()) as Array<MenuCardModel>;
        set(
          produce((state: ContextProps) => {
            state.menucards = data;
            state.isLoadingMenucards = false;
          })
        );
      },
      refetchMenucards: async () => {
        const state = get();
        set(
          produce((state: ContextProps) => {
            state.isRefetchMenucards = true;
          })
        );
        const res = await getCategoryMenuCards(
          state.category?.category_id ?? ""
        );
        const data = (res?.data ??
          Array<MenuCardModel>()) as Array<MenuCardModel>;
        set(
          produce((state: ContextProps) => {
            state.menucards = data;
            state.isRefetchMenucards = false;
          })
        );
      },
      newMenucard: async (props: any) => {
        const state = get();
        const { data = {}, image = [] } = props;
        if (!data || !image) {
          ToastErrorNotifier({
            message: "Veuillez renseigner tous les champs !",
          });
          return;
        }
        const res = await newCategoryMenuCard(data);
        if (res.success) {
          let files: File[] = [];
          if (image?.file) {
            files.push(image.file);
          }
          const reponses = await uploadFiles(
            files,
            `LeCoinChicDuMoment/uploads/company/${state.category?.category_company.company_id}/category/${state.category?.category_id}/menucards`
          );
          const dts = reponses.filter((r: any) => !!r.file_id);
          await Promise.all(
            files.map(async (file, index) => {
              let miniature = await generateImageMiniature(file, {
                width: 10,
                height: 10,
              });
              const imageData = {
                image_parent: res.data.menucard_id,
                image_parent_model: "menucard",
                image_miniature: miniature,
                image_link: getPropValue(dts[index], "download_url"),
                image_key: getPropValue(dts[index], "file_id"),
              };
              return await updateCategoryMenuCardImage(
                imageData.image_parent,
                imageData
              );
            })
          );
          await state.refetchMenucards();
          ToastSuccessNotifier({
            message: "Enregistrement reussie !",
            position: "top-center",
            duration: 1000,
          });
        } else {
          ToastErrorNotifier({ message: res.message.toString() });
        }
        return res;
      },
      deleteMenucard: async (id: any) => {
        const state = get();
        await deleteCategoryMenuCard(id);
        await state.refetchMenucards();
      },
      updateMenucard: async (id: string, props: any) => {
        const state = get();
        const data = props;
        if (!id || !data) {
          ToastErrorNotifier({
            message: "Veuillez renseigner tous les champs !",
          });
          return;
        }
        const res = await updateCategoryMenuCard(id, data);
        if (res.success) {
          await state.refetchMenucards();
          ToastSuccessNotifier({
            message: "Mise a jour reussie !",
            position: "top-center",
            duration: 700,
          });
        } else {
          ToastErrorNotifier({ message: res.message.toString() });
        }
        return res;
      },
      updateMenucardImage: async (id: string, props: any) => {
        const state = get();
        const image = props;
        if (!id || !image) {
          ToastErrorNotifier({
            message: "Formulaire incorrecte",
          });
          return;
        }
        let files: File[] = [];
        if (image?.file) {
          files.push(image.file);
        }
        const uploadResponse = await uploadFiles(
          files,
          `LeCoinChicDuMoment/uploads/company/${state.category?.category_company.company_id}/category/${state.category?.category_id}/menucards`
        );
        const dts = uploadResponse.filter((r: any) => !!r.file_id);
        const ress = await Promise.all(
          files.map(async (file, index) => {
            let miniature = await generateImageMiniature(file, {
              width: 10,
              height: 10,
            });
            const imageData = {
              image_parent: id,
              image_parent_model: "menucard",
              image_miniature: miniature,
              image_link: getPropValue(dts[index], "download_url"),
              image_key: getPropValue(dts[index], "file_id"),
            };
            return await updateCategoryMenuCardImage(id, imageData);
          })
        );
        if (ress.length !== 0 && !!ress?.[0].success) {
          await state.refetchMenucards();
          ToastSuccessNotifier({
            message: "Enregistrement reussie !",
            position: "top-center",
            duration: 1000,
          });
        } else {
          ToastErrorNotifier({ message: ress?.[0].message.toString() });
        }
        return !!ress?.[0] ? ress[0] : null;
      },
      // Menus
      getMenus: async (id: string) => {
        const res = await getCategoryMenus(id);
        const data = (res?.data ?? Array<MenuModel>()) as Array<MenuModel>;
        set(
          produce((state: ContextProps) => {
            state.menus = state.menus
              .filter((e) => e.menucard !== id)
              .concat({ menucard: id, menus: data });
          })
        );
      },
      newMenu: async (props: any) => {
        const state = get();
        const { data = {}, image = [] } = props;
        if (!data || !image) {
          ToastErrorNotifier({
            message: "Veuillez renseigner tous les champs !",
          });
          return;
        }
        const res = await newCategoryMenu(data);
        if (res.success) {
          let files: File[] = [];
          if (image?.file) {
            files.push(image.file);
          }
          const reponses = await uploadFiles(
            files,
            `LeCoinChicDuMoment/uploads/company/${state.category?.category_company.company_id}/category/${state.category?.category_id}/menucard/${data.menu_card}/menus`
          );
          const dts = reponses.filter((r: any) => !!r.file_id);
          await Promise.all(
            files.map(async (file, index) => {
              let miniature = await generateImageMiniature(file, {
                width: 10,
                height: 10,
              });
              const imageData = {
                image_parent: res.data.menu_id,
                image_parent_model: "menu",
                image_miniature: miniature,
                image_link: getPropValue(dts[index], "download_url"),
                image_key: getPropValue(dts[index], "file_id"),
              };
              return await updateCategoryMenuImage(
                imageData.image_parent,
                imageData
              );
            })
          );
          await state.getMenus(data.menu_card);
          ToastSuccessNotifier({
            message: "Enregistrement reussie !",
            position: "top-center",
            duration: 1000,
          });
        } else {
          ToastErrorNotifier({ message: res.message.toString() });
        }
        return res;
      },
      deleteMenu: async (id: any, card: string) => {
        const state = get();
        await deleteCategoryMenu(id);
        await state.getMenus(card);
      },
      updateMenu: async (id: string, card: string, props: any) => {
        const state = get();
        const data = props;
        if (!id || !data) {
          ToastErrorNotifier({
            message: "Veuillez renseigner tous les champs !",
          });
          return;
        }
        const res = await updateCategoryMenu(id, data);
        if (res.success) {
          await state.getMenus(card);
          ToastSuccessNotifier({
            message: "Mise a jour reussie !",
            position: "top-center",
            duration: 700,
          });
        } else {
          ToastErrorNotifier({ message: res.message.toString() });
        }
        return res;
      },
      updateMenuImage: async (id: string, card: string, props: any) => {
        const state = get();
        const image = props;
        if (!id || !image) {
          ToastErrorNotifier({
            message: "Formulaire incorrecte",
          });
          return;
        }
        let files: File[] = [];
        if (image?.file) {
          files.push(image.file);
        }
        const uploadResponse = await uploadFiles(
          files,
          `LeCoinChicDuMoment/uploads/company/${state.category?.category_company.company_id}/category/${state.category?.category_id}/menucard/${card}/menus`
        );
        const dts = uploadResponse.filter((r: any) => !!r.file_id);
        const ress = await Promise.all(
          files.map(async (file, index) => {
            let miniature = await generateImageMiniature(file, {
              width: 10,
              height: 10,
            });
            const imageData = {
              image_parent: id,
              image_parent_model: "menu",
              image_miniature: miniature,
              image_link: getPropValue(dts[index], "download_url"),
              image_key: getPropValue(dts[index], "file_id"),
            };
            return await updateCategoryMenuImage(id, imageData);
          })
        );
        if (ress.length !== 0 && !!ress?.[0].success) {
          await state.getMenus(card);
          ToastSuccessNotifier({
            message: "Enregistrement reussie !",
            position: "top-center",
            duration: 1000,
          });
        } else {
          ToastErrorNotifier({ message: ress?.[0].message.toString() });
        }
        return !!ress?.[0] ? ress[0] : null;
      },
      // Places
      getPlaces: async () => {
        const state = get();
        set(
          produce((state: ContextProps) => {
            state.isLoadingPlaces = true;
          })
        );
        const res = await getCategoryPlaces(state.category?.category_id ?? "");
        const data = (res?.data ?? Array<PlaceModel>()) as Array<PlaceModel>;
        set(
          produce((state: ContextProps) => {
            state.places = data;
            state.isLoadingPlaces = false;
          })
        );
      },
      refetchPlaces: async () => {
        const state = get();
        set(
          produce((state: ContextProps) => {
            state.isRefetchPlaces = true;
          })
        );
        const res = await getCategoryPlaces(state.category?.category_id ?? "");
        const data = (res?.data ?? Array<PlaceModel>()) as Array<PlaceModel>;
        set(
          produce((state: ContextProps) => {
            state.places = data;
            state.isRefetchPlaces = false;
          })
        );
      },
      newPlace: async (props: any) => {
        const state = get();
        const { data = {}, image = [] } = props;
        if (!data || !image) {
          ToastErrorNotifier({
            message: "Veuillez renseigner tous les champs !",
          });
          return;
        }
        const res = await newCategoryPlace(data);
        if (res.success) {
          let files: File[] = [];
          if (image?.file) {
            files.push(image.file);
          }
          const reponses = await uploadFiles(
            files,
            `LeCoinChicDuMoment/uploads/company/${state.category?.category_company.company_id}/category/${state.category?.category_id}/places`
          );
          const dts = reponses.filter((r: any) => !!r.file_id);
          await Promise.all(
            files.map(async (file, index) => {
              let miniature = await generateImageMiniature(file, {
                width: 10,
                height: 10,
              });
              const imageData = {
                image_parent: res.data.place_id,
                image_parent_model: "place",
                image_miniature: miniature,
                image_link: getPropValue(dts[index], "download_url"),
                image_key: getPropValue(dts[index], "file_id"),
              };
              return await updateCategoryPlaceImage(
                imageData.image_parent,
                imageData
              );
            })
          );
          await state.refetchPlaces();
          ToastSuccessNotifier({
            message: "Enregistrement reussie !",
            position: "top-center",
            duration: 1000,
          });
        } else {
          ToastErrorNotifier({ message: res.message.toString() });
        }
        return res;
      },
      deletePlace: async (id: any) => {
        const state = get();
        await deleteCategoryPlace(id);
        await state.refetchPlaces();
      },
      updatePlace: async (id: string, props: any) => {
        const state = get();
        const data = props;
        if (!id || !data) {
          ToastErrorNotifier({
            message: "Veuillez renseigner tous les champs !",
          });
          return;
        }
        const res = await updateCategoryPlace(id, data);
        if (res.success) {
          await state.refetchPlaces();
          ToastSuccessNotifier({
            message: "Mise a jour reussie !",
            position: "top-center",
            duration: 700,
          });
        } else {
          ToastErrorNotifier({ message: res.message.toString() });
        }
        return res;
      },
      updatePlaceImages: async (id: string, props: any) => {
        const state = get();
        const image = props;
        if (!id || !image) {
          ToastErrorNotifier({
            message: "Formulaire incorrecte",
          });
          return;
        }
        let files: File[] = [];
        if (image?.file) {
          files.push(image.file);
        }
        const uploadResponse = await uploadFiles(
          files,
          `LeCoinChicDuMoment/uploads/company/${state.category?.category_company.company_id}/category/${state.category?.category_id}/places`
        );
        const dts = uploadResponse.filter((r: any) => !!r.file_id);
        const ress = await Promise.all(
          files.map(async (file, index) => {
            let miniature = await generateImageMiniature(file, {
              width: 10,
              height: 10,
            });
            const imageData = {
              image_parent: id,
              image_parent_model: "place",
              image_miniature: miniature,
              image_link: getPropValue(dts[index], "download_url"),
              image_key: getPropValue(dts[index], "file_id"),
            };
            return await updateCategoryPlaceImage(id, imageData);
          })
        );
        if (ress.length !== 0 && !!ress?.[0].success) {
          await state.refetchPlaces();
          ToastSuccessNotifier({
            message: "Enregistrement reussie !",
            position: "top-center",
            duration: 1000,
          });
        } else {
          ToastErrorNotifier({ message: ress?.[0].message.toString() });
        }
        return !!ress?.[0] ? ress[0] : null;
      },
      // Reservations
      getReservations: async () => {
        const state = get();
        set(
          produce((state: ContextProps) => {
            state.isLoadingReservations = true;
          })
        );
        const res = await getCategoryReservations(
          state.category?.category_id ?? ""
        );
        const data = (res?.data ??
          Array<ReservationModel>()) as Array<ReservationModel>;
        set(
          produce((state: ContextProps) => {
            state.reservations = data;
            state.isLoadingReservations = false;
          })
        );
      },
      manageLocalReservations: (manage) => {
        const { reservations } = get();
        const managedReservations = manage(reservations);
        set(
          produce((state: ContextProps) => {
            state.reservations = managedReservations;
          })
        );
      },
      refetchReservations: async () => {
        const state = get();
        set(
          produce((state: ContextProps) => {
            state.isRefetchReservations = true;
          })
        );
        const res = await getCategoryReservations(
          state.category?.category_id ?? ""
        );
        const data = (res?.data ??
          Array<ReservationModel>()) as Array<ReservationModel>;
        set(
          produce((state: ContextProps) => {
            state.reservations = data;
            state.isRefetchReservations = false;
          })
        );
      },
      // Conveniencies
      getConveniencies: async () => {
        const state = get();
        set(
          produce((state: ContextProps) => {
            state.isLoadingConveniencies = true;
          })
        );
        const res = await getCategoryConveniences(
          state.category?.category_type.categorytype_id ?? ""
        );
        const data = (res?.data ??
          Array<ConvenienceModel>()) as Array<ConvenienceModel>;
        set(
          produce((state: ContextProps) => {
            state.conveniencies = data;
            state.isLoadingConveniencies = false;
          })
        );
      },
      refetchConveniencies: async () => {
        const state = get();
        set(
          produce((state: ContextProps) => {
            state.isRefetchConveniencies = true;
          })
        );
        const res = await getCategoryConveniences(
          state.category?.category_type.categorytype_id ?? ""
        );
        const data = (res?.data ??
          Array<ConvenienceModel>()) as Array<ConvenienceModel>;
        set(
          produce((state: ContextProps) => {
            state.conveniencies = data;
            state.isRefetchConveniencies = false;
          })
        );
      },
      newCategoryConvenience: async (id: string) => {
        const state = get();
        const res = await newCategoryConvenience(
          id,
          (state.category?.category_id ?? "").toString()
        );
        if (res.success) {
          await state.refetchCategoryOnly();
        }
      },
      deleteCategoryConvenience: async (id: string) => {
        const state = get();
        const res = await deleteCategoryConvenience(
          id,
          (state.category?.category_id ?? "").toString()
        );
        if (res.success) {
          await state.refetchCategoryOnly();
        }
      },
      // Reset
      reset: () => {
        set(
          produce((state) => {
            state.isLoadingCategory = true;
            state.isRefetchCategory = false;
            state.category = null;
            state.selectedRoute = "";
            state.isLoadingImages = true;
            state.isRefetchImages = false;
            state.images = [];
            state.isLoadingServices = true;
            state.isRefetchServices = false;
            state.services = [];
            state.isLoadingMenucards = true;
            state.isRefetchMenucards = false;
            state.menucards = [];
            state.menus = [];
            state.isLoadingReservations = true;
            state.isRefetchReservations = false;
            state.reservations = [];
            state.isLoadingPlaces = true;
            state.isRefetchPlaces = false;
            state.places = [];
            state.stats = null;
          })
        );
      },
    }),
    {
      name: LOCAL_DATASTORE_NAME,
      storage: createJSONStorage(() => ZustandLocalStorageCrypted("dat_ctg")),
    }
  )
);
