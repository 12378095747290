import React from "react";
import { MenuCardModel, MenuModel } from "../../../../models/models";
import ConfirmActionModal from "../confirmAction";
import { useCategoryStore } from "../../../../configs/stores/category";
import ManageMenu from "../managemenu";

type Props = {
  menu: MenuModel;
  card: MenuCardModel;
};

const MenuItem = ({ menu, card }: Props) => {
  const [openManageMenu, setOpenManageMenu] = React.useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] =
    React.useState(false);
  const deleteMenu = useCategoryStore((e) => e.deleteMenu);
  function closeManageModal() {
    setOpenManageMenu(false);
  }
  function closeModal() {
    setOpenDeleteConfirmation(false);
  }
  async function confirmAction() {
    await deleteMenu(menu.menu_id.toString(), card.menucard_id.toString());
    return closeModal();
  }
  return (
    <React.Fragment>
      <div className="flex relative select-none w-full flex-row bg-white rounded-xl gap-x-5">
        <img
          src={menu.menu_image.image_link.toString()}
          alt={menu.menu_name.toString()}
          className="flex h-[100px] w-[100px] phone:h-[80px] phone:w-[80px] rounded-[10px] border object-cover"
        />
        <div className="flex flex-1 flex-col gap-y-2">
          <div className="flex flex-col flex-1 justify-center space-y-1">
            <span className="text-[18px] leading-tight font-medium line-clamp-1">
              {menu.menu_name}
            </span>
            <span className="text-[15px] leading-tight line-clamp-2">
              {menu.menu_description}
            </span>
            <span className="flex text-[15px] font-medium leading-tight line-clamp-1">
              Prix : {menu.menu_price} FCFA
            </span>
          </div>
          <div className="flex flex-row space-x-4">
            <button
              onClick={() => setOpenDeleteConfirmation(true)}
              className="flex flex-row items-center text-black/50 hover:text-red-500 active:text-red-500/75"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-[18px] h-[18px]"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                />
              </svg>
              <span className="flex text-[14px]">Supprimer</span>
            </button>
            <button
              onClick={() => setOpenManageMenu(true)}
              className="flex flex-row items-center text-black/50 hover:text-primary active:text-primary/75"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-[18px] h-[18px]"
              >
                <path d="m5.433 13.917 1.262-3.155A4 4 0 0 1 7.58 9.42l6.92-6.918a2.121 2.121 0 0 1 3 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 0 1-.65-.65Z" />
                <path d="M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0 0 10 3H4.75A2.75 2.75 0 0 0 2 5.75v9.5A2.75 2.75 0 0 0 4.75 18h9.5A2.75 2.75 0 0 0 17 15.25V10a.75.75 0 0 0-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5Z" />
              </svg>
              <span className="flex text-[14px]">Modifier</span>
            </button>
          </div>
        </div>
      </div>
      <ManageMenu
        open={openManageMenu}
        handleClose={closeManageModal}
        menu={menu}
        menucard={card}
      />
      <ConfirmActionModal
        confirm={confirmAction}
        message="Voulez-vous suppprimer ce menu ?"
        open={openDeleteConfirmation}
        handleClose={closeModal}
      />
    </React.Fragment>
  );
};

export default MenuItem;
