import React from "react";
import PageDescription from "../../../../../../components/descriptionCard/pageDescription";
import SearchInTable from "../../../../../../components/searchInput/searchInTable";
import ServiceItem from "./item";
import NoData from "../../../../../../assets/noData.svg";
import { useCategoryStore } from "../../../../../../configs/stores/category";
import AddService from "./add";
import RefreshButton from "../../../../../../components/buttons/refeshButton";
import { useOneEffect } from "../../../../../../hooks/useOnEffect";

const CategoryServices = () => {
  const [searchInput, setsearchInput] = React.useState("");
  const services = useCategoryStore((e) => e.services);
  const refetchServices = useCategoryStore((e) => e.refetchServices);
  const getServices = useCategoryStore((e) => e.getServices);
  const isRefetchServices = useCategoryStore((e) => e.isRefetchServices);
  const isLoadingServices = useCategoryStore((e) => e.isLoadingServices);
  useOneEffect(() => {
    if (!isLoadingServices) {
      getServices();
    } else {
      refetchServices();
    }
  }, []);
  return (
    <div className="flex h-auto flex-col space-y-5">
      <span className="flex text-[30px] phone:text-[25px] font-medium text-darkgrey">
        SERVICES
      </span>
      <PageDescription
        title="Description de la page"
        description="Bienvenue sur la page de gestion des services ! 
        Cet espace est dédié aux administrateurs pour gérer les différentes services que votre établissement peut offrir."
      />
      <div className="flex flex-row flex-wrap items-end gap-4 text-[17px] mb-4">
        <SearchInTable
          placeholder="Entrer le nom du service rechercher..."
          onChange={setsearchInput}
        />
        <AddService />
        <RefreshButton
          refresh={refetchServices}
          isLoading={isRefetchServices}
        />
      </div>
      <div className="flex flex-col space-y-5 w-full max-w-[800px]">
        {services
          .filter((e) =>
            searchInput.length !== 0
              ? e.service_name.toLowerCase().includes(searchInput.toLowerCase())
              : true
          )
          .map((item, index) => {
            return <ServiceItem service={item} key={index} />;
          })}
        {services.length === 0 && (
          <div className="flex w-full py-10 pl-10 items-center flex-row ">
            <img src={NoData} className="flex w-[45%]" alt="NoData" />
          </div>
        )}
      </div>
    </div>
  );
};

export default CategoryServices;
