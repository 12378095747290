import React from "react";
import { UserRoleModel } from "../../models/models";
import { NavLink } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useCompanyStore } from "../../configs/stores/company";
import { useCategoryStore } from "../../configs/stores/category";

type Props = {
  role: UserRoleModel;
};

const CompanyItemPhone = ({ role }: Props) => {
  const company = useCompanyStore((e) => e.company);
  const reset = useCompanyStore((e) => e.reset);
  const resetCategory = useCategoryStore((e) => e.reset);

  function click() {
    if (company?.company_id !== role.role_company.company_id) {
      reset();
      resetCategory();
    }
  }

  return (
    <NavLink
      to={`/company/${role.role_company.company_slug}`}
      onClick={click}
      className="flex cursor-pointer flex-row justify-between items-center py-2 hover:shadow-sm hover:bg-darkgrey/10 active:bg-darkgrey/20 transition-all duration-300 rounded-md"
    >
      <div className="flex flex-row gap-3 items-center">
        <div className="flex h-[35px] w-[35px] bg-slate-300 rounded-full  overflow-hidden">
          <LazyLoadImage
            src={role.role_company.company_logo?.image_link.toString()}
            alt={role.role_company.company_logo?.image_id.toString()}
            className="flex  h-[35px] w-[35px] rounded-full overflow-hidden"
            placeholderSrc={role.role_company.company_logo?.image_miniature.toString()}
            height={35}
            width={35}
            effect="blur"
          />
        </div>
        <div className="flex flex-col">
          <span className="flex text-[16px] font-medium">
            {role.role_company.company_name}
          </span>
          <span className="line-clamp-1 text-[13px]">
            {role.role_type.roletype_name}
          </span>
        </div>
      </div>
    </NavLink>
  );
};

export default CompanyItemPhone;
