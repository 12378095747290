import React from "react";
import PageDescription from "../../../../components/descriptionCard/pageDescription";
import SearchInTable from "../../../../components/searchInput/searchInTable";
import CategoryItem from "./item";
import NewCategoryModel from "../../../../components/modals/models/newcategory";
import { useBasicsStore } from "../../../../configs/stores/basics";
import { useCompanyStore } from "../../../../configs/stores/company";

const DashCategories = () => {
  const { categoryTypes } = useBasicsStore();
  const categories = useCompanyStore((e) => e.categories);
  const [searchInput, setsearchInput] = React.useState("");
  const [open, setOpen] = React.useState(false);
  function HandleClose() {
    setOpen(false);
  }
  return (
    <div className="flex h-auto flex-col space-y-5">
      <span className="flex text-[30px] phone:text-[25px] font-medium text-darkgrey">
        CATEGORIES
      </span>
      <PageDescription
        title="Description de la page"
        description="Bienvenue sur la page de gestion des catégories 
        d'établissements ! Cet espace est dédié aux administrateurs pour 
        gérer les différentes catégories auxquelles nos établissements 
        peuvent être associés. Vous pouvez ici ajouter de nouvelles 
        catégories pertinentes, modifier les catégories existantes ou 
        supprimer celles qui ne sont plus nécessaires. Cela permettra de 
        mieux organiser notre base de données et faciliter la recherche pour 
        nos utilisateurs."
      />
      <div className="flex flex-row items-end phone:items-start phone:flex-col space-x-4 phone:space-x-0 phone:space-y-5">
        <SearchInTable
          placeholder="Entrer le type de la categorie rechercher..."
          onChange={setsearchInput}
        />
        {categoryTypes.filter(
          (e) =>
            !categories
              .map((item) => item.category_type.categorytype_id)
              .includes(e.categorytype_id)
        ).length !== 0 && (
          <button
            onClick={() => setOpen(true)}
            className="flex cursor-pointer flex-row gap-3 p-3 py-[10px] px-5 rounded-md items-center text-white fill-white bg-primary active:bg-active hover:bg-hover"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-6 h-6"
            >
              <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
            </svg>
            <span className="flex font-medium text-[17px]">
              Ajouter une categorie
            </span>
          </button>
        )}
      </div>
      <div className="flex flex-row flex-wrap gap-7">
        {categories
          .filter((item) =>
            searchInput.length !== 0
              ? item.category_type.categorytype_name
                  .toString()
                  .includes(searchInput.toString())
              : true
          )
          .map((category, index) => {
            return <CategoryItem key={index} category={category} />;
          })}
      </div>
      <NewCategoryModel handleClose={HandleClose} open={open} />
    </div>
  );
};

export default DashCategories;
