import React from "react";

const PageDescription = ({ title = "", description = "" }) => {
  return (
    <div className="flex flex-col h-fit py-[13px] px-4 gap-1 rounded-[8px] w-fit phone:max-w-[calc(100vw-30px)] bg-white">
      <span className="flex text-[14.5px] font-normal leading-snug text-darkgrey/90">{description}</span>
    </div>
  );
};

export default PageDescription;
