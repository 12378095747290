import CompanyNavBar from "./companyNav";
import CompanyCategories from "./categories";
import CategoryNav from "./categoryNav";
import { useCategoryStore } from "../../configs/stores/category";

const Sidebar = () => {
  const category = useCategoryStore((e) => e.category);
  return (
    <div
      id="sidebar"
      className="flex phone:hidden flex-col h-full min-w-[270px] max-w-[350px] w-[18vw] select-none border-t bg-white"
    >
      <CompanyNavBar />
      <div className="flex flex-col flex-1">
        {!category && <CompanyCategories />}
        {!!category && <CategoryNav />}
      </div>
    </div>
  );
};

export default Sidebar;
