import MissingPage from "../../../pages/missing";
import LoadingPage from "../../../pages/loading";
import DashWelcome from "../../../pages/dashboard/pages/welcome";
import DashCategories from "../../../pages/dashboard/pages/categories";
import DashManagers from "../../../pages/dashboard/pages/mangers";
import DashGeolocalisation from "../../../pages/dashboard/pages/geolocalisation";
import DashStatistiques from "../../../pages/dashboard/pages/statistiques";
import DashSettings from "../../../pages/dashboard/pages/settings";
import CategoryWelcome from "../../../pages/dashboard/pages/categoryDash/pages/welcome";
import CategoryPhotos from "../../../pages/dashboard/pages/categoryDash/pages/photos";
import CategoryReservations from "../../../pages/dashboard/pages/categoryDash/pages/reservations";
import CategoryMenuCards from "../../../pages/dashboard/pages/categoryDash/pages/menucards";
import CategoryServices from "../../../pages/dashboard/pages/categoryDash/pages/services";
import CategoryCustomerReview from "../../../pages/dashboard/pages/categoryDash/pages/customerreview";
import CategorySetting from "../../../pages/dashboard/pages/categoryDash/pages/setting";
import CategoryBillingMethods from "../../../pages/dashboard/pages/categoryDash/pages/billing";
import CategoryConveniences from "../../../pages/dashboard/pages/categoryDash/pages/conveniences";
import CategoryPlaces from "../../../pages/dashboard/pages/categoryDash/pages/places";

export interface SubRouteModel {
  index?: boolean;
  path?: string;
  name?: string;
  icon?: JSX.Element | string;
  element?: JSX.Element;
  showSidebar?: boolean;
  showInSidebar?: boolean;
  isGenerativeRoute?: boolean;
  authorizedRoles?: string[];
  children?: Array<SubRouteModel>;
}

export const SubRoutes: Array<SubRouteModel> = [
  {
    path: "welcome",
    name: "Accueil",
    showSidebar: true,
    showInSidebar: true,
    authorizedRoles: ["*"],
    element: undefined,
    children: [
      {
        path: "",
        name: "any",
        showSidebar: true,
        showInSidebar: false,
        authorizedRoles: ["*"],
        element: <CategoryWelcome />,
      },
    ],
  },
  {
    path: "bookings",
    name: "Réservations",
    showSidebar: true,
    showInSidebar: true,
    authorizedRoles: ["*"],
    element: undefined,
    children: [
      {
        path: "",
        name: "any",
        showSidebar: true,
        showInSidebar: false,
        authorizedRoles: ["*"],
        element: <CategoryReservations />,
      },
    ],
  },
  {
    path: "places",
    name: "Places",
    showSidebar: true,
    showInSidebar: true,
    authorizedRoles: ["*"],
    element: undefined,
    children: [
      {
        path: "",
        name: "any",
        showSidebar: true,
        showInSidebar: false,
        authorizedRoles: ["*"],
        element: <CategoryPlaces />,
      },
    ],
  },
  {
    path: "menucards",
    name: "Carte de menu",
    showSidebar: true,
    showInSidebar: true,
    authorizedRoles: ["*"],
    element: undefined,
    children: [
      {
        path: "",
        name: "any",
        showSidebar: true,
        showInSidebar: false,
        authorizedRoles: ["*"],
        element: <CategoryMenuCards />,
      },
    ],
  },
  {
    path: "services",
    name: "Services",
    showSidebar: true,
    showInSidebar: true,
    authorizedRoles: ["*"],
    element: undefined,
    children: [
      {
        path: "",
        name: "any",
        showSidebar: true,
        showInSidebar: false,
        authorizedRoles: ["*"],
        element: <CategoryServices />,
      },
    ],
  },
  {
    path: "conveniences",
    name: "Commodités",
    showSidebar: true,
    showInSidebar: true,
    authorizedRoles: ["*"],
    element: undefined,
    children: [
      {
        path: "",
        name: "any",
        showSidebar: true,
        showInSidebar: false,
        authorizedRoles: ["*"],
        element: <CategoryConveniences />,
      },
    ],
  },
  {
    path: "photo",
    name: "Photos",
    showSidebar: true,
    showInSidebar: true,
    authorizedRoles: ["*"],
    element: undefined,
    children: [
      {
        path: "",
        name: "any",
        showSidebar: true,
        showInSidebar: false,
        authorizedRoles: ["*"],
        element: <CategoryPhotos />,
      },
    ],
  },
  {
    path: "customerreview",
    name: "Avis clients",
    showSidebar: true,
    showInSidebar: true,
    authorizedRoles: ["*"],
    element: undefined,
    children: [
      {
        path: "",
        name: "any",
        showSidebar: true,
        showInSidebar: false,
        authorizedRoles: ["*"],
        element: <CategoryCustomerReview />,
      },
    ],
  },
  {
    path: "billing",
    name: "Facturation",
    showSidebar: true,
    showInSidebar: true,
    authorizedRoles: ["*"],
    element: undefined,
    children: [
      {
        path: "",
        name: "any",
        showSidebar: true,
        showInSidebar: false,
        authorizedRoles: ["*"],
        element: <CategoryBillingMethods />,
      },
    ],
  },
  {
    path: "settings",
    name: "Reglage",
    showSidebar: true,
    showInSidebar: true,
    authorizedRoles: ["*"],
    element: undefined,
    children: [
      {
        path: "",
        name: "any",
        showSidebar: true,
        showInSidebar: false,
        authorizedRoles: ["*"],
        element: <CategorySetting />,
      },
    ],
  },
];

export const CompanyRoutes: Array<SubRouteModel> = [
  {
    path: "",
    name: "Tableau de bord",
    showSidebar: true,
    showInSidebar: true,
    authorizedRoles: ["*"],
    element: undefined,
    children: [
      {
        path: "",
        name: "any",
        showSidebar: true,
        showInSidebar: false,
        authorizedRoles: ["*"],
        element: <DashWelcome />,
      },
    ],
  },
  {
    path: "categories",
    name: "Categories",
    showSidebar: true,
    showInSidebar: true,
    authorizedRoles: ["*"],
    element: undefined,
    children: [
      {
        path: "",
        name: "any",
        showSidebar: true,
        showInSidebar: false,
        authorizedRoles: ["*"],
        element: <DashCategories />,
      },
    ],
  },
  {
    path: "team",
    name: "Gestionnaires",
    showSidebar: true,
    showInSidebar: true,
    authorizedRoles: ["*"],
    element: undefined,
    children: [
      {
        path: "",
        name: "any",
        showSidebar: true,
        showInSidebar: false,
        authorizedRoles: ["*"],
        element: <DashManagers />,
      },
    ],
  },
  {
    path: "geolocation",
    name: "Geolocalisation",
    showSidebar: true,
    showInSidebar: true,
    authorizedRoles: ["*"],
    element: undefined,
    children: [
      {
        path: "",
        name: "any",
        showSidebar: true,
        showInSidebar: false,
        authorizedRoles: ["*"],
        element: <DashGeolocalisation />,
      },
    ],
  },
  {
    path: "stats",
    name: "Statistiques",
    showSidebar: true,
    showInSidebar: true,
    authorizedRoles: ["*"],
    element: undefined,
    children: [
      {
        path: "",
        name: "any",
        showSidebar: true,
        showInSidebar: false,
        authorizedRoles: ["*"],
        element: <DashStatistiques />,
      },
    ],
  },
  {
    path: "settings",
    name: "Parametres",
    showSidebar: true,
    showInSidebar: true,
    authorizedRoles: ["*"],
    element: undefined,
    children: [
      {
        path: "",
        name: "any",
        showSidebar: true,
        showInSidebar: false,
        authorizedRoles: ["*"],
        element: <DashSettings />,
      },
    ],
  },
];

export const DefaultSubRoutes: Array<SubRouteModel> = [
  {
    path: "",
    showSidebar: false,
    showInSidebar: false,
    authorizedRoles: ["*"],
    element: <LoadingPage message="Quelques secondes s'il vous plait..." />,
  },
  {
    path: "*",
    showSidebar: false,
    showInSidebar: false,
    authorizedRoles: ["*"],
    element: (
      <MissingPage>
        <div className="flex flex-col">
          <button className="flex mt-5 px-3 py-2 rounded-md bg-white shadow-sm">
            Retourner au Tableau de bord
          </button>
        </div>
      </MissingPage>
    ),
  },
];
