import React from "react";
import ModalCustom from "../../modal";
import { MenuCardModel } from "../../../../models/models";
import NewMenu from "../newmenu";
import NoData from "../../../../assets/noData.svg";
import MenuItem from "./item";
import { useCategoryStore } from "../../../../configs/stores/category";
import ConfirmActionModal from "../confirmAction";
import clsx from "clsx";

type Props = {
  handleClose: () => void;
  open: boolean;
  menucard: MenuCardModel;
};

const ViewMenuCard = ({ handleClose, open = false, menucard }: Props) => {
  const deleteMenucard = useCategoryStore((e) => e.deleteMenucard);
  const [isRefreshing, setIsRefreshing] = React.useState(false);
  const getMenus = useCategoryStore((e) => e.getMenus);
  const menus_all = useCategoryStore((e) => e.menus);
  const menus =
    menus_all.find((e) => e.menucard === menucard.menucard_id)?.menus ?? [];
  const [searchInput, setSearchInput] = React.useState("");
  const [openNewMenu, setOpenNewMenu] = React.useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] =
    React.useState(false);
  function handleCloseNewMenu() {
    setOpenNewMenu(false);
  }
  const [closable] = React.useState(true);
  function close() {
    handleClose();
  }
  function closeModal() {
    setOpenDeleteConfirmation(false);
  }
  async function confirmAction() {
    await deleteMenucard(menucard.menucard_id);
    closeModal();
    return close();
  }
  function refresh() {
    if (isRefreshing) {
      return;
    }
    setIsRefreshing(true);
    getMenus(menucard.menucard_id).then(() => {
      setIsRefreshing(false);
    });
  }
  return (
    <ModalCustom open={open} handleClose={closable ? close : undefined}>
      <React.Fragment>
        <div className="flex flex-col max-h-[80vh] min-w-[700px] w-[40vw] max-w-[1000px] phone:min-w-0 phone:w-[calc(100vw-30px)] phone:space-y-3 space-y-5 px-4">
          <span className="flex text-[20px] text-darkgrey font-medium">
            CARTE DE MENU
          </span>
          <div className="flex w-full flex-col gap-4">
            <div className="flex relative select-none w-full flex-row bg-white rounded-xl gap-x-5">
              <img
                src={menucard.menucard_image.image_link.toString()}
                alt={menucard.menucard_name.toString()}
                className="flex h-[90px] w-[90px] rounded-full border object-cover"
              />
              <div className="flex flex-1 flex-col gap-y-3">
                <div className="flex flex-col flex-1 justify-center gap-y-1">
                  <span className="flex text-[20px] leading-tight font-medium line-clamp-1">
                    {menucard.menucard_name}
                  </span>
                  <span className=" text-[15px] leading-tight line-clamp-2">
                    {menucard.menucard_description}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex flex-row gap-4 pt-1 transition-colors duration-100">
              <button
                onClick={() => setOpenDeleteConfirmation(true)}
                className="flex px-3 py-2 gap-2 items-center flex-row rounded-md bg-red-500/0 border text-black hover:text-white hover:bg-red-500/90 active:bg-red-500/100"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                  />
                </svg>
              </button>
              <div className="flex px-5 py-2 gap-2 items-center flex-row rounded-md border">
                <span className="flex text-[17px] text-black">
                  {menus.length} menus
                </span>
              </div>
              <button
                onClick={refresh}
                className="flex px-4 py-2 gap-2 items-center flex-row rounded-md bg-primary text-white hover:bg-hover active:bg-active"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2.5}
                  stroke="currentColor"
                  className={clsx("w-6 h-6", isRefreshing && "animate-spin")}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                  />
                </svg>
                <span className="flex text-[16px]">Rafraichir</span>
              </button>
            </div>
            <div className="flex flex-row gap-x-4">
              <div className="flex flex-1 items-center flex-row gap-3 border rounded-md py-3 px-3 bg-grey">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="w-5 h-5 stroke-darkgrey/50"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                  />
                </svg>
                <input
                  className="flex flex-1 h-full outline-none bg-transparent"
                  placeholder={"Rechercher un menu "}
                  onChange={(e) => {
                    setSearchInput(e.currentTarget?.value ?? "");
                  }}
                />
              </div>
              <button
                onClick={() => setOpenNewMenu(true)}
                className="flex px-5 py-2 gap-2 items-center flex-row rounded-md bg-primary text-white hover:bg-hover active:bg-active"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="white"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 4.5v15m7.5-7.5h-15"
                  />
                </svg>
                <span className="flex text-[16px] phone:hidden">Ajouter un menu</span>
              </button>
            </div>
            <div className="flex flex-col space-y-5 h-[40vh] pt-2 pb-4 min-h-[300px] max-h-[700px] overflow-y-auto">
              {menus
                .filter((e) =>
                  searchInput.length !== 0
                    ? e.menu_name
                        .toLowerCase()
                        .includes(searchInput.toLowerCase())
                    : true
                )
                .map((menu) => {
                  return (
                    <MenuItem
                      key={menu.menu_id.toString()}
                      menu={menu}
                      card={menucard}
                    />
                  );
                })}
              {(menus.length === 0 ||
                menus.filter((e) =>
                  searchInput.length !== 0
                    ? e.menu_name
                        .toLowerCase()
                        .includes(searchInput.toLowerCase())
                    : true
                ).length === 0) && (
                <div className="flex flex-1 flex-col gap-3 items-center justify-center">
                  <img src={NoData} alt="menusEmpty" className="flex w-[30%]" />
                  <span className="flex text-[15px] text-black">Vide</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <NewMenu
          open={openNewMenu}
          handleClose={handleCloseNewMenu}
          menucard={menucard}
        />
        <ConfirmActionModal
          confirm={confirmAction}
          message="Voulez-vous suppprimer cette carte ?"
          open={openDeleteConfirmation}
          handleClose={closeModal}
        />
      </React.Fragment>
    </ModalCustom>
  );
};

export default ViewMenuCard;
