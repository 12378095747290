import React from "react";
import { useCategoryStore } from "../../../../../../../configs/stores/category";
import WaitReservation from "./items/wait";
import { useCategoryReservationStore } from "../../../../../../../configs/stores/reservations";
import { ReservationModel } from "../../../../../../../models/models";

const AllWaitReservation = () => {
  const searchInput = useCategoryReservationStore((e) => e.searchInput);
  const reservations = useCategoryStore((e) => e.reservations);
  function searchReservation(e: ReservationModel) {
    return searchInput.length === 0
      ? true
      : e.reservation_user.user_name
          .toLowerCase()
          .includes(searchInput.toLowerCase()) ||
          e.reservation_user.user_surname
            .toLowerCase()
            .includes(searchInput.toLowerCase());
  }
  const EmptyView = () => {
    return (
      <div className="flex border py-4 justify-center">
        <span className="flex text-[18px]">Aucune réservation enregistrée</span>
      </div>
    );
  };
  return (
    <>
      <div className="flex sticky top-0 min-h-[50px] h-[50px] pl-4 items-center bg-black/30">
        <span className="flex text-white text-[20px]">
          Réservations en attentent
        </span>
      </div>
      <div className="flex flex-col px-4 py-4 space-y-4">
        {reservations
          .filter((e) => e.reservation_state === "wait")
          .filter(searchReservation).length !== 0 ? (
          reservations
            .filter((e) => e.reservation_state === "wait")
            .filter(searchReservation)
            .map((e) => {
              return (
                <WaitReservation
                  key={e.reservation_id.toString()}
                  reservation={e}
                />
              );
            })
        ) : (
          <EmptyView />
        )}
      </div>
    </>
  );
};

export default AllWaitReservation;
