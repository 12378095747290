import React from "react";
import PageDescription from "../../../../../../components/descriptionCard/pageDescription";
import SearchInTable from "../../../../../../components/searchInput/searchInTable";
import AddMenuCard from "./add";
import MenuCardItem from "./item";
import NoData from "../../../../../../assets/noData.svg";
import { useCategoryStore } from "../../../../../../configs/stores/category";
import { useOneEffect } from "../../../../../../hooks/useOnEffect";
import RefreshButton from "../../../../../../components/buttons/refeshButton";

const CategoryMenuCards = () => {
  const [searchInput, setsearchInput] = React.useState("");
  const menucards = useCategoryStore((e) => e.menucards);
  const refetchMenucards = useCategoryStore((e) => e.refetchMenucards);
  const getMenucards = useCategoryStore((e) => e.getMenucards);
  const isRefetchMenucards = useCategoryStore((e) => e.isRefetchMenucards);
  const isLoadingServices = useCategoryStore((e) => e.isLoadingServices);
  useOneEffect(() => {
    if (!isLoadingServices) {
      getMenucards();
    } else {
      refetchMenucards();
    }
  }, []);
  return (
    <div className="flex h-auto flex-col gap-6">
      <span className="flex text-[30px] font-medium text-darkgrey">
        CARTES DE MENU
      </span>
      <PageDescription
        title="Description de la page"
        description="Bienvenue sur la page de gestion des cartes menus de reservations ! 
        Cet espace est dédié aux administrateurs pour gérer les différentes cartes menus disponibles dans votre établissement."
      />
      <div className="flex flex-row flex-wrap items-end gap-4 text-[17px] mb-4">
        <SearchInTable
          placeholder="Entrer le nom de la carte rechercher..."
          onChange={setsearchInput}
        />
        <AddMenuCard />
        <RefreshButton
          refresh={refetchMenucards}
          isLoading={isRefetchMenucards}
        />
      </div>
      <div className="flex flex-col gap-5 w-full max-w-[800px]">
        {menucards
          .filter((e) =>
            searchInput.length !== 0
              ? e.menucard_name.includes(searchInput)
              : true
          )
          .map((item, index) => {
            return <MenuCardItem item={item} key={index} />;
          })}
        {menucards.length === 0 && (
          <div className="flex w-full py-10 pl-10 items-center flex-row ">
            <img src={NoData} className="flex w-[45%]" alt="NoData" />
          </div>
        )}
      </div>
    </div>
  );
};

export default CategoryMenuCards;
