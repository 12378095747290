import forge from "node-forge";
import pako from "pako";
import Resizer from "react-image-file-resizer";
import { ToastPosition, toast } from "react-toastify";

export const primaryColor = "#F08400";
export const greyColor = "#EFEFEF";

export const hoverPrimary = "#FC8B00";

export const MINUTE_IN_MS = 60_000;

export const activePrimary = "#D67600";
export const activeGrey = "#DDD5D5";

export const isNumber = (string: string) => /^[0-9]+$/.test(string);
export const isEmail = (string: string) =>
  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(string);
export const isDeviseIsoCode = (string: string) => /^[A-Z]{3}$/.test(string);

export function encodeBase64Url(str: string) {
  // Encode en base64
  const base64 = forge.util.encode64(str);

  // Remplace les caractères spéciaux par leur équivalent en base64url
  return base64.replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, "");
}

export function decodeBase64Url(str: string) {
  // Remplace les caractères spéciaux par leur équivalent en base64
  str = str
    .replace(/-/g, "+")
    .replace(/_/g, "/")
    .padEnd(str.length + ((4 - (str.length % 4)) % 4), "=");

  // Décodage en base64
  return forge.util.decode64(str);
}

export function chunkFile(file: any, chunkSize: number) {
  const chunks = [];
  const fileSize = file.size;

  for (let i = 0; i < fileSize; i += chunkSize) {
    const chunk = file.slice(i, i + chunkSize);
    chunks.push(chunk);
  }

  return chunks;
}

export async function compressChunk(chunk: any) {
  const buffer = await chunk.arrayBuffer();
  const compressed = pako.deflate(new Uint8Array(buffer));
  return new Blob([compressed]);
}

export const generateImageMiniature = (
  file: File,
  { width = 0, height = 0 },
  quality = 20,
  format = "JPEG"
) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      width,
      height,
      format,
      quality,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

export const ANY = Object();

export const ToastErrorNotifier = ({
  message = "",
  duration = 5000,
  position = "top-center",
}) => {
  toast.error(message, {
    position: position as ToastPosition,
    autoClose: duration,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};

export const ToastSuccessNotifier = ({
  message = "",
  duration = 5000,
  position = "top-center",
}) => {
  toast.success(message, {
    position: position as ToastPosition,
    autoClose: duration,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};

export const ToastWarnNotifier = ({
  message = "",
  duration = 5000,
  position = "top-center",
}) => {
  toast.warn(message, {
    position: position as ToastPosition,
    autoClose: duration,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};

export const Months = [
  {
    month: "January",
    length: "31",
    name: "Janvier",
  },
  {
    month: "February",
    length: "29",
    name: "Fevrier",
  },
  {
    month: "March",
    length: "31",
    name: "Mars",
  },
  {
    month: "April",
    length: "30",
    name: "Avril",
  },
  {
    month: "May",
    length: "31",
    name: "Mai",
  },
  {
    month: "June",
    length: "31",
    name: "Juin",
  },
  {
    month: "June",
    length: "30",
    name: "Juin",
  },
  {
    month: "July",
    length: "31",
    name: "Juillet",
  },
  {
    month: "August",
    length: "31",
    name: "Aout",
  },
  {
    month: "September",
    length: "30",
    name: "Septembre",
  },
  {
    month: "October",
    length: "31",
    name: "Octobre",
  },
  {
    month: "November",
    length: "30",
    name: "Novembre",
  },
  {
    month: "December",
    length: "31",
    name: "Decembre",
  },
];

export async function wait<T>(
  time?: number,
  rep?: T,
  waitList: Array<Promise<any>> = []
) {
  return await new Promise<T | Boolean>(async (resolve, reject) => {
    setTimeout(async () => {
      if (waitList.length > 0) {
        await Promise.all(waitList);
      }
      resolve(rep ?? true);
    }, time ?? 7000);
  });
}
