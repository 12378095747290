import clsx from "clsx";
import { primaryColor } from "../../constants";
import React from "react";

type NumberInputProps = {
  title: string;
  defaultValue?: number;
  onChangeValue: (value: string) => void;
};

function NumberInput(props: NumberInputProps) {
  const { title = "", defaultValue = 0, onChangeValue = (e) => {} } = props;
  const [isFocused, setIsFocused] = React.useState(false);
  function onFocus() {
    setIsFocused(true);
  }
  function onBlur() {
    setIsFocused(false);
  }
  return (
    <div
      className={clsx(
        "flex border rounded-md",
        isFocused ? "ring-primary ring-2 border-transparent" : ""
      )}
    >
      <div
        style={{
          borderColor: isFocused ? primaryColor : undefined,
          color: isFocused ? primaryColor : "black",
        }}
        className="flex flex-1 border-r-2 items-center justify-center"
      >
        <span className="flex">{title}</span>
      </div>
      <input
        type="number"
        min={0}
        max={10000}
        onFocus={onFocus}
        onBlur={onBlur}
        className="flex p-4 phone:py-3 w-[60%] rounded-xl border-none outline-none focus:border-none focus:outline-none"
        placeholder={title}
        defaultValue={defaultValue}
        onChange={(e) => {
          onChangeValue(e.target.value);
        }}
      />
    </div>
  );
}

export default NumberInput;
