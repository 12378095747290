import React from "react";
import GridItemBilling from "./gridItem";
import { useBillings } from "../../../../../../hooks/useBillings";
import { useCategoryStore } from "../../../../../../configs/stores/category";

const BillingsGridView = () => {
  const category = useCategoryStore((e) => e.category);
  const { billings } = useBillings(
    category?.category_type.categorytype_id.toString() ?? ""
  );
  return (
    <div className="flex flex-col gap-5">
      <div className="flex flex-row flex-wrap gap-5">
        {billings.map((billing, index) => (
          <div
            key={index.toString() + billing.billing_id.toString()}
            className="flex flex-1 flex-col min-w-[320px] 2xl:max-w-[380px]"
            style={{
              maxWidth: [0, 1].includes(billings.length) ? 380 : 340,
            }}
          >
            <GridItemBilling billing={billing} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default BillingsGridView;
