import React from "react";
import ConfirmActionModal from "../../modals/models/confirmAction";
import { useAuthStore } from "../../../configs/stores/auth";

const AccountLogOut = () => {
  const { logout } = useAuthStore()
  const [isOpen, setisOpen] = React.useState(false);
  function closeModal() {
    setisOpen(false);
  }

  async function confirmAction() {
    return logout();
  }

  return (
    <React.Fragment>
      <button
        className="group flex px-[10px] py-[6px] bg-greylast/30 hover:bg-red-700/10 rounded-[4px]"
        onClick={() => {
          setisOpen(true);
        }}
      >
        <span className="flex text-red-600">Deconnexion</span>
      </button>
      <ConfirmActionModal
        confirm={confirmAction}
        message="Voulez-vous vraiment vous deconnectez maintenant ?"
        open={isOpen}
        handleClose={closeModal}
      />
    </React.Fragment>
  );
};

export default AccountLogOut;
