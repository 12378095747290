import React from "react";
import { generateImageMiniature } from "../../../../../../constants";
import { uploadFiles } from "../../../../../../functions/uploadFiles";
import { getPropValue } from "../../../../../../functions/getPropValue";
import LoadingModal from "../../../../../../components/modals/models/loading";
import { useCompanyStore } from "../../../../../../configs/stores/company";
import { useCategoryStore } from "../../../../../../configs/stores/category";

const AddPhoto = () => {
  const company = useCompanyStore((e) => e.company);
  const category = useCategoryStore((e) => e.category);
  const newImage = useCategoryStore((e) => e.newImage);
  const [isLoading, setisLoading] = React.useState(false);
  function pickImage() {
    document.getElementById("fileType")?.click();
  }
  function handleChange(e: any) {
    const image = e.target.files[0];
    if (!image) return;
    setisLoading(true);
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onloadend = async () => {
      if (!!reader.result) {
        let files: File[] = [];
        files.push(image);
        const reponses = await uploadFiles(
          files,
          `LeCoinChicDuMoment/uploads/company/${company?.company_id}/category/${
            category?.category_id ?? ""
          }/photos`
        );
        const dts = reponses.filter((r: any) => !!r.file_id);
        if (dts.length !== 0) {
          let miniature = await generateImageMiniature(files[0], {
            width: 10,
            height: 10,
          });
          const imageData = {
            image_parent: category?.category_id,
            image_parent_model: "category",
            image_miniature: miniature,
            image_link: getPropValue(dts[0], "download_url"),
            image_key: getPropValue(dts[0], "file_id"),
          };
          await newImage(imageData);
          setisLoading(false);
        }
      }
    };
    reader.onerror = () => {
      setisLoading(false);
      console.error("something went wrong!");
    };
  }
  return (
    <React.Fragment>
      <button
        className="flex items-center justify-center flex-col gap-3 px-5 py-[10px] aspect-[7/6] bg-primary hover:bg-active active:bg-active rounded-xl text-white"
        onClick={pickImage}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={3}
          stroke="white"
          className=" flex w-10 h-10"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 4.5v15m7.5-7.5h-15"
          />
        </svg>
        <span className="flex text-[22px]">Ajouter une photo</span>
      </button>
      <input
        id="fileType"
        type="file"
        accept="image/*" //accept="image/*"
        className="hidden"
        onChange={handleChange}
      />
      <LoadingModal open={isLoading} handleClose={() => setisLoading(false)} />
    </React.Fragment>
  );
};

export default AddPhoto;
