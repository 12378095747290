import { deleteUrl, getUrl, postUrl } from "../../apiRequest";

export function login(credentials = { login: "", password: "" }) {
  return postUrl("/auth/signin", credentials);
}

export function logout() {
  return getUrl("/auth/signout");
}

export function getManagerData() {
  return getUrl("/auth/me");
}

export function deleteCompany(data: any) {
  return deleteUrl("/auth/delete?id=" + data);
}