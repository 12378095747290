import React from "react";
import BillingsGridView from "./gridView";
import PageDescription from "../../../../../../components/descriptionCard/pageDescription";

const CategoryBillingMethods = () => {
  return (
    <div className="flex h-auto w-full flex-col gap-5">
      <span className="flex text-[30px] font-medium text-darkgrey">
        Formules de facturation
      </span>
      <PageDescription
        title="Description de la page"
        description="Bienvenue sur la page de selection du mode de facturation des reservations."
      />
      <BillingsGridView />
    </div>
  );
};

export default CategoryBillingMethods;
