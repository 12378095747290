import React from "react";
import { useAuthApiInterceptor } from "../../configs/api";

type AuthContextProps = {
  isAuthenticated: boolean;
  updateAuthState: (bool: boolean) => void;
  updateAccessToken: () => void;
  logout: () => Promise<void>;
};

export const AuthContext = React.createContext<AuthContextProps>(
  {} as AuthContextProps
);

const AuthProvider = ({ children = <div /> }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const interceptors = useAuthApiInterceptor();
  return (
    <React.Fragment>
      <div className="flex flex-col w-full">{children}</div>
    </React.Fragment>
  );
};

export default AuthProvider;
