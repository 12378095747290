import React from "react";
import ModalCustom from "../../modal";
import TextInput from "../../../inputs/textInput";
import { Button } from "@mui/material";
import { ToastErrorNotifier, primaryColor } from "../../../../constants";
import { generateSlug } from "../../../../functions/generateSlug";
import { useCategoryStore } from "../../../../configs/stores/category";
import { MenuCardModel, MenuModel } from "../../../../models/models";
import NumberInput from "../../../inputs/placesInput";

type Props = {
  handleClose: () => void;
  open: boolean;
  menu: MenuModel;
  menucard: MenuCardModel;
};

const ManageMenu = ({ handleClose, open = false, menu, menucard }: Props) => {
  const updateMenu = useCategoryStore((e) => e.updateMenu);
  const updateMenuImage = useCategoryStore((e) => e.updateMenuImage);
  const [imageUplading, setImageUplading] = React.useState(false);
  const [isLoading, setisLoading] = React.useState(false);
  const [formdata, setFormdata] = React.useState({
    menu_name: menu.menu_name,
    menu_description: menu.menu_description,
    menu_price: menu.menu_price,
  });
  const [image, setImage] = React.useState<{
    file?: File;
    image?: string | ArrayBuffer;
  }>();
  const [closable, setClosable] = React.useState(true);
  function pickImage() {
    document.getElementById("fileType")?.click();
  }
  function handleChange(e: any) {
    const image = e.target.files[0];
    if (!image) return;
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onloadend = () => {
      if (!!reader.result) {
        setImage({
          file: image,
          image: reader.result,
        });
        setClosable(false);
        setImageUplading(true);
        updateMenuImage(menu.menu_id, menucard.menucard_id.toString(), {
          file: image,
          image: reader.result,
        }).then(() => {
          setClosable(true);
          setImageUplading(false);
        });
      }
    };
    reader.onerror = () => {
      console.error("something went wrong!");
    };
  }
  function onValueChange(slug = "") {
    return (value: any) => {
      setFormdata((e) => ({ ...e, [slug]: value }));
    };
  }
  function close() {
    setImage({});
    setFormdata({
      menu_name: formdata.menu_name,
      menu_description: formdata.menu_description,
      menu_price: formdata.menu_price,
    });
    handleClose();
  }
  function checkForm() {
    let result = false;
    if (formdata.menu_name.length === 0) {
      result = true;
      ToastErrorNotifier({
        message: "Nommer le menu",
        position: "top-right",
      });
    }
    if (
      formdata.menu_description.length === 0 ||
      formdata.menu_description.split(" ").length < 10
    ) {
      result = true;
      ToastErrorNotifier({
        message: "Decriver le menu, minimum 10 mots",
        position: "top-right",
      });
    }
    if (formdata.menu_price === 0) {
      result = true;
      ToastErrorNotifier({
        message: "Entrer le prix du menu",
        position: "top-right",
      });
    }
    return result;
  }
  function saveForm() {
    if (checkForm()) {
      return;
    }
    setisLoading(true);
    setClosable(false);
    updateMenu(menu.menu_id, menucard.menucard_id.toString(), {
      menu_name: formdata.menu_name,
      menu_slug: generateSlug(formdata.menu_name),
      menu_description: formdata.menu_description,
      menu_price: formdata.menu_price,
    }).then(async (reponse) => {
      if (reponse?.success) {
        setTimeout(() => {
          close();
        }, 300);
      }
      setClosable(true);
      setisLoading(false);
    });
  }
  return (
    <ModalCustom open={open} handleClose={closable ? close : undefined}>
      <div className="flex flex-col w-[40vw] max-w-[1000px] max-h-[90vh] phone:w-[calc(100vw-30px)] overflow-y-auto phone:space-y-3 space-y-5 px-4 pb-4 items-center">
        <span className="flex text-[20px] text-darkgrey font-medium">
          Modifier menu
        </span>
        <div className="flex w-full flex-row phone:flex-col phone:space-x-0 phone:space-y-3 phone:items-center space-x-5">
          <div
            className="flex phone:h-[150px] phone:w-[150px] w-[250px] h-[250px] relative bg-whitegrey/25 hover:bg-whitegrey/10 rounded-xl border-[2px] cursor-pointer"
            onClick={pickImage}
          >
            {!!image?.image ? (
              <>
                <img
                  className="flex phone:h-[150px] phone:w-[150px] w-[250px] h-[250px] object-cover rounded-xl bg-black/10 p-2"
                  alt={image.image.toString()}
                  src={image.image.toString()}
                />
                <span className="flex text-[16px] phone:text-[13px] text-white absolute top-[15px] left-[15px] bg-black/20 hover:bg-black/30 active:bg-black/25 transition-colors duration-100 px-3 py-1 rounded-md">
                  Modifier l'image
                </span>
              </>
            ) : (
              <>
                <img
                  className="flex flex-1 object-cover rounded-xl bg-black/10 p-2"
                  alt={menu.menu_image.image_id.toString()}
                  src={menu.menu_image.image_link.toString()}
                />
                <span className="flex text-[16px] phone:text-[13px] text-white absolute top-[15px] left-[15px] bg-black/20 hover:bg-black/30 active:bg-black/25 transition-colors duration-100 px-3 py-1 rounded-md">
                  Modifier l'image
                </span>
              </>
            )}
            <div
              style={{ display: imageUplading ? "flex" : "none" }}
              className="flex absolute w-full h-full bg-black/50 justify-center items-center"
            >
              <svg
                className="animate-spin h-10 w-10 text-black/50"
                xmlns="http://www.w3.org/2000/svg"
                fill={"transparent"}
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke={"white"}
                  strokeWidth="2"
                ></circle>
                <path
                  className="opacity-75"
                  fill={"white"}
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </div>
          </div>
          <div className="flex flex-col flex-1 w-full pt-1 justify-center gap-3 max-h-[70vh] overflow-y-auto">
            <TextInput
              label="Nom du menu"
              placeholder="Entrer le nom du menu"
              value={formdata.menu_name}
              onTextChange={onValueChange("menu_name")}
            />
            <TextInput
              label="Description du menu"
              placeholder="Decriver le menu"
              multiline
              minlines={5}
              maxlines={5}
              value={formdata.menu_description}
              onTextChange={onValueChange("menu_description")}
            />
            <div className="flex flex-col p-1">
              <NumberInput
                title="Prix du menu"
                defaultValue={formdata.menu_price}
                onChangeValue={onValueChange("menu_price")}
              />
            </div>
          </div>
        </div>
        <Button
          style={{
            backgroundColor: primaryColor,
            paddingBlock: 10,
            color: "white",
          }}
          sx={{
            px: 8,
          }}
          onClick={saveForm}
        >
          {!isLoading ? (
            <span className="flex font-medium">Mettre a jour</span>
          ) : (
            <>
              <svg
                className="animate-spin h-6 w-6 text-black/50"
                xmlns="http://www.w3.org/2000/svg"
                fill={"transparent"}
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke={"white"}
                  strokeWidth="2"
                ></circle>
                <path
                  className="opacity-75"
                  fill={"white"}
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <span className="flex ml-3 normal-case">
                Patientez un instant
              </span>
            </>
          )}
        </Button>
        <input
          id="fileType"
          type="file"
          accept="image/*" //accept="image/*"
          className="hidden"
          onChange={handleChange}
        />
      </div>
    </ModalCustom>
  );
};

export default ManageMenu;
