import { Checkbox, TableCell, TableRow } from "@mui/material";
import {
  DeleteIconButton,
  DonwloadIconButton,
  EditIconButton,
  ToggleStateIconButton,
  ViewIconButton,
} from "./icons";
import React from "react";
import { TableRowData } from "./types";
import { getPropValue } from "../../functions/getPropValue";
import { useTableViewHook } from "./hook";

type Props = {
  row: TableRowData;
};

function TableItem({ row }: Props) {
  const {
    checkable = false,
    columns = [],
    viewable = false,
    togglable = false,
    editable = false,
    downloadable = false,
    removable = false,
  } = useTableViewHook();
  const [isChecked, setisChecked] = React.useState(false);
  function onCheck(bool: boolean) {
    setisChecked(bool);
  }
  function onView(bool: any) {}
  function onToggle(bool: any) {}
  function onEdit(bool: any) {}
  function onDownload(bool: any) {}
  function onRemove(bool: any) {}
  return (
    <TableRow hover role="checkbox" tabIndex={-1}>
      {checkable && (
        <TableCell
          padding="checkbox"
          align={"left"}
          style={{
            fontWeight: "700",
          }}
        >
          <Checkbox
            color="primary"
            checked={isChecked}
            onChange={(e, bool) => onCheck(bool)}
          />
        </TableCell>
      )}
      {columns.map((column, index) => {
        const value = getPropValue(row, column.slug);
        const maxValueLenght = column.maxValueLenght ?? 35;
        return (
          <TableCell
            key={index}
            align={column.align}
            style={{
              maxWidth: column.maxWidth,
            }}
          >
            {!column?.isComponent
              ? (value ?? "").length > maxValueLenght
                ? value.slice(0, maxValueLenght) + "..."
                : value ?? "Aucun"
              : value ?? "Aucun"}
          </TableCell>
        );
      })}
      <TableCell align={"right"}>
        <div className="flex w-full justify-end flex-row items-center gap-2">
          {viewable && <ViewIconButton value={row} onClick={onView} />}
          {togglable && (
            <ToggleStateIconButton value={row} onClick={onToggle} />
          )}
          {editable && <EditIconButton value={row} onClick={onEdit} />}
          {downloadable && (
            <DonwloadIconButton value={row} onClick={onDownload} />
          )}
          {removable && <DeleteIconButton value={row} onClick={onRemove} />}
        </div>
      </TableCell>
    </TableRow>
  );
}

export default TableItem;
