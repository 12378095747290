import React from "react";
import CompanyHeader from "./companyHeader";
import VisitesBloc from "./visitesBloc";
import EvaluationBloc from "./evaluationBloc";
import CategoriesBloc from "./categoriesBloc";
import ManagerBloc, { ManagerBlocMobile } from "./managerBloc";
import SoldeBloc, { SoldeBlocMobile } from "./solde";
import ReservationChartBloc from "./reservationChartBloc";
import GeolocationBloc from "./geolocationBloc";

const DashWelcome = () => {
  return (
    <div className="flex flex-col space-y-5">
      <span className="flex text-[30px] phone:text-[25px] font-medium">
        TABLEAU DE BORD
      </span>
      <CompanyHeader />
      <div className="flex flex-col space-y-5">
        <span className="flex text-[18px] font-medium">
          Recapitulatif des réservations
        </span>
        <ReservationChartBloc />
        <div className="flex phone:hidden flex-row space-x-6">
          <SoldeBloc text="Total solde net" value="0 XOF" />
          <SoldeBloc text="Total solde distribué" value="0 XOF" />
        </div>
        <div className="hidden phone:flex flex-col space-y-5">
          <SoldeBlocMobile text="Total solde net" value="0 XOF" />
          <SoldeBlocMobile text="Total solde distribué" value="0 XOF" />
        </div>
      </div>
      <div className="flex flex-col space-y-5">
        <span className="flex text-[18px] font-medium">
          Evaluation - Visites
        </span>
        <div className="flex flex-row phone:flex-col phone:space-y-5 phone:space-x-0 space-x-5">
          <EvaluationBloc />
          <VisitesBloc />
        </div>
      </div>
      <span className="flex text-[18px] font-medium">
        Toutes les categories
      </span>
      <CategoriesBloc />
      <span className="flex text-[17px] font-medium">Gestionnaires</span>
      <ManagerBloc />
      <ManagerBlocMobile />
      <span className="flex text-[17px] font-medium">
        Géolocalisation de l'établissement
      </span>
      <GeolocationBloc />
    </div>
  );
};

export default DashWelcome;
