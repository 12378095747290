import React, { useState } from "react";
import { ImageModel } from "../../../../../../models/models";
import ConfirmActionModal from "../../../../../../components/modals/models/confirmAction";
import ViewImageModal from "../../../../../../components/modals/models/viewImage";
import { useCategoryStore } from "../../../../../../configs/stores/category";

type Props = {
  image: ImageModel;
};

const PhotoItem = ({ image }: Props) => {
  const [mouseIsEnter, setMouseIsEnter] = useState(false);
  const [updatingPreview, setUpdatingPreview] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] =
    React.useState(false);
  const [openViewImageModal, setOpenViewImageModal] = React.useState(false);
  const deleteImage = useCategoryStore((e) => e.deleteImage);
  const updateImage = useCategoryStore((e) => e.updateImage);

  function togglePreviewState(value = false) {
    setUpdatingPreview(true);
    updateImage(image.image_id, { image_ispreview: value }).then((e) => {
      setUpdatingPreview(false);
    });
  }

  function closeModal() {
    setOpenDeleteConfirmation(false);
  }
  function closeImageModal() {
    setOpenViewImageModal(false);
  }
  async function confirmAction() {
    await deleteImage(image.image_id);
    return closeModal();
  }
  return (
    <React.Fragment>
      <div
        onMouseEnter={() => setMouseIsEnter(true)}
        onMouseLeave={() => setMouseIsEnter(false)}
        className="group rounded-xl aspect-[7/6] relative overflow-hidden border bg-black/5 select-none"
      >
        <img
          className={`w-full h-full rounded-xl object-cover absolute transition-transform duration-300 ${
            mouseIsEnter ? "scale-150" : ""
          }`}
          src={image.image_link.toString()}
          alt={image.image_link.toString()}
        />
        {mouseIsEnter && (
          <div className="flex gap-6 absolute inset-0 bg-black/50 items-center justify-center transition-opacity duration-300">
            <div
              onClick={() => setOpenDeleteConfirmation(true)}
              className="flex cursor-pointer p-3 rounded-full bg-white/30 hover:bg-white/50 active:bg-white/70 transition-colors duration-150"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2.5}
                stroke="white"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                />
              </svg>
            </div>
            <div
              onClick={() => setOpenViewImageModal(true)}
              className="flex cursor-pointer p-3 rounded-full bg-white/30 hover:bg-white/50 active:bg-white/70 transition-colors duration-150"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2.5}
                stroke="white"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            </div>
          </div>
        )}
        <div className="flex absolute bg-blue-500/0 w-full bottom-0 p-3 px-4 items-center justify-center">
          <div className="flex flex-row py-2 flex-1 px-4 rounded-[30px] items-center justify-between bg-white shadow-md">
            <span className="flex font-medium text-[14px]">
              Mettre en avant cette image ?
            </span>
            <div className="flex flex-row items-center space-x-3">
            {updatingPreview && <svg
                data-unchecked-icon
                className="animate-spin h-5 w-5 text-gray-300"
                xmlns="http://www.w3.org/2000/svg"
                fill={"transparent"}
                viewBox="0 0 24 24"
              >
                <circle
                  key={"zzz"}
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke={"blue"}
                  strokeWidth="2"
                ></circle>
                <path
                  className="opacity-75"
                  fill={"white"}
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>}
              <label
                htmlFor={"ispreview_"+image.image_id}
                className="relative h-6 w-12 cursor-pointer [-webkit-tap-highlight-color:_transparent]"
              >
                <input
                  type="checkbox"
                  id={"ispreview_"+image.image_id}
                  className="peer sr-only [&:checked_+span_svg[data-checked-icon]]:block [&:checked+_span_svg[data-unchecked-icon]]:hidden"
                  checked={image.image_ispreview}
                  onChange={(e) => {
                    togglePreviewState(e.target.checked);
                  }}
                />

                <span className="absolute inset-y-0 start-0 z-10 m-[3px] inline-flex h-[18px] w-[18px] items-center justify-center rounded-full bg-white text-gray-400 transition-all peer-checked:start-6 peer-checked:text-green-600">
                  <svg
                    data-unchecked-icon
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-3 w-3"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>

                  <svg
                    data-checked-icon
                    xmlns="http://www.w3.org/2000/svg"
                    className="hidden h-3 w-3"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>

                <span className="absolute inset-0 rounded-full bg-gray-300 transition peer-checked:bg-green-500"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <ConfirmActionModal
        confirm={confirmAction}
        message="Voulez-vous supprimer cette image ?"
        open={openDeleteConfirmation}
        handleClose={closeModal}
      />
      <ViewImageModal
        open={openViewImageModal}
        handleClose={closeImageModal}
        image={image}
      />
    </React.Fragment>
  );
};

export default PhotoItem;
