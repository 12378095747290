import React from "react";
import NewCategoryModel from "../../modals/models/newcategory";
import { useBasicsStore } from "../../../configs/stores/basics";
import { useCompanyStore } from "../../../configs/stores/company";

const AllCategories = () => {
  const {categoryTypes} = useBasicsStore()
  const company = useCompanyStore((e)=>e.company)
  const categories = useCompanyStore((e)=>e.categories)
  const [open, setOpen] = React.useState(false);
  function HandleClose() {
    setOpen(false);
  }
  return (
    <div className="flex flex-col max-h-[calc(100vh-140px)] overflow-y-auto space-y-3 bg-slate-200/30">
      <div className="flex pl-5 py-2 text-[16px] font-medium border-b bg-white">
        <span className="flex">Sélectionner une categorie</span>
      </div>
      <div className="flex flex-col space-y-4 px-3">
        {categories.map((item, index) => {
          return (
            <a
              href={
                "/company/" +
                company?.company_slug +
                "/" +
                item.category_type?.categorytype_slug.toString() +
                "/welcome"
              }
              key={item.category_id.toString()}
              className="flex pl-3 py-3 text-[16px] font-medium border bg-white shadow-sm hover:shadow-md transition-all hover:text-white rounded-md hover:bg-hover"
            >
              <span className="flex">
                {item.category_name ??
                  item.category_type?.categorytype_name}
              </span>
            </a>
          );
        })}
        {categoryTypes.filter(
          (e) =>
            !categories
              .map((item) => item.category_type?.categorytype_id)
              .includes(e.categorytype_id)
        ).length !== 0 && (
          <>
            <div className="flex self-center w-[95%] h-[1px] bg-darkgrey/20" />
            <button
              onClick={() => setOpen(true)}
              className="flex flex-row pl-3 py-3 gap-3 text-[16px] font-medium border items-center shadow-sm hover:shadow-md transition-all text-white rounded-md bg-primary"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-5 h-5"
              >
                <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
              </svg>

              <span className="flex">Nouvelle categorie</span>
            </button>
          </>
        )}
      </div>
      <NewCategoryModel handleClose={HandleClose} open={open} />
    </div>
  );
};

export default AllCategories;
