import React from "react";
import { useCompanyStore } from "../../../../configs/stores/company";

const EvaluationBloc = () => {
  const categories = useCompanyStore((e)=>e.categories);
  // const evaluations = categories.map((e) =>
  //   parseFloat(e.evaluation.evaluation.toString())
  // );
  // const evaluationsLength = categories.map((e) =>
  //   parseFloat(e.evaluation.length.toString())
  // );
  return (
    <div className="flex flex-row gap-5 p-5 pr-8 bg-white rounded-md items-center">
      <div className="flex h-[70px] flex-col aspect-square rounded-full border-[8px] border-primary items-center justify-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          className="w-4 h-4 fill-primary"
        >
          <path
            fillRule="evenodd"
            d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
            clipRule="evenodd"
          />
        </svg>
        <span className="flex text-[16px] font-medium text-primary">
          {/* {(
            evaluations.reduce((prev, curr) => {
              return prev + curr;
            }, 0) / evaluations.length
          ).toFixed(1)} */}
          0/5
        </span>
      </div>
      <div className="flex flex-col gap-2 leading-none">
        <span className="flex text-[18px] font-medium text-darkgrey">
          Evaluation de l'établissement
        </span>
        <span className="flex text-[14px] cursor-pointer w-fit text-primary text-darkgrey/50 underline">
          {/* {evaluationsLength.reduce((prev, curr) => {
            return prev + curr;
          }, 0)} */}
          0 évaluations
        </span>
      </div>
    </div>
  );
};

export default EvaluationBloc;
