import React from "react";
import PageDescription from "../../../../../../components/descriptionCard/pageDescription";
import SearchInTable from "../../../../../../components/searchInput/searchInTable";
import AddMenuCard from "./add";
import NoData from "../../../../../../assets/noData.svg";
import { useCategoryStore } from "../../../../../../configs/stores/category";
import PlaceItem from "./item";
import { useOneEffect } from "../../../../../../hooks/useOnEffect";
import RefreshButton from "../../../../../../components/buttons/refeshButton";

const CategoryPlaces = () => {
  const [searchInput, setsearchInput] = React.useState("");
  const places = useCategoryStore((e) => e.places);
  const isLoadingPlaces = useCategoryStore((e) => e.isLoadingPlaces);
  const isRefetchPlaces = useCategoryStore((e) => e.isRefetchPlaces);
  const refetchPlaces = useCategoryStore((e) => e.refetchPlaces);
  const getPlaces = useCategoryStore((e) => e.getPlaces);
  useOneEffect(() => {
    if (isLoadingPlaces) {
      getPlaces();
    } else {
      refetchPlaces();
    }
  }, []);
  return (
    <div className="flex h-auto flex-col space-y-6">
      <span className="flex text-[30px] phone:text-[25px] font-medium text-darkgrey">PLACES</span>
      <PageDescription
        title="Description de la page"
        description="Bienvenue sur la page de gestion des places de reservations ! 
        Cet espace est dédié aux administrateurs pour gérer les différentes places disponibles pour reservations."
      />
      <div className="flex flex-row flex-wrap items-end gap-4 text-[17px] mb-4">
        <SearchInTable
          placeholder="Entrer le nom d'une place'..."
          onChange={setsearchInput}
        />
        <AddMenuCard />
        <RefreshButton
          refresh={refetchPlaces}
          isLoading={isRefetchPlaces}
        />
      </div>
      <div className="flex flex-col gap-5 w-full max-w-[800px] phone:max-w-[calc(100vw-30px)]">
        {places
          .filter((e) =>
            searchInput.length !== 0 ? e.place_name.includes(searchInput) : true
          )
          .map((item, index) => {
            return <PlaceItem item={item} key={index} />;
          })}
        {places.length === 0 && (
          <div className="flex w-full py-10 pl-10 items-center flex-row ">
            <img src={NoData} className="flex w-[45%]" alt="NoData" />
          </div>
        )}
      </div>
    </div>
  );
};

export default CategoryPlaces;
