import React from "react";
import ReservationTabTitle from "./tab";
import { useCategoryReservationStore } from "../../../../../../../configs/stores/reservations";
import AllReservation from "./all";
import AllPaiedReservation from "./paied";
import AllWaitReservation from "./wait";

const ReservationTabs = () => {
  const tabState = useCategoryReservationStore((e) => e.tabState);
  const tabs = [
    {
      label: "Tous",
      value: "all",
    },
    {
      label: "En cours",
      value: "wait",
    },
    {
      label: "Réservés",
      value: "paied",
    },
  ];
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row pb-3 phone:w-[calc(100vw-30px)] phone:overflow-x-auto pr-14 space-x-4 border-b border-black/30 w-fit">
        {tabs.map((e) => {
          return <ReservationTabTitle key={e.value} tab={e} />;
        })}
      </div>
      <div className="flex h-auto min-h-[300px] relative max-h-[1000px] bg-white flex-col rounded-[17px] overflow-hidden overflow-y-auto pb-1">
        {tabState === "all" && <AllReservation />}
        {tabState === "wait" && <AllWaitReservation />}
        {tabState === "paied" && <AllPaiedReservation />}
      </div>
    </div>
  );
};

export default ReservationTabs;
