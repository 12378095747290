import { NavLink } from "react-router-dom";
import { CompanyRoutes } from "../../../contexts/dashboard/defaults/subroutes";
import { useCategoryStore } from "../../../configs/stores/category";

const CompanyNavItem = ({ item = CompanyRoutes[0], close = () => {} }) => {
  const category = useCategoryStore((e) => e.category);
  const selectRoute = useCategoryStore((e) => e.selectRoute);
  return (
    <NavLink
      to={
        category
          ? category.category_type.categorytype_slug +
            "/company" +
            (item.path === "" ? "" : "/" + item.path)
          : item.path ?? ""
      }
      end={(item.path ?? "") === ""}
      className={({ isActive }) => {
        if (isActive) {
          selectRoute(item.name ?? "");
          return "flex w-full px-5 py-2 bg-primary hover:bg-primary text-white";
        }
        return "flex w-full px-5 py-2 hover:bg-slate-100";
      }}
    >
      <span className={`flex text-base font-semibold`}>{item.name}</span>
    </NavLink>
  );
};

export default CompanyNavItem;
