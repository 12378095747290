import React from "react";
import ModalCustom from "../../modal";
import TextInput from "../../../inputs/textInput";
import { Button } from "@mui/material";
import { ToastErrorNotifier, primaryColor } from "../../../../constants";
import { generateSlug } from "../../../../functions/generateSlug";
import { useCategoryStore } from "../../../../configs/stores/category";
import { MenuCardModel } from "../../../../models/models";
import NumberInput from "../../../inputs/placesInput";

type Props = {
  handleClose: () => void;
  open: boolean;
  menucard: MenuCardModel;
};

const NewMenu = ({ handleClose, open = false, menucard }: Props) => {
  const category = useCategoryStore((e) => e.category);
  const newMenu = useCategoryStore((e) => e.newMenu);
  const [isLoading, setisLoading] = React.useState(false);
  const [formdata, setFormdata] = React.useState({
    menu_name: "",
    menu_description: "",
    menu_price: 0,
  });
  const [image, setImage] = React.useState<{
    file?: File;
    image?: string | ArrayBuffer;
  }>();
  const [closable, setClosable] = React.useState(true);
  function pickImage() {
    document.getElementById("fileType")?.click();
  }
  function handleChange(e: any) {
    const image = e.target.files[0];
    if (!image) return;
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onloadend = () => {
      !!reader.result && setImage({ file: image, image: reader.result });
    };
    reader.onerror = () => {
      console.error("something went wrong!");
    };
  }
  function onValueChange(slug = "") {
    return (value: any) => {
      setFormdata((e) => ({ ...e, [slug]: value }));
    };
  }
  function close() {
    setImage({});
    setFormdata({
      menu_name: "",
      menu_description: "",
      menu_price: 0,
    });
    handleClose();
  }
  function checkForm() {
    let result = false;
    if (!image?.file) {
      result = true;
      ToastErrorNotifier({
        message: "Veuillez selectionner une image",
        position: "top-right",
      });
    }
    if (formdata.menu_name.length === 0) {
      result = true;
      ToastErrorNotifier({
        message: "Nommer le menu",
        position: "top-right",
      });
    }
    if (
      formdata.menu_description.length === 0 ||
      formdata.menu_description.split(" ").length < 10
    ) {
      result = true;
      ToastErrorNotifier({
        message: "Decriver le menu, minimum 10 mots",
        position: "top-right",
      });
    }
    if (
      formdata.menu_price === 0
    ) {
      result = true;
      ToastErrorNotifier({
        message: "Entrer le prix du menu",
        position: "top-right",
      });
    }
    return result;
  }
  function saveForm() {
    if (checkForm()) {
      return;
    }
    setisLoading(true);
    setClosable(false);
    newMenu({
      data: {
        menu_name: formdata.menu_name,
        menu_slug: generateSlug(formdata.menu_name),
        menu_description: formdata.menu_description,
        menu_price: formdata.menu_price,
        menu_card: menucard.menucard_id,
        menu_category: category?.category_id,
      },
      image,
    }).then(async (reponse) => {
      if (reponse?.success) {
        setTimeout(() => {
          close();
        }, 300);
      }
      setClosable(true);
      setisLoading(false);
    });
  }
  return (
    <ModalCustom open={open} handleClose={closable ? close : undefined}>
      <div className="flex flex-col w-[40vw] max-w-[1000px] max-h-[90vh] phone:w-[calc(100vw-30px)] overflow-y-auto phone:space-y-3 space-y-5 px-4 pb-4 items-center">
        <span className="flex text-[20px] text-darkgrey font-medium">
          Ajouter nouveau menu
        </span>
        <div className="flex w-full flex-row phone:flex-col phone:space-x-0 phone:space-y-3 phone:items-center space-x-5">
          <div
            className="flex phone:h-[150px] phone:w-[150px] w-[250px] h-[250px] relative bg-whitegrey/25 hover:bg-whitegrey/10 rounded-xl border-[2px] cursor-pointer"
            onClick={pickImage}
          >
            {!!image?.image ? (
              <>
                <img
                  className="flex  phone:h-[150px] phone:w-[150px] w-[250px] h-[250px] object-cover rounded-xl bg-black/10 p-2"
                  alt={image.image.toString()}
                  src={image.image.toString()}
                />
                <span className="flex text-[16px] phone:text-[13px] text-white absolute top-[15px] left-[15px] bg-black/20 hover:bg-black/30 active:bg-black/25 transition-colors duration-100 px-3 py-1 rounded-md">
                  Modifier l'image
                </span>
              </>
            ) : (
              <div className="flex flex-1 flex-col gap-4 items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  className="flex aspect-[4/3] h-[30%] fill-white stroke-black/40"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                  />
                </svg>
                <span className="flex text-center phone:text-[13px] w-[80%]">
                  Ajouter une image de presentation du menu
                </span>
              </div>
            )}
          </div>
          <div className="flex flex-col flex-1 pt-1 w-full justify-center gap-3 max-h-[70vh] overflow-y-auto">
            <TextInput
              label="Nom du menu"
              placeholder="Entrer le nom du menu"
              value={formdata.menu_name}
              onTextChange={onValueChange("menu_name")}
            />
            <TextInput
              label="Description du menu"
              placeholder="Decriver le menu"
              multiline
              minlines={5}
              maxlines={5}
              value={formdata.menu_description}
              onTextChange={onValueChange("menu_description")}
            />
            <div className="flex flex-col p-1">
              <NumberInput
                title="Prix du menu"
                defaultValue={formdata.menu_price}
                onChangeValue={onValueChange("menu_price")}
              />
            </div>
          </div>
        </div>
        <Button
          style={{
            backgroundColor: primaryColor,
            paddingBlock: 10,
            color: "white",
          }}
          sx={{
            px: 8,
          }}
          onClick={saveForm}
        >
          {!isLoading ? (
            <span className="flex font-medium">Enregistrer</span>
          ) : (
            <>
              <svg
                className="animate-spin h-6 w-6 text-black/50"
                xmlns="http://www.w3.org/2000/svg"
                fill={"transparent"}
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke={"white"}
                  strokeWidth="2"
                ></circle>
                <path
                  className="opacity-75"
                  fill={"white"}
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <span className="flex ml-3 normal-case">
                Patientez un instant
              </span>
            </>
          )}
        </Button>
        <input
          id="fileType"
          type="file"
          accept="image/*" //accept="image/*"
          className="hidden"
          onChange={handleChange}
        />
      </div>
    </ModalCustom>
  );
};

export default NewMenu;
