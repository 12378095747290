import React from "react";
import NewCommpanyIllustration from "../../../../assets/newCompany.svg";
import {
  ToastErrorNotifier,
  isEmail,
  primaryColor,
} from "../../../../constants";
import { Button } from "@mui/material";
import TextInput from "../../../inputs/textInput";
import { generateSlug } from "../../../../functions/generateSlug";
import { useAuthStore } from "../../../../configs/stores/auth";
import { useRolesStore } from "../../../../configs/stores/roles";

const NewCompanyMobileModal = ({ close = () => {} }) => {
  const manager = useAuthStore((e) => e.manager);
  const newCompany = useRolesStore((e) => e.newCompany);
  const isLoading = useRolesStore((e) => e.isCreatingNewCompany);
  const [formdata, setFormdata] = React.useState({
    company_name: "",
    company_email_pro: "",
  });
  const [image, setImage] = React.useState<{
    file?: File;
    image?: string | ArrayBuffer;
  }>();
  function onValueChange(slug = "") {
    return (value: any) => {
      setFormdata((e) => ({ ...e, [slug]: value }));
    };
  }
  function checkForm() {
    let result = false;
    if (formdata.company_name.length === 0) {
      result = true;
      ToastErrorNotifier({
        message: "Entrer le nom de l'etablissement",
      });
    }
    if (
      formdata.company_email_pro.length === 0 ||
      !isEmail(formdata.company_email_pro)
    ) {
      result = true;
      ToastErrorNotifier({
        message: "Entrer une adresse email valide",
      });
    }
    if (!image) {
      result = true;
      ToastErrorNotifier({
        message:
          "Veuillez selectionner le logo de l'etablissement avant de pursuivre",
      });
    }
    return result;
  }
  function saveForm() {
    if (checkForm()) {
      return;
    }
    newCompany({
      manager,
      formdata,
      image,
    }).then((success) => {
      if (success) {
        close();
      }
    });
  }
  function pickImage() {
    document.getElementById("fileType")?.click();
  }
  function handleChange(e: any) {
    const image = e.target.files[0];
    if (!image) return;
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onloadend = () => {
      !!reader.result && setImage({ file: image, image: reader.result });
    };
    reader.onerror = () => {
      console.error("something went wrong!");
    };
  }
  return (
    <div id="modalcontainer" className="flex flex-col w-[85vw] h-auto overflow-y-auto">
      <div className="flex flex-col px-4 pt-3 pb-3">
        <span className="flex text-[18px] font-bold">
          Demarrer des maintenant
        </span>
      </div>
      <div className="flex flex-1 h-[80vw] bg-slate-400/10">
        <img
          src={NewCommpanyIllustration}
          alt="NewCommpanyIllustration"
          className="flex flex-1 object-cover"
        />
      </div>
      <div className="flex flex-col px-4 py-3 gap-3 leading-tight">
        <span className="flex text-[15px] text-primary font-normal">
          Des clients potentiels vous y attendent !
        </span>
        <div className="group flex flex-row items-center gap-3">
          <div className="flex h-[50px] aspect-square bg-whitegrey/25 rounded-full border-[2px]">
            {!!image?.image ? (
              <img
                className="flex flex-1 object-cover rounded-full"
                alt={image.image.toString()}
                src={image.image.toString()}
              />
            ) : (
              <div className="flex flex-1 items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="w-6 h-6 fill-darkgrey/70"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 2.25a.75.75 0 000 1.5v16.5h-.75a.75.75 0 000 1.5H15v-18a.75.75 0 000-1.5H3zM6.75 19.5v-2.25a.75.75 0 01.75-.75h3a.75.75 0 01.75.75v2.25a.75.75 0 01-.75.75h-3a.75.75 0 01-.75-.75zM6 6.75A.75.75 0 016.75 6h.75a.75.75 0 010 1.5h-.75A.75.75 0 016 6.75zM6.75 9a.75.75 0 000 1.5h.75a.75.75 0 000-1.5h-.75zM6 12.75a.75.75 0 01.75-.75h.75a.75.75 0 010 1.5h-.75a.75.75 0 01-.75-.75zM10.5 6a.75.75 0 000 1.5h.75a.75.75 0 000-1.5h-.75zm-.75 3.75A.75.75 0 0110.5 9h.75a.75.75 0 010 1.5h-.75a.75.75 0 01-.75-.75zM10.5 12a.75.75 0 000 1.5h.75a.75.75 0 000-1.5h-.75zM16.5 6.75v15h5.25a.75.75 0 000-1.5H21v-12a.75.75 0 000-1.5h-4.5zm1.5 4.5a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75h-.008a.75.75 0 01-.75-.75v-.008zm.75 2.25a.75.75 0 00-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 00.75-.75v-.008a.75.75 0 00-.75-.75h-.008zM18 17.25a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75h-.008a.75.75 0 01-.75-.75v-.008z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            )}
          </div>
          <div className="flex flex-col gap-1">
            <span className="flex text-[15px] text-darkgrey/75">
              Logo de l'etablissement
            </span>
            <button
              className="flex flex-row items-center gap-1 font-medium"
              onClick={pickImage}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                className="w-4 h-4 stroke-primary"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                />
              </svg>
              <span className="flex pb-px text-primary text-[14px]">
                Modifier
              </span>
            </button>
          </div>
        </div>
        <input
          id="fileType"
          type="file"
          accept="image/*" //accept="image/*"
          className="hidden"
          onChange={handleChange}
        />
        <TextInput
          label="Entrer le nom de l'etablissement"
          value={formdata.company_name}
          onTextChange={onValueChange("company_name")}
        />
        {formdata.company_name.length !== 0 && (
          <span className="flex text-darkgrey/50 text-[14px]">
            Slug: {generateSlug(formdata.company_name)}
          </span>
        )}
        <TextInput
          label="Email de l'etablissement"
          value={formdata.company_email_pro}
          onTextChange={onValueChange("company_email_pro")}
        />
        <Button
          style={{
            backgroundColor: primaryColor,
            paddingBlock: 10,
            color: "white",
          }}
          onClick={saveForm}
        >
          {!isLoading ? (
            <span className="flex font-medium">Inscription</span>
          ) : (
            <>
              <svg
                className="animate-spin h-6 w-6 text-black/50"
                xmlns="http://www.w3.org/2000/svg"
                fill={"transparent"}
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke={"white"}
                  strokeWidth="2"
                ></circle>
                <path
                  className="opacity-75"
                  fill={"white"}
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <span className="flex ml-3 normal-case">
                Patientez un instant
              </span>
            </>
          )}
        </Button>
      </div>
    </div>
  );
};

export default NewCompanyMobileModal;
