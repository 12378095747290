import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuthStore } from "../configs/stores/auth";

const PrivateRoute = ({ children = <div /> }) => {
  const isAuthenticated = useAuthStore((e) => e.isAuthenticated);
  const location = useLocation();
  if (!isAuthenticated) {
    return <Navigate to={"/login"} state={{ from: location }} replace />;
  }
  return children;
};

export default PrivateRoute;
