import React from "react";
import { PlaceModel } from "../../../../../../models/models";
import ConfirmActionModal from "../../../../../../components/modals/models/confirmAction";
import { useCategoryStore } from "../../../../../../configs/stores/category";
import ManagePlace from "../../../../../../components/modals/models/manageplace";

type Props = {
  item: PlaceModel;
};

const PlaceItem = ({ item }: Props) => {
  const deletePlace = useCategoryStore((e) => e.deletePlace);
  const [openManage, setOpenManage] = React.useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] =
    React.useState(false);
  function handleCloseManage() {
    setOpenManage(false);
  }
  function closeModal() {
    setOpenDeleteConfirmation(false);
  }
  async function confirmAction() {
    await deletePlace(item.place_id);
    return closeModal();
  }
  return (
    <React.Fragment>
      <div className="flex relative select-none leading-tight w-full min-h-[230px] phone:min-w-0 flex-row phone:flex-col phone:space-y-3 phone:space-x-0 bg-white border rounded-xl p-3 space-x-5">
        <div className="flex h-[205px] phone:h-[100px] phone:w-[100px]">
          <img
            src={item.place_images[0].image_link.toString()}
            alt={item.place_name.toString()}
            className="flex flex-1 aspect-square object-cover rounded-xl"
          />
        </div>
        <div className="flex flex-1 flex-col space-y-4">
          <div className="flex flex-col flex-1 justify-center space-y-2">
            <span className="flex text-[22px] font-medium line-clamp-1">
              {item.place_name}
            </span>
            <div className="flex flex-col gap-y-1">
              <span className="flex text-[17px] font-medium">Description</span>
              <span className="text-[16px] font-normal line-clamp-2">
                {item.place_description}
              </span>
            </div>
            <div className="flex flex-col">
              <span className="flex text-[17px] font-medium">
                Disponibilite en semaine: {item.place_week}
              </span>
            </div>
            <div className="flex flex-col">
              <span className="flex text-[17px] font-medium">
                Disponibilite en week-end: {item.place_weekend}
              </span>
            </div>
          </div>
        </div>
        <div
          onClick={() => setOpenManage(true)}
          className="flex absolute top-3 right-[65px] p-[6px] rounded-md border hover:bg-hover text-activeGrey hover:text-white active:bg-primary/50 transition-colors duration-100"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
            />
          </svg>
        </div>
        <div
          onClick={() => setOpenDeleteConfirmation(true)}
          className="flex absolute top-3 right-3 p-[6px] rounded-md border hover:bg-red-500 text-activeGrey hover:text-white active:bg-red-500/50 transition-colors duration-100"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
            />
          </svg>
        </div>
      </div>
      <ManagePlace
        open={openManage}
        handleClose={handleCloseManage}
        place={item}
      />
      <ConfirmActionModal
        confirm={confirmAction}
        message="Voulez-vous suppprimer cette carte ?"
        open={openDeleteConfirmation}
        handleClose={closeModal}
      />
    </React.Fragment>
  );
};

export default PlaceItem;
