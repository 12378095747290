import React from "react";
import { CategoryModel } from "../../../../../models/models";
import ChartBloc from "./chart";
import { Months } from "../../../../../constants";


type Props = {
  category: CategoryModel;
};

const CategoryItem = ({ category }: Props) => {
  return (
    <div className="flex flex-col gap-4 bg-white rounded-md p-6 min-w-[500px] w-full max-w-[900px] phone:min-w-0 phone:w-[calc(100vw-30px)] phone:max-w-[calc(100vw-30px)]">
      <div className="flex flex-row items-center justify-between">
        <span className="flex text-[20px] font-medium text-primary">
          {category.category_type.categorytype_name}
        </span>
        <div className="flex flex-row items-center gap-3 mr-3">
          <div className="flex flex-row items-center gap-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              className="w-[16px] h-[16px] fill-primary"
            >
              <path
                fillRule="evenodd"
                d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                clipRule="evenodd"
              />
            </svg>
            <span className="flex text-[14px] font-medium text-primary">
              0/5
            </span>
          </div>
          /
          <span className="flex text-[14px] font-medium text-darkgrey">
            0
          </span>
        </div>
      </div>
      <ChartBloc />
      <div className="flex flex-row phone:flex-col phone:items-start phone:px-0 phone:space-y-2 justify-between items-center px-2">
        <span className="flex text-[16px] text-darkgrey font-medium">
          Evolution des reservations sur un mois ({Months[new Date().getMonth()].name})
        </span>
        <div className="flex flex-row gap-5 items-center">
          <div className="flex flex-row gap-2 items-center">
            <div className="flex w-[35px] phone:w-[30px] h-4 rounded-md bg-primary"/>
            <span className="flex text-[17px] font-medium text-primary">Achat</span>
          </div>
          <div className="flex flex-row gap-2 items-center">
            <div className="flex w-[35px] phone:w-[30px] h-4 rounded-md bg-[#00FF00]"/>
            <span className="flex text-[17px] font-medium text-[#00FF00]">Paiement Effectue</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryItem;
