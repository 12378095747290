import React from "react";
import PageDescription from "../../../../components/descriptionCard/pageDescription";
import GeolocalisationForm from "./form";

const DashGeolocalisation = () => {
  return (
    <div className="flex h-auto flex-col space-y-6">
      <span className="flex text-[30px] phone:text-[25px] font-medium text-darkgrey">
        GEOLOCALISATION
      </span>
      <PageDescription
        title="Description de la page"
        description="Bienvenue ! Ici, vous pouvez visualiser en temps réel 
        la géolocalisation des etablissements de votre plateforme. Grâce à une carte interactive, 
        vous pourrez repérer les emplacements des etablissements actifs et leurs zones d'affluence. 
        Cette vue d'ensemble vous permettra de prendre des décisions éclairées pour améliorer
         l'expérience des utilisateurs dans différentes régions et optimiser vos services en 
         fonction des données géospatiales."
      />
      <GeolocalisationForm />
    </div>
  );
};

export default DashGeolocalisation;
