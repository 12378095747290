import React from "react";
import PageDescription from "../../../../components/descriptionCard/pageDescription";

const DashStatistiques = () => {
  return (
    <div className="flex h-auto flex-col gap-6">
      <span className="flex text-[30px] font-medium text-darkgrey">
        STATISTIQUES
      </span>
      <PageDescription
        title="Description de la page"
        description=" Bienvenue sur la page de gestion des statistiques ! 
        Cette interface est dédiée aux administrateurs pour accéder et gérer 
        les différentes données statistiques enregistrées par notre système. 
        Vous avez ici la possibilité de consulter et analyser diverses statistiques, 
        telles que les données d'utilisation, les tendances de fréquentation, 
        les performances du système, etc. Explorez cette page pour obtenir 
        des informations précieuses permettant de prendre des décisions éclairées 
        et d'améliorer l'efficacité globale de notre plateforme."
      />
    </div>
  );
};

export default DashStatistiques;
