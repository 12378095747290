import React from "react";
import { ReservationModel } from "../../../../../../../../models/models";
import moment from "moment";
import "moment/locale/fr";

type Props = {
  reservation: ReservationModel;
};

const PaiedReservation = ({ reservation }: Props) => {
  return (
    <div className="flex flex-row space-x-3 p-3 pl-4 rounded-[15px] border">
      <div className="flex w-[100px] h-[100px] rounded-full overflow-hidden">
        {!!reservation.reservation_user?.user_profile ? (
          <img
            alt={reservation.reservation_id.toString()}
            src={reservation.reservation_user.user_profile.image_link}
            className="flex flex-1"
          />
        ) : (
          <div className="flex flex-1 justify-center items-center border rounded-full bg-slate-300">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="white"
              className="w-14 h-14"
            >
              <path d="M10 8a3 3 0 100-6 3 3 0 000 6zM3.465 14.493a1.23 1.23 0 00.41 1.412A9.9514 9.957 0 0010 18c2.31 0 4.438-.784 6.131-2.1.43-.333.604-.903.408-1.41a7.002 7.002 0 00-13.074.003z" />
            </svg>
          </div>
        )}
      </div>
      <div className="flex flex-col space-y-1 leading-tight pt-2 pb-1">
        <span className="flex text-[18px] font-medium">
          {reservation.reservation_user.user_name +
            " " +
            reservation.reservation_user.user_surname}
        </span>
        <span className="flex text-[17px]">
          {"Réservation de " +
            reservation.reservation_places
              .map((e) => `(x${e.length}) ${e.place.place_name}, `)
              .reduce<string>((prev, curr, i, arr) => prev + curr, "") +
            (reservation.reservation_services.length !== 0
              ? " ; Services réservés: " +
                reservation.reservation_services.reduce<string>(
                  (prev, curr, i, arr) => prev + ", " + curr.service_name,
                  ""
                )
              : "")}
        </span>
        <span className="flex text-[17px]">
          Jour d'arrivée:{" "}
          {moment(reservation.reservation_arrival_day).format("LL")}
          {" à "}
          {reservation.reservation_arrival_time}
        </span>
        <span className="flex text-[16px] text-primary font-bold">
          Frais: {reservation.reservation_price} FCFA
        </span>
      </div>
    </div>
  );
};

export default PaiedReservation;
