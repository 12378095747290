import { Popover } from "@mui/material";
import React from "react";
import NewCategoryModel from "../../modals/models/newcategory";
import { useBasicsStore } from "../../../configs/stores/basics";
import { useCategoryStore } from "../../../configs/stores/category";
import { useCompanyStore } from "../../../configs/stores/company";

const SelectedCategory = () => {
  const { categoryTypes } = useBasicsStore();
  const category = useCategoryStore((e) => e.category);
  const reset = useCategoryStore((e) => e.reset);
  const company = useCompanyStore((e) => e.company);
  const categories = useCompanyStore((e) => e.categories);
  const [isOpenChildrenList, setIsOpenChildrenList] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const handleAnchor = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsOpenChildrenList(!isOpenChildrenList);
  };

  const [openModal, setOpenModal] = React.useState(false);
  function HandleCloseModal() {
    setOpenModal(false);
  }
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <div className="flex flex-row pl-5 pr-[8px] py-[6px] pb-2 bg-slate-900 justify-between">
      <span className="flex text-white">
        {category?.category_type.categorytype_name ?? ""}
      </span>
      <div
        onClick={(e) => {
          handleAnchor(e);
          setIsOpenChildrenList(!isOpenChildrenList);
        }}
        className={`flex bg-slate-200/20 hover:bg-slate-200/40 active:bg-slate-200/30 transition-colors duration-150 rounded-md ${
          true ? "" : ""
        }`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.9}
          stroke="white"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
          />
        </svg>
      </div>
      <Popover
        id={id}
        open={isOpenChildrenList}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          marginTop: 0,
          marginLeft: 2,
        }}
        style={{
          boxShadow: "10px 5px 5px red",
        }}
        elevation={3}
      >
        <div className="flex flex-col space-y-2 m-1 min-w-[240px]">
          <span className="flex text-[16px] font-medium text-darkgrey ml-[10px] my-1">
            Changer de categorie :
          </span>
          {categories
            .filter(
              (item) =>
                item.category_type.categorytype_slug !==
                category?.category_type.categorytype_slug
            )
            .map((item, index) => {
              return (
                <a
                  href={
                    "/company/" +
                    company?.company_slug +
                    "/" +
                    item.category_type.categorytype_slug.toString() +
                    "/welcome"
                  }
                  key={item.category_id.toString()}
                  onClick={reset}
                  className="flex pl-3 py-[10px] text-[16px] font-medium border bg-white hover:shadow-md transition-all hover:text-white rounded-md hover:bg-hover"
                >
                  <span className="flex">
                    {item.category_name ?? item.category_type.categorytype_name}
                  </span>
                </a>
              );
            })}
          {categoryTypes.filter(
            (e) =>
              !categories
                .map((item) => item.category_type.categorytype_id)
                .includes(e.categorytype_id)
          ).length !== 0 && (
            <>
              <div className="flex self-center w-[95%] h-[1px] bg-darkgrey/20" />
              <button
                onClick={() => setOpenModal(true)}
                className="flex flex-row pl-3 py-[10px] space-x-3 text-[14px] font-medium border items-center hover:shadow-md transition-all text-darkgrey rounded-md"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="w-4 h-4"
                >
                  <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
                </svg>

                <span className="flex">Nouvelle categorie</span>
              </button>
            </>
          )}
        </div>
      </Popover>
      <NewCategoryModel handleClose={HandleCloseModal} open={openModal} />
    </div>
  );
};

export default SelectedCategory;
