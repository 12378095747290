import { useQuery } from "@tanstack/react-query";
import { BillingModel } from "../models/models";
import { useAuth } from "./useAuth";
import { getBillingsData } from "../contexts/dashboard/functions";
export function useBillings(selectedCategory = "", staleTime = Infinity) {
  const { isAuthenticated } = useAuth();
  const {
    isLoading,
    data: result,
    ...args
  } = useQuery({
    queryKey: ["billings", selectedCategory],
    queryFn: () => getBillingsData(selectedCategory),
    enabled: !!isAuthenticated && selectedCategory.length !== 0,
    staleTime,
  });
  return {
    isLoading,
    billings: (result?.data as Array<BillingModel>) ?? Array<BillingModel>(),
    ...args,
  };
}
