import React from "react";
import { Outlet } from "react-router-dom";
import { useCategoryStore } from "../../../../configs/stores/category";
import { useOneEffect } from "../../../../hooks/useOnEffect";

const CategoryDashPage = () => {
  const getStats = useCategoryStore((e) => e.getStats);

  useOneEffect(() => {
    getStats();
  }, []);
  React.useEffect(() => {
    getStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="flex flex-1 flex-col w-full">
      <Outlet />
    </div>
  );
};

export default CategoryDashPage;
