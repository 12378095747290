import React from "react";
import NewService from "../../../../../../components/modals/models/newservice";

const AddService = () => {
  const [open, setOpen] = React.useState(false);
  function handleClose() {
    setOpen(false);
  }
  return (
    <React.Fragment>
      <button
        className="flex px-5 py-[10px] bg-primary hover:bg-active active:bg-active rounded-md text-white"
        onClick={() => setOpen(true)}
      >
        <span className="flex">Ajouter un service</span>
      </button>
      <NewService open={open} handleClose={handleClose} />
    </React.Fragment>
  );
};

export default AddService;
