import React from "react";
import NewMenuCard from "../../../../../../components/modals/models/newmenucard";

const AddMenuCard = () => {
  const [open, setOpen] = React.useState(false);
  function handleClose() {
    setOpen(false);
  }
  return (
    <React.Fragment>
      <button
        className="flex px-5 py-[10px] bg-primary hover:bg-active active:bg-active rounded-md text-white"
        onClick={() => setOpen(true)}
      >
        <span className="flex">Ajouter une carte</span>
      </button>
      <NewMenuCard open={open} handleClose={handleClose} />
    </React.Fragment>
  );
};

export default AddMenuCard;
