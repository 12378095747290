import React from "react";
import ModalCustom from "../../modal";
import TextInput from "../../../inputs/textInput";
import { Button } from "@mui/material";
import { ToastErrorNotifier, primaryColor } from "../../../../constants";
import { generateSlug } from "../../../../functions/generateSlug";
import { useCategoryStore } from "../../../../configs/stores/category";
import { ServiceModel } from "../../../../models/models";
import NumberInput from "../../../inputs/placesInput";

type Props = {
  handleClose: () => void;
  open: boolean;
  service: ServiceModel;
};

const ManageService = ({ handleClose, open = false, service }: Props) => {
  const updateServiceImage = useCategoryStore((e) => e.updateServiceImage);
  const updateService = useCategoryStore((e) => e.updateService);
  const [imageUplading, setImageUplading] = React.useState(false);
  const [isLoading, setisLoading] = React.useState(false);
  const [formdata, setFormdata] = React.useState({
    service_name: service.service_name,
    service_description: service.service_description,
    service_price: service.service_price,
  });
  const [image, setImage] = React.useState<{
    file?: File;
    image?: string | ArrayBuffer;
  }>();
  const [closable, setClosable] = React.useState(true);
  function pickImage() {
    document.getElementById("fileType")?.click();
  }
  function handleChange(e: any) {
    const image = e.target.files[0];
    if (!image) return;
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onloadend = () => {
      !!reader.result && setImage({ file: image, image: reader.result });
      if (!!reader.result) {
        setImage({
          file: image,
          image: reader.result,
        });
        setClosable(false);
        setImageUplading(true);
        updateServiceImage(service.service_id, {
          file: image,
          image: reader.result,
        }).then(() => {
          setClosable(true);
          setImageUplading(false);
        });
      }
    };
    reader.onerror = () => {
      console.error("something went wrong!");
    };
  }
  function onValueChange(slug = "") {
    return (value: any) => {
      setFormdata((e) => ({ ...e, [slug]: value }));
    };
  }
  function close() {
    setImage({});
    setFormdata({
      service_name: formdata.service_name,
      service_description: formdata.service_description,
      service_price: formdata.service_price,
    });
    handleClose();
  }
  function checkForm() {
    let result = false;
    if (formdata.service_name.length === 0) {
      result = true;
      ToastErrorNotifier({
        message: "Nommer le service",
        position: "top-right",
      });
    }
    if (
      formdata.service_description.length === 0 ||
      formdata.service_description.split(" ").length < 5
    ) {
      result = true;
      ToastErrorNotifier({
        message: "Decriver le service, minimum 5 mots",
        position: "top-right",
      });
    }
    if (formdata.service_price === 0) {
      result = true;
      ToastErrorNotifier({
        message: "Entrer le cout du service",
        position: "top-right",
      });
    }
    return result;
  }
  function saveForm() {
    if (checkForm()) {
      return;
    }
    setisLoading(true);
    setClosable(false);
    updateService(service.service_id, {
      service_name: formdata.service_name,
      service_slug: generateSlug(formdata.service_name),
      service_description: formdata.service_description,
      service_price: formdata.service_price,
    }).then(async (reponse) => {
      if (reponse?.success) {
        setTimeout(() => {
          close();
        }, 300);
      }
      setClosable(true);
      setisLoading(false);
    });
  }
  return (
    <ModalCustom open={open} handleClose={closable ? close : undefined}>
      <div className="flex flex-col w-[40vw] max-w-[1000px] max-h-[90vh] phone:w-[calc(100vw-30px)] overflow-y-auto phone:space-y-3 space-y-5px-4 pb-4 items-center">
        <span className="flex text-[20px] text-darkgrey font-medium">
          Ajouter nouveau service
        </span>
        <div className="flex w-full flex-row phone:flex-col phone:space-x-0 phone:space-y-3 phone:items-center space-x-5">
          <div
            className="flex  phone:h-[150px] phone:w-[150px] w-[250px] h-[250px] relative bg-whitegrey/25 hover:bg-whitegrey/10 rounded-xl border-[2px] cursor-pointer"
            onClick={pickImage}
          >
            {!!image?.image ? (
              <>
                <img
                  className="flex  phone:h-[150px] phone:w-[150px] w-[250px] h-[250px] object-cover rounded-xl bg-black/10 p-2"
                  alt={image.image.toString()}
                  src={image.image.toString()}
                />
                <span className="flex text-[16px] phone:text-[13px] text-white absolute top-[15px] left-[15px] bg-black/20 hover:bg-black/30 active:bg-black/25 transition-colors duration-100 px-3 py-1 rounded-md">
                  Modifier l'image
                </span>
              </>
            ) : (
              <>
                <img
                  className="flex flex-1 object-cover rounded-xl bg-black/10 p-2"
                  alt={service.service_image.image_id.toString()}
                  src={service.service_image.image_link.toString()}
                />
                <span className="flex text-[16px] phone:text-[13px] text-white absolute top-[15px] left-[15px] bg-black/20 hover:bg-black/30 active:bg-black/25 transition-colors duration-100 px-3 py-1 rounded-md">
                  Modifier l'image
                </span>
              </>
            )}
            <div
              style={{ display: imageUplading ? "flex" : "none" }}
              className="flex absolute w-full h-full bg-black/50 justify-center items-center"
            >
              <svg
                className="animate-spin h-10 w-10 text-black/50"
                xmlns="http://www.w3.org/2000/svg"
                fill={"transparent"}
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke={"white"}
                  strokeWidth="2"
                ></circle>
                <path
                  className="opacity-75"
                  fill={"white"}
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </div>
          </div>
          <div className="flex flex-col flex-1 pt-1 w-full justify-center gap-3 max-h-[70vh] overflow-y-auto">
            <TextInput
              label="Nom du service"
              serviceholder="Entrer le nom du service"
              value={formdata.service_name}
              onTextChange={onValueChange("service_name")}
            />
            <TextInput
              label="Description du service"
              serviceholder="Decriver le service"
              multiline
              minlines={3}
              maxlines={3}
              value={formdata.service_description}
              onTextChange={onValueChange("service_description")}
            />
            <div className="flex flex-col p-1">
              <NumberInput
                title="Prix du service"
                defaultValue={formdata.service_price}
                onChangeValue={onValueChange("service_price")}
              />
            </div>
          </div>
        </div>
        <Button
          style={{
            backgroundColor: primaryColor,
            paddingBlock: 10,
            color: "white",
          }}
          sx={{
            px: 8,
          }}
          onClick={saveForm}
        >
          {!isLoading ? (
            <span className="flex font-medium">Enregistrer</span>
          ) : (
            <>
              <svg
                className="animate-spin h-6 w-6 text-black/50"
                xmlns="http://www.w3.org/2000/svg"
                fill={"transparent"}
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke={"white"}
                  strokeWidth="2"
                ></circle>
                <path
                  className="opacity-75"
                  fill={"white"}
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <span className="flex ml-3 normal-case">
                Patientez un instant
              </span>
            </>
          )}
        </Button>
        <input
          id="fileType"
          type="file"
          accept="image/*" //accept="image/*"
          className="hidden"
          onChange={handleChange}
        />
      </div>
    </ModalCustom>
  );
};

export default ManageService;
