import React from "react";

const VisitesBloc = () => {
  return (
    <div className="flex flex-row gap-5 p-5 pr-8 bg-white rounded-md items-center">
      <div className="flex h-[70px] aspect-square rounded-full border-[8px] border-primary items-center justify-center">
        <span className="flex text-[22px] font-medium text-primary">0</span>
      </div>
      <div className="flex flex-col gap-2 leading-none">
        <span className="flex text-[18px] font-medium text-darkgrey">
          Nombres de visiteurs (cette semaine)
        </span>
        <span className="flex text-[14px] cursor-pointer w-fit text-primary text-darkgrey/50 underline">
          Voir les statistiques
        </span>
      </div>
    </div>
  );
};

export default VisitesBloc;
