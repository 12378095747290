import React from "react";

const SoldeBloc = ({ text = "", value = "" }) => {
  return (
    <div className="flex flex-col items-center gap-3 p-4 rounded-md bg-white h-[250px] w-[250px]">
      <div className="flex flex-1 items-center justify-center">
        <span className="flex text-center text-[20px] font-medium">
          {text} :
        </span>
      </div>
      <span className="flex text-[18px] font-medium">{value}</span>
    </div>
  );
}


export const SoldeBlocMobile = ({ text = "", value = "" }) => {
  return (
    <div className="flex flex-col space-y-3 p-4 px-6 rounded-md bg-white">
      <div className="flex ">
        <span className="flex text-[20px] font-medium">
          {text} :
        </span>
      </div>
      <span className="flex text-[18px] font-medium">{value}</span>
    </div>
  );
}

export default SoldeBloc;
