import React from "react";
import { ConvenienceModel } from "../../../../../../models/models";
import { useCategoryStore } from "../../../../../../configs/stores/category";

type Props = {
  convenience: ConvenienceModel;
};

const ConvenienceItem = ({ convenience }: Props) => {
  const category = useCategoryStore((e) => e.category);
  const newConvenience = useCategoryStore((e) => e.newCategoryConvenience);
  const deleteConvenience = useCategoryStore(
    (e) => e.deleteCategoryConvenience
  );
  const [updatingPreview, setUpdatingPreview] = React.useState(false);
  const isActive =
    category?.category_convenience
      .map((e) => e.convenience_id.toString())
      .includes(convenience.convenience_id.toString()) ?? false;
  function updateConveniencePreview(bool: boolean) {
    setUpdatingPreview(true);
    if (!bool) {
      newConvenience(convenience.convenience_id.toString()).then(() => {
        setUpdatingPreview(false);
      });
    } else {
      deleteConvenience(convenience.convenience_id.toString()).then(() => {
        setUpdatingPreview(false);
      });
    }
  }
  return (
    <React.Fragment>
      <div
        key={convenience.convenience_id.toString()}
        className="flex relative select-none leading-tight max-w-[45vw] min-w-[500px] phone:min-w-0 phone:max-w-none phone:w-[calc(100vw-30px)] pr-[150px] phone:pr-4 flex-row bg-white border rounded-xl p-4 space-x-5"
      >
        <img
          src={convenience.convenience_icon}
          alt={convenience.convenience_slug}
          className="flex h-[100px] w-[100px] phone:h-[80px] phone:w-[80px] object-cover rounded-xl"
        />
        <div className="flex flex-1 flex-col gap-4">
          <div className="flex flex-col flex-1 justify-center gap-2">
            <span className="flex text-[18px] font-medium line-clamp-1">
              {convenience.convenience_name}
            </span>
            <div className="flex flex-col gap-x-1">
              <span className="text-[16px] font-normal line-clamp-2">
                {convenience.convenience_categorytype.length === 0 && (
                  <div className="flex border p-1 px-[6px] w-fit rounded-md">
                    <span className="flex text-[11px] font-medium">
                      Toutes les categories
                    </span>
                  </div>
                )}
                {convenience.convenience_categorytype.map((e) => (
                  <div className="flex border p-1 px-[6px] w-fit rounded-md">
                    <span className="flex text-[11px] font-medium">
                      {e.categorytype_name}
                    </span>
                  </div>
                ))}
              </span>
            </div>
            <span className="text-[14px] font-normal mt-1 line-clamp-2">
              {convenience.convenience_description}
            </span>
          </div>
          <div className="flex flex-row space-x-3 items-center transition-colors duration-100">
            <label
              htmlFor={`AcceptConditions_${convenience.convenience_id.toString()}`}
              className="relative h-8 w-14 cursor-pointer [-webkit-tap-highlight-color:_transparent]"
            >
              <input
                type="checkbox"
                id={`AcceptConditions_${convenience.convenience_id.toString()}`}
                className="peer sr-only [&:checked_+span_svg[data-checked-icon]]:block [&:checked+_span_svg[data-unchecked-icon]]:hidden"
                checked={isActive}
                onChange={(e) => {
                  updateConveniencePreview(isActive);
                }}
              />

              <span className="absolute inset-y-0 start-0 z-10 m-1 inline-flex h-6 w-6 items-center justify-center rounded-full bg-white text-gray-400 transition-all peer-checked:start-6 peer-checked:text-green-600">
                <svg
                  data-unchecked-icon
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>

                <svg
                  data-checked-icon
                  xmlns="http://www.w3.org/2000/svg"
                  className="hidden h-4 w-4"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>

              <span className="absolute inset-0 rounded-full bg-gray-300 transition peer-checked:bg-green-500"></span>
            </label>
            {updatingPreview && (
              <svg
                data-unchecked-icon
                className="animate-spin h-6 w-6 text-gray-300"
                xmlns="http://www.w3.org/2000/svg"
                fill={"transparent"}
                viewBox="0 0 24 24"
              >
                <circle
                  key={"zzz"}
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke={"blue"}
                  strokeWidth="2"
                ></circle>
                <path
                  className="opacity-75"
                  fill={"white"}
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ConvenienceItem;
