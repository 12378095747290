import React from "react";
import Logo from "../../assets/logoicon.png";
import TextInputSign from "../../components/inputs/textInputsign";
import PasswordInputSign from "../../components/inputs/passwordInput";
import { ToastErrorNotifier } from "../../constants";
import { useAuthStore } from "../../configs/stores/auth";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  const navigate = useNavigate();
  const login = useAuthStore((e) => e.login);
  const isLoading = useAuthStore((e) => e.isLoadingLogin);
  const isAuthenticated = useAuthStore((e) => e.isAuthenticated);

  const [formData, setFormData] = React.useState({
    login: "",
    password: "",
    rememberMe: false,
  });
  function onValueChange(slug: string) {
    return (value: any) => {
      setFormData((f) => ({ ...formData, [slug]: value }));
    };
  }
  function checkFormData() {
    let errorOccured = false;
    if (formData.login.length === 0) {
      errorOccured = true;
      let msg = "login incorrecte";
      ToastErrorNotifier({ message: msg });
    }
    if (formData.password.length === 0) {
      errorOccured = true;
      let msg = "mot de passe vide";
      ToastErrorNotifier({ message: msg });
    }
    return errorOccured;
  }

  function submitForm(e: any) {
    e?.preventDefault();
    if (checkFormData()) {
      console.log("Error Occured !!!");
      return;
    }
    let data = {
      login: formData.login,
      password: formData.password,
    };
    login(data);
  }

  React.useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <div className="flex flex-1 flex-col items-center justify-center h-screen">
      <form
        onSubmit={submitForm}
        className="flex flex-col p-5 space-y-5 border bg-white rounded-xl max-w-[400px] mx-5 shadow-md"
      >
        <div className="flex w-full justify-center pt-3">
          <img className="flex h-12" src={Logo} alt="logo" />
        </div>
        <div className="flex w-full justify-center pt-0 pb-1">
          <h2 className="flex text-center text-2xl phone:text-[20px] font-bold tracking-tight max-sm:text-xl w-[90%]">
            Connectez-vous a votre espace de gestion
          </h2>
        </div>
        <TextInputSign
          type={"text"}
          placeholder="Email ou Telephone"
          onTextChange={onValueChange("login")}
        />
        <PasswordInputSign
          placeholder="Mot de passe"
          title="Mot de passe"
          onTextChange={onValueChange("password")}
          errorState={{ state: false, msg: "" }}
        />
        <button
          disabled={isLoading}
          onClick={submitForm}
          style={{
            display: !isAuthenticated ? "flex" : "none",
          }}
          className="flex w-[99%] transition-all self-center justify-center items-center py-[9px] text-white text-[18px] font-medium bg-primary hover:bg-hover rounded-[20px] shadow-sm border"
        >
          <span className="-mt-[1px]">Connexion{isLoading && "..."}</span>
        </button>
        <div
          style={{
            display: !isAuthenticated ? "flex" : "none",
          }}
          className="flex flex-col"
        >
          <div className="hidden phone:flex flex-col items-center gap-3">
            <a
              href={"./forgot-password"}
              className=" hover:underline font-normal text-[14px] text-black/40"
            >
              Mot de passe oublié ?
            </a>
            <a
              className="flex w-fit"
              href={"https://www.lecoinchicdumoment.com/signup"}
              target="_blank"
              rel="noreferrer"
            >
              <p className="flex text-[18px] underline underline-offset-4 font-[600] text-[#F08400]">
                Créer un compte
              </p>
            </a>
          </div>
          <div className="phone:hidden flex justify-between text-[14px]">
            <a
              className="flex w-fit"
              href={"https://www.lecoinchicdumoment.com/signup"}
              target="_blank"
              rel="noreferrer"
            >
              <p className="flex text-[15px] underline underline-offset-4 font-[600] text-[#F08400]">
                Créer un compte
              </p>
            </a>
            <a
              href={"./forgot-password"}
              className=" hover:underline font-normal text-[#F08400]"
            >
              Mot de passe oublié ?
            </a>
          </div>
        </div>
        <div
          style={{
            display: isAuthenticated ? "flex" : "none",
          }}
          className="flex w-[99%] transition-all self-center justify-center items-center py-[9px] text-[18px] font-medium bg-green-500 rounded-[20px] shadow-sm border"
        >
          <span className="font-normal text-white">Connexion reussie !</span>
        </div>
      </form>
    </div>
  );
};

export default LoginPage;
