import React from "react";
import ModalCustom from "../../modal";
import { PlaceModel } from "../../../../models/models";
import { ToastErrorNotifier, primaryColor } from "../../../../constants";
import TextInput from "../../../inputs/textInput";
import NumberInput from "../../../inputs/placesInput";
import { Button } from "@mui/material";
import { useCategoryStore } from "../../../../configs/stores/category";
import { generateSlug } from "../../../../functions/generateSlug";

type Props = {
  handleClose: () => void;
  open: boolean;
  place: PlaceModel;
};

const ManagePlace = ({ handleClose, open = false, place }: Props) => {
  const updatePlace = useCategoryStore((e) => e.updatePlace);
  const updatePlaceImages = useCategoryStore((e) => e.updatePlaceImages);
  const [isLoading, setisLoading] = React.useState(false);
  const [imageUplading, setImageUplading] = React.useState(false);
  const [formdata, setFormdata] = React.useState({
    place_name: place.place_name,
    place_description: place.place_description,
    place_weekend: place.place_weekend,
    place_week: place.place_week,
  });
  const [image, setImage] = React.useState<{
    file?: File;
    image?: string | ArrayBuffer;
  }>();
  const [closable, setClosable] = React.useState(true);
  function pickImage() {
    document.getElementById("fileType")?.click();
  }
  function handleChange(e: any) {
    const image = e.target.files[0];
    if (!image) return;
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onloadend = () => {
      if (!!reader.result) {
        setImage({
          file: image,
          image: reader.result,
        });
        setClosable(false);
        setImageUplading(true);
        updatePlaceImages(place.place_id, {
          file: image,
          image: reader.result,
        }).then(() => {
          setClosable(true);
          setImageUplading(false);
        });
      }
    };
    reader.onerror = () => {
      console.error("something went wrong!");
    };
  }
  function onValueChange(slug = "") {
    return (value: any) => {
      setFormdata((e) => ({ ...e, [slug]: value }));
    };
  }
  function close() {
    setImage({});
    setFormdata({
      place_name: formdata.place_name,
      place_description: formdata.place_description,
      place_week: formdata.place_week,
      place_weekend: formdata.place_weekend,
    });
    handleClose();
  }
  function checkForm() {
    let result = false;
    if (formdata.place_name.length === 0) {
      result = true;
      ToastErrorNotifier({
        message: "Nommez la place",
        position: "top-right",
      });
    }
    if (
      formdata.place_description.length === 0 ||
      formdata.place_description.split(" ").length < 10
    ) {
      result = true;
      ToastErrorNotifier({
        message: "Decrivez la carte, minimum 10 mots",
        position: "top-right",
      });
    }
    if (formdata.place_week === 0) {
      result = true;
      ToastErrorNotifier({
        message: "Entrez un nombre superieur ou egal a 1",
        position: "top-right",
      });
    }
    if (formdata.place_weekend === 0) {
      result = true;
      ToastErrorNotifier({
        message: "Entrez un nombre superieur ou egal a 1",
        position: "top-right",
      });
    }
    return result;
  }
  function saveForm() {
    if (checkForm()) {
      return;
    }
    setisLoading(true);
    setClosable(false);
    updatePlace(place.place_id.toString(), {
      place_name: formdata.place_name,
      place_slug: generateSlug(formdata.place_name),
      place_description: formdata.place_description,
      place_week: formdata.place_week,
      place_weekend: formdata.place_weekend,
    }).then((reponse) => {
      if (reponse?.success) {
        setTimeout(() => {
          close();
        }, 300);
      }
      setClosable(true);
      setisLoading(false);
    });
  }
  return (
    <ModalCustom open={open} handleClose={closable ? close : undefined}>
      <div className="flex flex-col w-[50vw] max-w-[1200px] max-h-[90vh] phone:w-[calc(100vw-30px)] overflow-y-auto phone:space-y-3 space-y-5 px-4 pb-4 items-center">
        <span className="flex text-[20px] text-darkgrey font-medium">
          Modifier la place
        </span>
        <div className="flex w-full flex-row phone:flex-col phone:space-x-0 phone:space-y-3 space-x-5">
          <div
            className="flex h-[300px] w-[300px] phone:h-[150px] phone:w-[150px] relative bg-whitegrey/25 hover:bg-whitegrey/10 rounded-xl border-[2px] cursor-pointer"
            onClick={pickImage}
          >
            {!!image?.image ? (
              <>
                <img
                  className="flex flex-1 object-cover rounded-xl bg-black/10 p-2"
                  alt={image.image.toString()}
                  src={image.image.toString()}
                />
                <span className="flex text-[16px] phone:text-[13px] text-white absolute top-[15px] left-[15px] bg-black/20 hover:bg-black/30 active:bg-black/25 transition-colors duration-100 px-3 py-1 rounded-md">
                  Modifier l'image
                </span>
              </>
            ) : (
              <>
                <img
                  className="flex flex-1 object-cover rounded-xl bg-black/10 p-2"
                  alt={place.place_images[0].image_id.toString()}
                  src={place.place_images[0].image_link.toString()}
                />
                <span className="flex text-[16px] phone:text-[13px] text-white absolute top-[15px] left-[15px] bg-black/20 hover:bg-black/30 active:bg-black/25 transition-colors duration-100 px-3 py-1 rounded-md">
                  Modifier l'image
                </span>
              </>
            )}
            <div
              style={{ display: imageUplading ? "flex" : "none" }}
              className="flex absolute w-full h-full bg-black/50 justify-center items-center"
            >
              <svg
                className="animate-spin h-10 w-10 text-black/50"
                xmlns="http://www.w3.org/2000/svg"
                fill={"transparent"}
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke={"white"}
                  strokeWidth="2"
                ></circle>
                <path
                  className="opacity-75"
                  fill={"white"}
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </div>
          </div>
          <div className="flex flex-col flex-1  pt-[6px] justify-center gap-3 max-h-[70vh] overflow-y-auto">
            <TextInput
              label="Nom de la place"
              placeholder="Entrer le nom de la place"
              value={formdata.place_name}
              onTextChange={onValueChange("place_name")}
            />
            <TextInput
              label="Description de la place"
              placeholder="Decriver de la place"
              multiline
              minlines={1}
              maxlines={1}
              value={formdata.place_description}
              onTextChange={onValueChange("place_description")}
            />
            <div className="flex flex-col space-y-4 phone:space-y-2 pb-2 px-1">
              <div className="flex flex-col">
                <span className="flex">Places disponibles :</span>
              </div>
              <NumberInput
                title="En semaine"
                defaultValue={place.place_week}
                onChangeValue={onValueChange("place_week")}
              />
              <NumberInput
                title="Week-end"
                defaultValue={place.place_weekend}
                onChangeValue={onValueChange("place_weekend")}
              />
            </div>
          </div>
        </div>
        <Button
          style={{
            backgroundColor: primaryColor,
            paddingBlock: 10,
            color: "white",
          }}
          sx={{
            px: 8,
          }}
          onClick={saveForm}
        >
          {!isLoading ? (
            <span className="flex font-medium">Mettre a jour</span>
          ) : (
            <>
              <svg
                className="animate-spin h-6 w-6 text-black/50"
                xmlns="http://www.w3.org/2000/svg"
                fill={"transparent"}
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke={"white"}
                  strokeWidth="2"
                ></circle>
                <path
                  className="opacity-75"
                  fill={"white"}
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <span className="flex ml-3 normal-case">
                Patientez un instant
              </span>
            </>
          )}
        </Button>
        <input
          id="fileType"
          type="file"
          accept="image/*" //accept="image/*"
          className="hidden"
          onChange={handleChange}
        />
      </div>
    </ModalCustom>
  );
};

export default ManagePlace;
