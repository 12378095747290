import { getUrl, postUrl } from "../../apiRequest";

export function getManagerRoleByCompany(companySlug = "") {
  return getUrl("/role/one-by-company?slug=" + companySlug);
}

export function getCategories(companyId: string) {
  return getUrl("/category/all?id=" + companyId);
}

export function getManagers(companyId: string) {
  return getUrl("/role/manager/all?id=" + companyId);
}

export function updateGeolocation(data: any={}) {
  return postUrl("/auth/update/geolocation", data);
}

export function newCategory(data: any) {
  return postUrl("/category/new", data);
}
