import React from "react";
import { useCompanyStore } from "../../../../../../configs/stores/company";

const CategoryHeader = () => {
  const company = useCompanyStore((e)=>e.company)
  return (
    <div className="flex p-5 px-7 flex-row space-x-4 items-center bg-white rounded-[10px]">
      <img
        src={company?.company_logo.image_link.toString()}
        alt="companylogo"
        className="flex h-[70px] w-[70px] phone:h-[50px] phone:w-[50px] rounded-full object-cover"
      />
      <div className="flex flex-col space-y-2 leading-none">
        <span className="flex text-[20px] font-medium">
          {company?.company_name}
        </span>
        <span className="flex text-primary text-[13px] font-medium line-clamp-1 underline-offset-1 underline">
          {company?.company_country?.locality_name ?? "localisation"} {" - "}{" "}
          {company?.company_locality_precision ?? "localisation"}
        </span>
      </div>
    </div>
  );
};

export default CategoryHeader;
