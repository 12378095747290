import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useCompanyStore } from "../../configs/stores/company";
import { Popover } from "@mui/material";
import { CompanyRoutes } from "../../contexts/dashboard/defaults/subroutes";
import { useCategoryStore } from "../../configs/stores/category";
import { NavLink } from "react-router-dom";

const CompanyHeader = () => {
  const role = useCompanyStore((e) => e.role);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleAnchor = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popovekr" : undefined;
  return (
    <div
      className="hidden phone:flex flex-row rounded-[10px] ml-4 items-center py-[6px] px-2 gap-2"
      onClick={!open?handleAnchor:handleClose}
    >
      <div className="flex h-[30px] w-[30px] bg-slate-300 rounded-full  overflow-hidden">
        <LazyLoadImage
          src={role?.role_company.company_logo?.image_link.toString()}
          alt={role?.role_company.company_logo?.image_id.toString()}
          className="flex  h-[30px] w-[30px] rounded-full overflow-hidden"
          placeholderSrc={role?.role_company.company_logo?.image_miniature.toString()}
          height={35}
          width={35}
          effect="blur"
        />
      </div>
      <span className="line-clamp-1 text-[18px] max-w-[70%]">
        {role?.role_company.company_name}
      </span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        className="w-6 h-6"
      >
        <path
          fillRule="evenodd"
          d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z"
          clipRule="evenodd"
        />
      </svg>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{
          marginTop: 2,
          padding: 0,
        }}
        elevation={3}
      >
        <div className="flex flex-col w-[200px] overflow-y-auto h-auto max-h-[250px]">
          {CompanyRoutes.map((item, index) => {
            return (
              <CompanyNavItem
                key={index + (item.name?.toString() ?? "")}
                item={item}
                close={handleClose}
              />
            );
          })}
        </div>{" "}
      </Popover>
    </div>
  );
};

const CompanyNavItem = ({ item = CompanyRoutes[0], close = () => {} }) => {
  const category = useCategoryStore((e) => e.category);
  const selectRoute = useCategoryStore((e) => e.selectRoute);
  return (
    <NavLink
      to={
        category
          ? category.category_type.categorytype_slug +
            "/company" +
            (item.path === "" ? "" : "/" + item.path)
          : item.path ?? ""
      }
      end={(item.path ?? "") === ""}
      className={({ isActive }) => {
        if (isActive) {
          selectRoute(item.name ?? "");
          return "flex w-full px-5 py-2 bg-primary hover:bg-primary text-white";
        }
        return "flex w-full px-5 py-2 hover:bg-slate-100";
      }}
    >
      <span className={`flex text-base font-semibold`}>{item.name}</span>
    </NavLink>
  );
};

export default CompanyHeader;
