import React from "react";
import { useNavigate } from "react-router-dom";
import { useCompanyStore } from "../../../../configs/stores/company";

const CompanyHeader = () => {
  const navigate = useNavigate();
  const company  = useCompanyStore((e)=>e.company);
  const gotoGeolocation = () => {
    navigate("./geolocation");
  };
  return (
    <div className="flex p-5 px-7 phone:px-5 flex-row space-x-4 items-center bg-white rounded-[10px]">
      <img
        src={company?.company_logo.image_link.toString()}
        alt="companylogo"
        className="flex h-[70px] phone:h-[50px] phone:w-[50px] w-[70px] rounded-full object-cover"
      />
      <div className="flex flex-col space-y-2 leading-none">
        <span className="flex text-[26px] phone:text-[24px] font-medium">
          {company?.company_name}
        </span>
        <button
          onClick={gotoGeolocation}
          className="text-primary text-[13px] w-fit font-medium line-clamp-1 underline-offset-1 underline"
        >
          {company?.company_country?.locality_name ?? "localisation"} {" - "}{" "}
          {company?.company_locality_precision ?? "localisation"}
        </button>
      </div>
    </div>
  );
};

export default CompanyHeader;
