import React from "react";
import Logo from "../../assets/logoicon.png";
import { useAuthStore } from "../../configs/stores/auth";
import { Popover } from "@mui/material";
import AccountPopup from "./header/account/popup";
import { useRolesStore } from "../../configs/stores/roles";
import ModalCustom from "../../components/modals/modal";
import CompanyItemPhone from "./companyItemPhone";
import NewCompanyMobileModal from "../../components/modals/models/newcompany/mobile";

const PhoneCompanies = () => {
  const managerRoles = useRolesStore((e) => e.roles);
  const [search, setSearch] = React.useState("");
  const manager = useAuthStore((e) => e.manager);
  const [isOpenModel, setIsOpenModel] = React.useState(false);

  const haveCompanies = managerRoles.length !== 0;

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleAnchor = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function closeNewCompany() {
    setIsOpenModel(false);
  }

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <div className="flex w-full min-h-[65vh] max-h-[calc(100dvh-120px)]  overflow-y-auto flex-col bg-white shadow-lg rounded-[15px] p-4 gap-5">
      <div className="flex flex-row justify-between items-center">
        <a className="flex h-[50px]" href="/">
          <img className="flex h-full" src={Logo} alt="Logo" />
        </a>
        <div className="flex flex-row items-center gap-3 pr-2">
          <div className="flex flex-col justify-center gap-[1px] leading-tight">
            <span className="flex text-[13px] text-darkgrey font-medium">
              {manager?.user_name}
            </span>
            <span className="line-clamp-1 max-w-[150px] text-[13px] text-darkgrey font-medium">
              {manager?.user_surname}
            </span>
          </div>
          {!!manager?.user_profile ? (
            <div
              className="flex h-[35px] w-[35px] cursor-pointer relative"
              onClick={handleAnchor}
            >
              <img
                className="flex flex-1 rounded-full overflow-hidden object-cover"
                src={manager.user_profile.image_link.toString()}
                alt="profile"
              />
              <div className="flex absolute bottom-[1px] ml-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="white"
                  strokeWidth={3}
                  className="hidden w-4 h-4 group-hover:flex group-hover:animate-bounce"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </div>
          ) : (
            <div
              onClick={handleAnchor}
              className="flex rounded-full h-[35px] cursor-pointer w-[35px] bg-[rgba(150,150,150,.8)] justify-center items-center relative"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="white"
                className="w-5 h-5"
              >
                <path d="M10 8a3 3 0 100-6 3 3 0 000 6zM3.465 14.493a1.23 1.23 0 00.41 1.412A9.957 9.957 0 0010 18c2.31 0 4.438-.784 6.131-2.1.43-.333.604-.903.408-1.41a7.002 7.002 0 00-13.074.003z" />
              </svg>
              <div className="flex absolute bottom-[1px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="white"
                  strokeWidth={3}
                  className="hidden w-2 h-2 group-hover:flex group-hover:animate-bounce"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </div>
          )}
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            sx={{
              marginTop: 2,
              padding: 0,
            }}
            elevation={3}
          >
            <AccountPopup close={handleClose} />
          </Popover>
        </div>
      </div>
      {!haveCompanies && (
        <div className="flex flex-1 flex-col items-center justify-center gap-10">
          <span className="flex text-[19px] leading-tight text-center font-medium">
            Bienvenue sur votre espace personnel dédié à la gestion de vos
            établissements
          </span>
          <button
            className="flex px-5 py-[10px] items-center gap-3 transition-colors bg-primary hover:bg-hover active:bg-active rounded-md text-white"
            onClick={() => setIsOpenModel(true)}
          >
            <span className="flex font-medium">Ajouter mon établissement</span>
          </button>
        </div>
      )}
      {haveCompanies && (
        <div className="flex flex-1 flex-col gap-6">
          <span className="flex text-[20px] leading-tight text-center font-medium">
            Bienvenue sur votre espace personnel dédié à la gestion de vos
            établissements
          </span>
          <div className="flex flex-1 flex-col gap-4">
            <div className="flex items-center flex-row gap-3 rounded-md py-3 px-3 bg-whitegrey/60 border-1 border-darkgrey">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5 stroke-darkgrey/50"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                />
              </svg>
              <input
                className="flex flex-1 h-full w-auto min-w-0 outline-none text-[15px] bg-transparent"
                placeholder={"Rechercher un établissement"}
                onChange={(e) => {
                  setSearch(e.currentTarget?.value ?? "");
                }}
              />
            </div>
            <div className="flex flex-col w-full gap-1">
              <div className="flex flex-row justify-between">
                <div className="flex flex-row">
                  <span className="flex font-semibold">Etablissement</span>
                </div>
              </div>
              <span className="flex flex-col gap-1 py-1">
                {managerRoles
                  .filter((f) =>
                    search.length !== 0
                      ? f.role_company.company_name
                          .toLowerCase()
                          .includes(search.toLowerCase())
                      : true
                  )
                  .map((role, index) => {
                    return <CompanyItemPhone key={index} role={role} />;
                  })}
                {managerRoles.filter((f) =>
                  search.length !== 0
                    ? f.role_company.company_name
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    : true
                ).length === 0 && (
                  <div className="flex h-[55px] pl-3 items-center bg-whitegrey/60 rounded-md">
                    <span className="flex text-[16px]">
                      Desole, vous n'avez aucun établissement inscrit a ce nom
                    </span>
                  </div>
                )}
              </span>
            </div>
            <button
              className="flex px-5 py-[10px] items-center gap-3 transition-colors bg-primary hover:bg-hover active:bg-active rounded-md text-white"
              onClick={() => setIsOpenModel(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                strokeWidth={3}
                className="w-5 h-5"
              >
                <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
              </svg>
              <span className="flex font-medium">Ajouter un établissement</span>
            </button>
          </div>
        </div>
      )}
      <ModalCustom handleClose={closeNewCompany} open={isOpenModel} zeroPadding>
        <NewCompanyMobileModal close={closeNewCompany} />
      </ModalCustom>
    </div>
  );
};

export default PhoneCompanies;
