import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableHeader from "./head";
import { TableContextProps } from "./types";
import TableItem from "./item";
import { TableContext } from "./context";

function TableView({
  columns = [],
  rows = [],
  maxHeight = "auto",
  maxWidth = "100%",
  checkTitle = "",
  activeActions = false,
}: TableContextProps) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <TableContext.Provider
      value={{ columns, rows, maxHeight, maxWidth, checkTitle, activeActions }}
    >
      <Paper
        sx={{
          width: "100%",
          maxWidth: maxWidth,
          overflow: "hidden",
          zIndex: 0,
          position: "relative",
          borderRadius: 2,
        }}
        elevation={0}
      >
        <TableContainer sx={{ maxHeight: maxHeight, zIndex: 0 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHeader />
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return <TableItem row={row} key={index} />;
                })}
            </TableBody>
            {rows.length === 0 && (
              <TableHead>
                <TableRow>
                  <TableCell
                    align={"left"}
                    sx={{ width: "fit" }}
                    style={{
                      fontWeight: "700",
                    }}
                  >
                    Aucun
                  </TableCell>
                </TableRow>
              </TableHead>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </TableContext.Provider>
  );
}

export default TableView;
