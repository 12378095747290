import { Route, Outlet, Routes, Navigate } from "react-router-dom";
import PrivateRoute from "./middlewares/protectedRoute";
import GenerateSubRoutes from "./components/routes/generateSubRoutes";
import DashboardPage from "./pages/dashboard";
import MissingPage from "./pages/missing";
import LayoutPage from "./pages/layout";
import LoginPage from "./pages/login";
import Home from "./pages/home";
import "./App.css";
import CompaniesPage from "./pages/companies";
import {
  CompanyRoutes,
  SubRoutes,
} from "./contexts/dashboard/defaults/subroutes";
import CategoryDashPage from "./pages/dashboard/pages/categoryDash";
import CategoryProvider from "./contexts/category/provider";
import AuthBasicsProvider from "./contexts/authbasics/provider";
import CompanyProvider, { CompanyHomePage } from "./contexts/company/provider";

function StartRoutingApp() {
  return (
    <Routes>
      <Route path="/" element={<LayoutPage />}>
        <Route path="/">
          <Route element={<Home />}>
            <Route path="/" element={<div />} />
          </Route>
        </Route>
        <Route
          element={
            <PrivateRoute>
              <AuthBasicsProvider>
                <Outlet />
              </AuthBasicsProvider>
            </PrivateRoute>
          }
        >
          {RoutesRequiredAuth()}
        </Route>
        <Route path="login" element={<LoginPage />} />
        <Route path="*" element={<MissingPage />} />
      </Route>
    </Routes>
  );
}

export function RoutesRequiredAuth() {
  return (
    <Route element={<Outlet />}>
      <Route path="companies" element={<CompaniesPage />} />
      <Route path="company">
        <Route path="" element={<CompanyHomePage />} />
        <Route
          path=":companySlug"
          element={
            <CompanyProvider>
              <DashboardPage />
            </CompanyProvider>
          }
        >
          {GenerateSubRoutes(CompanyRoutes)}
          {RoutesDash()}
        </Route>
      </Route>
    </Route>
  );
}

export function RoutesDash() {
  return (
    <Route element={<Outlet />}>
      <Route
        path=":categoryTypeSlug"
        element={
          <CategoryProvider>
            <CategoryDashPage />
          </CategoryProvider>
        }
      >
        <Route path="" element={<Navigate to={"welcome"} />} />
        <Route path="company">{GenerateSubRoutes(CompanyRoutes)}</Route>
        {GenerateSubRoutes(SubRoutes)}
      </Route>
    </Route>
  );
}

export default StartRoutingApp;
