import React from "react";
import VisibilityOn from "../../assets/visibilityOn.svg";
import VisibilityOff from "../../assets/visibilityOff.svg";
import { errorColor } from "../../reactions/errorReaction";

function PasswordInputSign({
  title = "",
  placeholder = "••••••••••",
  errorState = { state: false, msg: "" },
  onTextChange = function (value: string) {},
  disabled = false,
}) {
  const [visible, setVisible] = React.useState(false);
  const [errorColorStatus, setErrorColorStatus] = React.useState(
    errorColor(errorState.state)
  );
  React.useEffect(() => {
    setErrorColorStatus(errorColor(errorState.state));
  }, [errorState]);
  return (
    <div className="flex flex-col w-full gap-1">
      <div className="flex w-full items-center justify-end">
        <input
          disabled={disabled}
          type={visible ? "text" : "password"}
          className={`flex w-full py-3 px-4 pr-10 border rounded-full shadow-sm text-[16px] max-sm:text-sm ${
            errorState.state ? "placeholder:text-red-700" : ""
          } ${errorColorStatus.focus.border} ${
            errorColorStatus.focus.outline
          } ${errorColorStatus.focus.ring} ${errorColorStatus.usage.border} ${
            errorColorStatus.usage.outline
          } ${errorColorStatus.usage.ring}`}
          placeholder={visible ? "0123456789" : placeholder}
          onChange={function (t) {
            onTextChange(t.currentTarget.value);
          }}
        />
        <div
          className="absolute z-auto rounded-full cursor-pointer p-[6px] mr-2 hover:bg-[#f1f3f7]"
          onClick={function () {
            setVisible(!visible);
          }}
        >
          <img
            className="flex h-5 w-5"
            src={visible ? VisibilityOn : VisibilityOff}
            alt="vsblt"
          />
        </div>
      </div>
      {errorState.state && errorState.msg.length !== 0 && (
        <p
          className={`flex ml-5 text-[12px] font-normal ${errorColorStatus.usage.text}`}
        >
          {errorState.msg}
        </p>
      )}
    </div>
  );
}

export default PasswordInputSign;
