import * as React from "react";
import { IconButton, Switch } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Visibility } from "@mui/icons-material";
import DownloadIcon from "@mui/icons-material/Download";

export const ViewIconButton = ({
  value = Object(),
  onClick = (data: any) => {},
}) => {
  return (
    <div className="flex">
      <IconButton
        edge="end"
        color="default"
        onClick={() => {
          !!onClick && onClick(value);
        }}
        aria-label="view"
        size="small"
      >
        <Visibility />
      </IconButton>
    </div>
  );
};

export const EditIconButton = ({
  value = Object(),
  onClick = (data: any) => {},
}) => {
  return (
    <div className="flex">
      <IconButton
        edge="end"
        color="default"
        onClick={() => {
          !!onClick && onClick(value);
        }}
        aria-label="edit"
        size="small"
      >
        <EditIcon />
      </IconButton>
    </div>
  );
};

export const DonwloadIconButton = ({
  value = Object(),
  onClick = (data: any) => {},
}) => {
  return (
    <div className="flex">
      <IconButton
        edge="end"
        color="default"
        onClick={() => {
          !!onClick && onClick(value);
        }}
        aria-label="download"
        size="small"
      >
        <DownloadIcon />
      </IconButton>
    </div>
  );
};

export const DeleteIconButton = ({
  value = Object(),
  onClick = (data: any) => {},
}) => {
  return (
    <div className="flex">
      <IconButton
        edge="end"
        color="default"
        onClick={() => {
          !!onClick && onClick(value);
        }}
        aria-label="delete"
        size="small"
      >
        <DeleteIcon />
      </IconButton>
    </div>
  );
};

export const ToggleStateIconButton = ({
  value = Object(),
  onClick = (data: any, bool: boolean) => {},
}) => {
  return (
    <div className="flex">
      <Switch
        size="small"
        checked={value}
        // sx={{ width: 45 }}
        onChange={(e, b) => {
          !!onClick && onClick(value, b);
        }}
        inputProps={{ "aria-label": "controlled" }}
      />
    </div>
  );
};
