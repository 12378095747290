import React from "react";
import ModalCustom from "../modal";
import { primaryColor } from "../../../constants";

const CategoryDatasLoadingModel = ({
  handleClose = () => {},
  open = false,
}) => {
  return (
    <ModalCustom closable={false} open={open} handleClose={handleClose}>
      <div className="flex flex-row gap-5 items-center p-4">
        <svg
          className="animate-spin h-6 w-6 text-black/50"
          xmlns="http://www.w3.org/2000/svg"
          fill={"transparent"}
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke={primaryColor}
            strokeWidth="2"
          ></circle>
          <path
            className="opacity-75"
            fill={primaryColor}
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
        <span className="flex flex-col leading-snug">
          <span className="flex">
            Patientez un instant...
          </span>
          <span className="flex">
            Chargement des donnees de la categorie !
          </span>
        </span>
      </div>
    </ModalCustom>
  );
};

export default CategoryDatasLoadingModel;
