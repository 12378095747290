import React from "react";
import UpdateCurrency from "../../../../components/modals/models/updatecurrency";
import { useCompanyStore } from "../../../../configs/stores/company";

const CurrencyUpdate = () => {
  const company = useCompanyStore((e)=>e.company)
  const [open, setOpen] = React.useState(false);
  function handleClose() {
    setOpen(false);
  }
  return (
    <React.Fragment>
      <div className="flex flex-col w-fit p-4 space-y-2 rounded-lg bg-white">
        <span className="flex text-[20px] font-medium">Devise principale</span>
        <div className="flex flex-row items-center gap-4">
          {!!company?.company_currency ? (
            <span className="flex text-[17px]">
              {company.company_currency.currency_name} - {company.company_currency.currency_isocode}
            </span>
          ) : (
            <span className="flex text-[15px] text-black/70">
              Aucune devise selectionner
            </span>
          )}
          <span
            onClick={() => setOpen(true)}
            className="flex text-[14px] cursor-pointer text-primary hover:text-hover active:text-active transition-colors duration-100"
          >
            Modifier la devise
          </span>
        </div>
      </div>
      <UpdateCurrency open={open} handleClose={handleClose} />
    </React.Fragment>
  );
};

export default CurrencyUpdate;
