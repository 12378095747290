import React from "react";

const CategoriesLoading = () => {
  return (
    <div className="flex flex-1 flex-col gap-3 bg-slate-200/30">
      <div className="flex pl-5 py-2 text-[16px] font-medium border-b bg-white">
        <span className="flex">Sélectionner une categorie</span>
      </div>
      <div className="flex flex-col gap-4 px-3">
        {Array.from({ length: 4 }).map((_, index) => {
          return (
            <div
              key={index}
              className="flex h-[50px] bg-[rgba(0,0,0,.15)] animate-pulse transition-all rounded-md"
            />
          );
        })}
      </div>
    </div>
  );
};

export default CategoriesLoading;
