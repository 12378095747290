import React from "react";
import Select, {
  GroupBase,
  OptionsOrGroups,
  components,
  ValueContainerProps,
  NoticeProps,
} from "react-select";
import { primaryColor } from "../../constants";

type Props = {
  label?: String;
  isMulti?: boolean;
  placeholder?: String;
  defaultOptions?: any;
  options: OptionsOrGroups<any, GroupBase<any>>;
  onChange?: (value: any | null) => void;
};

const ValueContainer = ({ children, ...props }: ValueContainerProps) => (
  <components.ValueContainer {...props}>{children}</components.ValueContainer>
);

const NoOptionsMessage = (props: NoticeProps) => {
  return (
    <components.NoOptionsMessage {...props}>
      Aucun resultat disponible !
    </components.NoOptionsMessage>
  );
};

const SimpleSelect = ({
  isMulti = false,
  options,
  defaultOptions,
  label,
  placeholder = "Selectionner...",
  onChange,
}: Props) => {
  const [isHover, setisHover] = React.useState(false);
  const [isFocused, setisFocused] = React.useState(!!defaultOptions);
  const [localValue, setlocalValue] = React.useState<any>(
    defaultOptions ?? null
  );
  return (
    <div
      className="flex flex-col relative"
      onMouseEnter={() => setisHover(true)}
      onMouseLeave={() => setisHover(false)}
    >
      <Select
        id="first"
        isMulti={isMulti}
        onFocus={() => label?.length !== 0 && setisFocused(true)}
        onBlur={() => label?.length !== 0 && setisFocused(false)}
        defaultValue={defaultOptions}
        options={options}
        placeholder={
          !!label && label.length !== 0 ? isFocused && placeholder : placeholder
        }
        onChange={(e, r) => {
          !!onChange && onChange(e);
          setlocalValue(e);
          // console.log(e);
        }}
        components={{
          ValueContainer,
          NoOptionsMessage,
          IndicatorSeparator: null,
        }}
        styles={{
          valueContainer: (base) => ({
            ...base,
            width: "100%",
            paddingBlock: 11.3,
            paddingLeft: 12,
          }) as any,
          control: (base, state) => ({
            ...base,
            fontSize: 16,
            fontFamily: "Sans-serif",
            "&:hover": {
              color: "black",
              borderColor: !state.isFocused ? "black" : primaryColor,
            },
          }) as any,
          dropdownIndicator: (base, state) => ({
            ...base,
            "&:hover": {
              color: !state.isFocused
                ? isHover
                  ? "rgba(0,0,0,.7)"
                  : base.color
                : primaryColor,
            },
            color: !state.isFocused
              ? isHover
                ? "rgba(0,0,0,.7)"
                : base.color
              : primaryColor,
            paddingInline: 10,
            paddingInlineEnd: 10,
          }) as any,
          menu: (base, state) => ({
            ...base,
            zIndex: 3,
          }) as any,
        }}
        theme={(theme) => ({
          ...theme,
          borderRadius: 6,
          borderWidth: 1,
          borderStyle: "solid",
          colors: {
            ...theme.colors,
            primary25: "rgb(240, 132, 0,.35)",
            primary: primaryColor,
          },
        })}
        isClearable
        isSearchable
      />
      <SelectLabel
        peerFocused={!!localValue && localValue.length !== 0 ? true : isFocused}
        realFocused={isFocused}
        label={label}
      />
    </div>
  );
};

type PropsLabel = {
  label?: String;
  peerFocused: Boolean;
  realFocused: Boolean;
};

function SelectLabel({
  label = "",
  peerFocused = false,
  realFocused = false,
}: PropsLabel) {//: PropsLabel
  return (
    <label
      style={{
        display: label.length !== 0 ? "flex" : "none",
        color: peerFocused
          ? realFocused
            ? "rgb(240, 132, 0)"
            : "rgba(75, 85, 99,.85)"
          : "rgba(75, 85, 99,.85)",
        fontSize: peerFocused ? 12 : 17,
        fontFamily: "Sans-serif",
        fontWeight: peerFocused ? "500" : "normal",
        transform: peerFocused
          ? "translate(-4px, -53%)"
          : "translate(-4px, 58%)",
      }}
      className="absolute left-[14px] cursor-text bg-white px-1 transition-all pointer-events-none"
      htmlFor="first"
    >
      {label}
    </label>
  );
}

export default SimpleSelect;
