import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import faker from "faker";
import { Months } from "../../../../constants";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      padding: 30,
      display: false,
      position: "top",
      align: "start",
    },
    title: {
      display: false,
      align: "start",
      position: "bottom",
      text: "Chart.js Line Chart",
    },
  },
};


const labels = Array.from({
  length: Months[new Date().getMonth()+1].length,
}).map((e, i) => (i + 1).toString());

export const data = {
  labels,
  datasets: [
    {
      label: "Achat",
      data: labels.map(() => faker.datatype.number({ min: -0, max: 0 })),
      borderColor: "rgb(240 132 0)",
      backgroundColor: "rgb(240 132 0)",
    },
    {
      label: "Paiement effectue",
      data: labels.map(() => faker.datatype.number({ min: -0, max: 0 })),
      borderColor: "#00FF00",
      backgroundColor: "#00FF00",
    },
  ],
};

const ReservationChartBloc = () => {
  return (
    <div className="flex bg-white flex-col rounded-[10px] h-[400px] w-[1200px] max-w-[calc(100vw-400px)] phone:max-w-[calc(100vw-30px)] space-y-3 p-6 pl-4 pr-6">
      <div className="flex flex-row phone:flex-col phone:space-y-2 justify-between items-center px-2">
        <span className="flex text-[16px] text-darkgrey font-medium">
          Recapitulatif sur un mois ({Months[new Date().getMonth()].name})
        </span>
        <div className="flex flex-row flex-wrap space-x-5 items-center">
          <div className="flex flex-row space-x-2 items-center">
            <div className="flex w-[35px] phone:w-[30px] h-4 rounded-md bg-primary"/>
            <span className="flex text-[17px] phone:text-[15px] font-medium text-primary">Achat</span>
          </div>
          <div className="flex flex-row space-x-2 items-center">
            <div className="flex w-[35px] phone:w-[30px] h-4 rounded-md bg-[#00FF00]"/>
            <span className="flex text-[17px] phone:text-[15px] font-medium text-[#00FF00]">Paiement Effectue</span>
          </div>
        </div>
      </div>
      <div className="flex relative space-x-3 flex-row w-full flex-1">
        <Line options={options} data={data} />
      </div>
    </div>
  );
};

export default ReservationChartBloc;
