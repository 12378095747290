import React from "react";
import { useNavigate } from "react-router-dom";
import SearchInTable from "../../../../components/searchInput/searchInTable";
import ConfirmActionModal from "../../../../components/modals/models/confirmAction";
import { getPropValue } from "../../../../functions/getPropValue";
import { UserModel } from "../../../../models/models";
import { useCompanyStore } from "../../../../configs/stores/company";
import TableView from "../../../../components/table";

const ManagersTable = () => {
  const managers = useCompanyStore((e)=>e.managers)
  const [modalConfig, setModalConfig] = React.useState({
    open: false,
    actionType: "",
    actionMessage: "",
    actionData: {},
  });
  const [searchInput, setsearchInput] = React.useState("");
  const navigate = useNavigate();
  // function navigateToNewRole() {
  //   navigate("new");
  // }
  function CloseModal() {
    setModalConfig({
      open: false,
      actionType: "",
      actionMessage: "",
      actionData: {},
    });
  }
  function edit(data: UserModel) {
    navigate("./" + data.user_id);
  }
  async function remove(data: UserModel) {
    // const e = await deleteUserData(data.user_id);
    // await refetch();
    return true;
  }
  const actions = {
    edit,
    remove,
    toggle: () => {},
  };
  const columns = [
    {
      label: "Nom",
      slug: "manager_name",
    },
    {
      label: "Email",
      slug: "manager_email",
    },
    {
      label: "Role",
      slug: "manager_role",
    },
    {
      label: "Derniere modification",
      slug: "role_updateAt",
    },
  ];
  const rows = managers
    .filter((f) =>
      searchInput.length !== 0
        ? f.role_user.user_name
            .toLowerCase()
            .includes(searchInput.toLowerCase())
        : true
    )
    .map((e) => {
      return {
        manager_name: e.role_user.user_name + " " + e.role_user.user_surname,
        manager_email: e.role_user.user_email,
        manager_role: e.role_type.roletype_name,
        role_updateAt: e.role_updateAt,
      };
    });
  return (
    <div>
      <div className="flex flex-row flex-wrap items-end gap-4 text-[17px] mb-4">
        <SearchInTable
          placeholder="Entrer le nom ou l'email du gestionnaire rechercher..."
          onChange={setsearchInput}
        />
        <div className="flex cursor-pointer flex-row gap-3 p-3 py-[10px] px-5 rounded-md items-center text-white fill-white bg-primary active:bg-active hover:bg-hover">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="w-6 h-6"
          >
            <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
          </svg>
          <span className="flex font-medium text-[17px]">
            Ajouter un gestionnaire
          </span>
        </div>
      </div>
      <TableView
        columns={columns}
        rows={rows}
        activeActions
        editable
        removable
        togglable
        onEdit={function (r) {
          setModalConfig({
            open: true,
            actionType: "edit",
            actionMessage: "Vous-vous vraiment modifier ?",
            actionData: r,
          });
        }}
        onRemove={function (r) {
          setModalConfig({
            open: true,
            actionType: "remove",
            actionMessage: "Vous-vous vraiment supprimer ?",
            actionData: r,
          });
        }}
        onToggle={function (r, v) {
          setModalConfig({
            open: true,
            actionType: "toggle",
            actionMessage: `Vous-vous vraiment ${
              v ? "desactiver" : "activer"
            } ?`,
            actionData: v,
          });
        }}
      />
      <ConfirmActionModal
        confirm={() => {
          return !!getPropValue(actions, modalConfig.actionType)
            ? getPropValue(
                actions,
                modalConfig.actionType
              )(modalConfig.actionData)
            : undefined;
        }}
        message={modalConfig.actionMessage}
        open={modalConfig.open}
        handleClose={CloseModal}
      />
    </div>
  );
};

export default ManagersTable;
