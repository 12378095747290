import React from "react";
import PageDescription from "../../../../components/descriptionCard/pageDescription";
import ManagersTable from "./table";

const DashManagers = () => {
  return (
    <div className="flex h-auto flex-col gap-6">
      <span className="flex text-[30px] font-medium text-darkgrey">
        GESTIONNAIRES
      </span>
      <PageDescription
        title="Description de la page"
        description="Bienvenue sur la page de gestion des catégories 
        d'établissements ! Cet espace est dédié aux administrateurs pour 
        gérer les différentes catégories auxquelles nos établissements 
        peuvent être associés. Vous pouvez ici ajouter de nouvelles 
        catégories pertinentes, modifier les catégories existantes ou 
        supprimer celles qui ne sont plus nécessaires. Cela permettra de 
        mieux organiser notre base de données et faciliter la recherche pour 
        nos utilisateurs."
      />
      <ManagersTable />
    </div>
  );
};

export default DashManagers;
