import React from "react";
import { Drawer } from "antd";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  children: any;
};

const DrawerBar = ({ isOpen, onClose, children }: Props) => {
  return (
    <Drawer
      placement={"left"}
      closable={false}
      onClose={onClose}
      open={isOpen}
      key={"left"}
      style={{
        width: window.innerWidth * 0.7,
      }}
      styles={{
        body: {
          paddingTop:10,
          paddingInline: 0,
        },
      }}
    >
      {children}
    </Drawer>
  );
};

export default DrawerBar;
