import { deleteUrl, getUrl, postUrl } from "../../configs/apiRequest";
import { decrypt, encrypt } from "../../configs/encryption";
import { ANY } from "../../constants";
import { generateUniqueName } from "../../functions/generateUniqueName";
import { UserRoleModel } from "../../models/models";

export function getSelectedCompany(): Promise<{
  role: UserRoleModel | null;
}> {
  return new Promise<any>((resolve, reject) => {
    setTimeout(() => {
      const role = localStorage.getItem(generateUniqueName("role"));
      if (role) {
        resolve({
          role: decrypt(
            role,
            process.env.REACT_APP_Key,
            process.env.REACT_APP_Iv
          ) as UserRoleModel,
        });
      } else {
        resolve({
          role: null,
        });
      }
    }, 300);
  });
}

export function setSelectedCompany({ role = ANY }): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    localStorage.setItem(
      generateUniqueName("role"),
      encrypt(
        JSON.stringify(role),
        process.env.REACT_APP_Key,
        process.env.REACT_APP_Iv
      )
    );
    setTimeout(() => {
      resolve({});
    }, 10);
  });
}

export function getCompanyRoleData(slug: any) {
  return getUrl(`/company/manager/role?slug=${slug}`);
}

export function getBillingsData(id: string) {
  return getUrl("/company/billing/all?id=" + id);
}

export function postCompanyNewCategory(data: any) {
  return postUrl("/company/category/new", data);
}

export function postCompanyNewMenuData(data: any) {
  return postUrl("/company/category/menucard/menu/new", data);
}

export function getCompanyServicesData(data: any) {
  return getUrl("/company/category/service/all?id=" + data);
}

export function updateCompanyCategory(data: any) {
  return postUrl("/company/category/update", data);
}

export function postCompanyNewServiceData(data: any) {
  return postUrl("/company/category/service/new", data);
}

export function deleteCompanyCategoryService(data: any) {
  return deleteUrl("/company/category/service/delete?id=" + data);
}

export function updateServiceImageData(id: string, data: any) {
  return postUrl("/company/category/service/update/image?id=" + id, data);
}

export function postCompanyMenuCardData(data: any) {
  return postUrl("/company/category/menucard/new", data);
}

export function updateCompanyCurrencyData(data: any) {
  return postUrl("/company/update", data);
}

export function getCompanyMenuCards(data: any) {
  return getUrl("/company/category/menucard/all?id=" + data);
}

export function deleteCompanyMenuCard(data: any) {
  return deleteUrl("/company/category/menucard/delete?id=" + data);
}

export function updateMenuCardImageData(id: string, data: any) {
  return postUrl("/company/category/menucard/update/image?id=" + id, data);
}

export function updateCategoryImageData(data: any) {
  return postUrl("/company/category/update/image", data);
}

export function getCompanyCategoryImages(data: any) {
  return getUrl("/image/all?id=" + data);
}

export function updateMenuImageData(id: string, data: any) {
  return postUrl("/company/category/menucard/menu/update/image?id=" + id, data);
}

export function getCompanyMenus(data: any) {
  return getUrl("/company/category/menucard/menu/all?id=" + data);
}

export function deleteCompanyMenu(data: any) {
  return deleteUrl("/company/category/menucard/menu/delete?id=" + data);
}

export function updateMenuImagesData(id: string, data: any) {
  return postUrl("/company/category/menucard/menu/update/image?id=" + id, data);
}

export function getConveniences() {
  return getUrl("/convenience/all");
}

export function deleteCompanyCategoryImage(data: any) {
  return deleteUrl("/image/delete?id=" + data);
}

export function deleteCompanyCategory(data: any) {
  return deleteUrl("/company/category/delete?id=" + data);
}

export function deleteCompany(data: any) {
  return deleteUrl("/company/delete?id=" + data);
}

export function postGeolocationData(data: any={}) {
  return postUrl("/company/update/geolocation", data);
}
