import React from "react";
import PageDescription from "../../../../../../components/descriptionCard/pageDescription";

const CategoryCustomerReview = () => {
  return (
    <div className="flex h-auto flex-col gap-6">
      <span className="flex text-[30px] font-medium text-darkgrey">
        AVIS ET COMMENTAIRES
      </span>
      <PageDescription
        title="Description de la page"
        description="Bienvenue sur la page de gestion des avis clients ! 
        Cet espace est dédié aux administrateurs pour gérer les différents avis et commentaires laissés par vos clients."
      />
    </div>
  );
};

export default CategoryCustomerReview;
