import React from "react";
import { useCompanyStore } from "../../../../configs/stores/company";

const ManagerBloc = () => {
  const managers = useCompanyStore((e) => e.managers);
  return (
    <div className="flex phone:hidden bg-white justify-between p-4 px-5 flex-row rounded-md gap-4 max-w-[1200px]">
      <div className="flex flex-row gap-3 items-center">
        <div className="flex h-[50px] aspect-square rounded-full border-[5px] border-primary items-center justify-center">
          <span className="flex text-[22px] font-medium text-primary">
            {managers.length}
          </span>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          className="w-8 h-8"
        >
          <path d="M10 9a3 3 0 100-6 3 3 0 000 6zM6 8a2 2 0 11-4 0 2 2 0 014 0zM1.49 15.326a.78.78 0 01-.358-.442 3 3 0 014.308-3.516 6.484 6.484 0 00-1.905 3.959c-.023.222-.014.442.025.654a4.97 4.97 0 01-2.07-.655zM16.44 15.98a4.97 4.97 0 002.07-.654.78.78 0 00.357-.442 3 3 0 00-4.308-3.517 6.484 6.484 0 011.907 3.96 2.32 2.32 0 01-.026.654zM18 8a2 2 0 11-4 0 2 2 0 014 0zM5.304 16.19a.844.844 0 01-.277-.71 5 5 0 019.947 0 .843.843 0 01-.277.71A6.975 6.975 0 0110 18a6.974 6.974 0 01-4.696-1.81z" />
        </svg>
        <span className="flex text-[18px] font-medium text-darkgrey">
          Nombres de gestionnaires enregistrer
        </span>
      </div>
      <div className="flex cursor-pointer flex-row gap-3 p-3 px-5 rounded-md items-center text-white fill-white bg-primary active:bg-active hover:bg-hover">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          className="w-6 h-6"
        >
          <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
        </svg>
        <span className="flex font-medium text-[17px]">Ajouter</span>
      </div>
    </div>
  );
};

export const ManagerBlocMobile = () => {
  const managers = useCompanyStore((e) => e.managers);
  return (
    <div className="hidden phone:flex bg-white justify-between p-4 px-5 flex-col rounded-md gap-4 max-w-[1200px]">
      <div className="flex flex-col gap-3 items-start">
        <div className="flex h-[50px] w-[50px] rounded-full border-[5px] border-primary items-center justify-center">
          <span className="flex text-[22px] font-medium text-primary">
            {managers.length}
          </span>
        </div>
        <div className="flex flex-row space-x-3 items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="w-10 h-10"
          >
            <path d="M10 9a3 3 0 100-6 3 3 0 000 6zM6 8a2 2 0 11-4 0 2 2 0 014 0zM1.49 15.326a.78.78 0 01-.358-.442 3 3 0 014.308-3.516 6.484 6.484 0 00-1.905 3.959c-.023.222-.014.442.025.654a4.97 4.97 0 01-2.07-.655zM16.44 15.98a4.97 4.97 0 002.07-.654.78.78 0 00.357-.442 3 3 0 00-4.308-3.517 6.484 6.484 0 011.907 3.96 2.32 2.32 0 01-.026.654zM18 8a2 2 0 11-4 0 2 2 0 014 0zM5.304 16.19a.844.844 0 01-.277-.71 5 5 0 019.947 0 .843.843 0 01-.277.71A6.975 6.975 0 0110 18a6.974 6.974 0 01-4.696-1.81z" />
          </svg>
          <span className="flex text-[18px] w-fit leading-tight font-medium text-darkgrey">
            Nombres de gestionnaires enregistrer
          </span>
        </div>
      </div>
      <div className="flex cursor-pointer flex-row gap-3 p-3 px-5 rounded-md items-center text-white fill-white bg-primary active:bg-active hover:bg-hover">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          className="w-6 h-6"
        >
          <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
        </svg>
        <span className="flex font-medium text-[17px]">Ajouter</span>
      </div>
    </div>
  );
};

export default ManagerBloc;
