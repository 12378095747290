import PageDescription from "../../../../../../components/descriptionCard/pageDescription";
import AddPhoto from "./add";
import PhotoItem from "./item";
import { useCategoryStore } from "../../../../../../configs/stores/category";
import clsx from "clsx";
import { useOneEffect } from "../../../../../../hooks/useOnEffect";

const CategoryPhotos = () => {
  const images = useCategoryStore((e) => e.images);
  const isLoadingImages = useCategoryStore((e) => e.isLoadingImages);
  const getImages = useCategoryStore((e) => e.getImages);
  const refetchImages = useCategoryStore((e) => e.refetchImages);
  const isRefetchImages = useCategoryStore((e) => e.isRefetchImages);
  useOneEffect(() => {
    if (!isLoadingImages) {
      getImages();
    } else {
      refetchImages();
    }
  }, []);
  return (
    <div className="flex h-auto flex-col space-y-5">
      <span className="flex text-[30px] phone:text-[25px] font-medium text-darkgrey">PHOTOS</span>
      <PageDescription
        title="Description de la page"
        description="Bienvenue sur la page de gestion des photos ! 
        Cet espace est dédié aux administrateurs pour gérer les différentes photos de votre établissement."
      />
      <div className="flex flex-row flex-wrap items-end gap-4 text-[17px]">
        <button
          onClick={refetchImages}
          className="flex px-4 py-2 gap-2 items-center flex-row rounded-md bg-primary text-white hover:bg-hover active:bg-active"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2.5}
            stroke="currentColor"
            className={clsx("w-6 h-6", isRefetchImages && "animate-spin")}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
            />
          </svg>
          <span className="flex text-[16px]">Rafraichir</span>
        </button>
      </div>
      <div className="flex w-full bg-white min-h-[100px] h-fit max-w-[1250px] rounded-lg p-5">
        <div className="grid phone:grid-cols-1 grid-cols-3 gap-4 w-full">
          {images
            .filter((e) => e.image_ispreview)
            .map((item, index) => {
              return (
                <PhotoItem
                  image={item}
                  key={index.toString() + item.image_id.toString()}
                />
              );
            })}
          {images
            .filter((e) => !e.image_ispreview)
            .map((item, index) => {
              return (
                <PhotoItem
                  image={item}
                  key={index.toString() + item.image_id.toString()}
                />
              );
            })}
          {images.length < 12 && <AddPhoto />}
        </div>
      </div>
    </div>
  );
};

export default CategoryPhotos;
