import React from "react";
import { useNavigate } from "react-router-dom";
import { wait } from "@testing-library/user-event/dist/utils";
import ConfirmActionModal from "../../../../components/modals/models/confirmAction";
import { useCompanyStore } from "../../../../configs/stores/company";
import { useRolesStore } from "../../../../configs/stores/roles";
import { deleteCompany } from "../../../../configs/stores/functions/auth";

const DeleteCompany = () => {
  const refetchRoles = useRolesStore((e)=>e.refetchRoles)
  const company = useCompanyStore((e)=>e.company)
  const [openDeleteConfirmation, setOpenDeleteConfirmation] =
    React.useState(false);
  function closeModal() {
    setOpenDeleteConfirmation(false);
  }
  const navigate = useNavigate();
  async function confirmAction() {
    await deleteCompany(company?.company_id??"");
    return refetchRoles().then(async (t) => {
      closeModal();
      await wait(200);
      navigate(`/companies`);
    });
  }
  return (
    <React.Fragment>
      <button
        onClick={() => setOpenDeleteConfirmation(true)}
        className="flex w-fit rounded-md px-5 py-[10px] text-[17px] font-medium bg-red-500/20 text-red-500 hover:bg-red-500/30 active:bg-red-500/50"
      >
        <span className="flex">Supprimer cet etablissement</span>
      </button>
      <ConfirmActionModal
        confirm={confirmAction}
        message={`Voulez-vous supprimer ${company?.company_name}?`}
        open={openDeleteConfirmation}
        handleClose={closeModal}
      />
    </React.Fragment>
  );
};

export default DeleteCompany;
