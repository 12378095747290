import React from "react";
import { useCategoryReservationStore } from "../../../../../../../configs/stores/reservations";

type Props = {
  tab?: {
    label: string;
    value: string;
  };
};

const ReservationTabTitle = ({ tab = { label: "", value: "" } }: Props) => {
  const tabState = useCategoryReservationStore((e) => e.tabState);
  const updateTabState = useCategoryReservationStore((e) => e.updateTabState);
  const isSelected = React.useMemo(
    () => tab.value === tabState,
    [tab.value, tabState]
  );
  function selectTab() {
    updateTabState(tab.value);
  }
  return (
    <button
      onClick={selectTab}
      className={`flex text-[17px] min-w-[150px] pl-4 p-2 px-3 rounded-md transition-all duration-150 ${
        isSelected ? "bg-primary text-white font-semibold" : "bg-white hover:bg-white/75 text-black/50 hover:text-black"
      }`}
    >
      <span className="flex text-[18px]">{tab.label}</span>
    </button>
  );
};

export default ReservationTabTitle;
