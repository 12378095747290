import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import BasicsProvider from "./contexts/basics/provider";
import AuthProvider from "./contexts/auth/provider";
import StartRoutingApp from "./App";
import { NavigationLinkManager } from "./components/buttons/navigationLinkManager";
import { ThemeProvider } from "@mui/material/styles";
import "mapbox-gl/dist/mapbox-gl.css";
import { queryClient } from "./configs/queryClient";
import { themeMui } from "./themes/mui";
import "react-lazy-load-image-component/src/effects/blur.css";

function Index() {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={themeMui}>
            <BasicsProvider>
              <AuthProvider>
                <StartRoutingApp />
              </AuthProvider>
            </BasicsProvider>
            <NavigationLinkManager />
          </ThemeProvider>
          <ToastContainer />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
}

const rootElement = document.getElementById("root") as HTMLElement;
ReactDOM.createRoot(rootElement).render(<Index />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
