import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { produce } from "immer";
import { ZustandLocalStorageCrypted } from "./utils";
import { UserModel } from "../../models/models";
import { ToastErrorNotifier, ToastSuccessNotifier } from "../../constants";
import { getManagerData, login, logout } from "./functions/auth";

const LOCAL_DATASTORE_NAME = "coinchicpro-auth";

type ContextProps = {
  tokens: string | null;
  isAuthenticated: boolean;
  isLoadingLogin: boolean;
  isLoadingLogout: boolean;
  manager: UserModel | null;
  isRefreshingUser: boolean;
  login: (credentials: { login: string; password: string }) => Promise<{
    [x: string]: any;
    success: Boolean;
    message: String;
    data: any;
  }>;
  logout: (cb?: () => void) => Promise<void>;
  refetchUser: () => Promise<{
    [x: string]: any;
    success: Boolean;
    message: String;
    data: any;
  }>;
  refreshToken: (tokens: string) => Promise<any>;
};

export const useAuthStore = create<
  ContextProps,
  [["zustand/persist", ContextProps]]
>(
  persist(
    (set, get) => ({
      tokens: null,
      isAuthenticated: false,
      isLoadingLogin: false,
      isLoadingLogout: false,
      manager: null,
      isRefreshingUser: false,
      refreshToken: async (tokens: string) => {
        set(
          produce((state: ContextProps) => {
            state.tokens = tokens;
          })
        );
      },
      login: async (credentials) => {
        set(
          produce((state: ContextProps) => {
            state.isLoadingLogin = true;
          })
        );
        const res = await login(credentials);
        const { success = false, message = "", data = null } = res;
        set(
          produce((state: ContextProps) => {
            if (success) {
              state.manager = data;
              state.tokens = res.tokens;
            }
          })
        );
        set(
          produce((state: ContextProps) => {
            state.isLoadingLogin = false;
            if (success) {
              ToastSuccessNotifier({ message: "Connexion reussie !" });
              state.isAuthenticated = true;
            } else {
              state.isAuthenticated = false;
              ToastErrorNotifier({
                message: message.toString(),
                position: "top-center",
              });
            }
          })
        );
        return res;
      },
      logout: async (cb) => {
        const state = get();
        if (state.isLoadingLogout) {
          return;
        }
        set(
          produce((state: ContextProps) => {
            state.isLoadingLogout = true;
          })
        );
        await logout();
        set(
          produce((state: ContextProps) => {
            state.tokens = null;
            state.isLoadingLogout = false;
            state.isAuthenticated = false;
            state.manager = null;
          })
        );
        !!cb && cb();
      },
      refetchUser: async () => {
        set(
          produce((state: ContextProps) => {
            state.isRefreshingUser = true;
          })
        );
        const res = await getManagerData();
        const { success = false, message = "", data = null } = res;
        set(
          produce((state: ContextProps) => {
            state.isRefreshingUser = false;
            if (success) {
              state.manager = data;
              if (!state.isAuthenticated) {
                state.isAuthenticated = true;
              }
            } else {
              state.isAuthenticated = false;
              ToastErrorNotifier({
                message: message.toString(),
                position: "top-center",
              });
              state.manager = null;
            }
          })
        );
        return res;
      },
    }),
    {
      name: LOCAL_DATASTORE_NAME,
      storage: createJSONStorage(() => ZustandLocalStorageCrypted("dat_ath")),
    }
  )
);
