import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { produce } from "immer";
import { ZustandLocalStorageCrypted } from "./utils";
import {
  CategoryModel,
  CompanyModel,
  UserRoleModel,
} from "../../models/models";
import {
  getCategories,
  getManagerRoleByCompany,
  getManagers,
  newCategory,
  updateGeolocation,
} from "./functions/company";

const LOCAL_DATASTORE_NAME = "coinchicpro-company";

type ContextProps = {
  role: UserRoleModel | null;
  company: CompanyModel | null;
  isLoadingRole: boolean;
  isRefetchRole: boolean;
  categories: Array<CategoryModel>;
  isLoadingCategories: boolean;
  isRefetchCategories: boolean;
  managers: Array<UserRoleModel>;
  isLoadingManagers: boolean;
  isRefetchManagers: boolean;
  getRole: (companySlug: string) => Promise<any>;
  refetchRole: () => Promise<any>;
  getCategories: () => Promise<any>;
  refetchCategories: () => Promise<any>;
  getManagers: () => Promise<any>;
  refetchManagers: () => Promise<any>;
  updateGeolocation: (data: any) => Promise<any>;
  newCategory: (data: any) => Promise<any>;
  reset: () => void;
};

export const useCompanyStore = create<
  ContextProps,
  [["zustand/persist", ContextProps]]
>(
  persist(
    (set, get) => ({
      role: null,
      company: null,
      isLoadingRole: true,
      isRefetchRole: false,
      categories: Array<CategoryModel>(),
      isLoadingCategories: false,
      isRefetchCategories: false,
      managers: Array<UserRoleModel>(),
      isLoadingManagers: false,
      isRefetchManagers: false,
      getRole: async (company) => {
        const state = get();
        set(
          produce((state: ContextProps) => {
            state.isLoadingRole = true;
          })
        );
        if (!company) {
          set(
            produce((state: ContextProps) => {
              state.isLoadingRole = false;
            })
          );
          return;
        }
        const res = await getManagerRoleByCompany(company);
        const data = (res?.data ?? {}) as UserRoleModel;
        set(
          produce((state: ContextProps) => {
            state.role = data;
            state.company = data.role_company;
            state.isLoadingRole = false;
          })
        );
        res.success && state.getCategories();
      },
      refetchRole: async () => {
        const state = get();
        set(
          produce((state: ContextProps) => {
            state.isRefetchRole = true;
          })
        );
        if (!state.company) {
          set(
            produce((state: ContextProps) => {
              state.isRefetchRole = false;
            })
          );
          return;
        }
        const res = await getManagerRoleByCompany(
          state.company.company_slug.toString()
        );
        const data = (res?.data ?? {}) as UserRoleModel;
        set(
          produce((state: ContextProps) => {
            state.role = data;
            state.company = data.role_company;
            state.isRefetchRole = false;
          })
        );
        res.success && state.refetchCategories();
      },
      getCategories: async () => {
        const state = get();
        set(
          produce((state: ContextProps) => {
            state.isLoadingCategories = true;
          })
        );
        if (!state.company) {
          set(
            produce((state: ContextProps) => {
              state.isLoadingCategories = false;
            })
          );
          return;
        }
        const res = await getCategories(
          state.company?.company_id.toString() ?? ""
        );
        const data = (res?.data ??
          Array<CategoryModel>()) as Array<CategoryModel>;
        set(
          produce((state: ContextProps) => {
            state.categories = data;
            state.isLoadingCategories = false;
          })
        );
        res.success && state.getManagers();
      },
      refetchCategories: async () => {
        const state = get();
        set(
          produce((state: ContextProps) => {
            state.isRefetchCategories = true;
          })
        );
        if (!state.company) {
          set(
            produce((state: ContextProps) => {
              state.isRefetchCategories = false;
            })
          );
          return;
        }
        const res = await getCategories(
          state.company?.company_id.toString() ?? ""
        );
        const data = (res?.data ??
          Array<CategoryModel>()) as Array<CategoryModel>;
        set(
          produce((state: ContextProps) => {
            state.categories = data;
            state.isRefetchCategories = false;
          })
        );
      },
      getManagers: async () => {
        const state = get();
        set(
          produce((state: ContextProps) => {
            state.isLoadingManagers = true;
          })
        );
        if (!state.company) {
          set(
            produce((state: ContextProps) => {
              state.isLoadingManagers = false;
            })
          );
          return;
        }
        const res = await getManagers(
          state.company?.company_id.toString() ?? ""
        );
        const data = (res?.data ??
          Array<UserRoleModel>()) as Array<UserRoleModel>;
        set(
          produce((state: ContextProps) => {
            state.managers = data;
            state.isLoadingManagers = false;
          })
        );
      },
      refetchManagers: async () => {
        const state = get();
        set(
          produce((state: ContextProps) => {
            state.isRefetchManagers = true;
          })
        );
        if (!state.company) {
          set(
            produce((state: ContextProps) => {
              state.isRefetchManagers = false;
            })
          );
          return;
        }
        const res = await getManagers(
          state.company?.company_id.toString() ?? ""
        );
        const data = (res?.data ??
          Array<UserRoleModel>()) as Array<UserRoleModel>;
        set(
          produce((state: ContextProps) => {
            state.managers = data;
            state.isRefetchManagers = false;
          })
        );
      },
      updateGeolocation: async (data: any) => {
        const state = get();
        const res = await updateGeolocation(data);
        if (res.success) {
          await state.refetchRole();
        }
        return res;
      },
      newCategory: async (data: any) => {
        const state = get();
        const res = await newCategory(data);
        if (res.success) {
          await state.refetchCategories();
        }
        return res;
      },
      reset: () => {
        set(
          produce((state) => {
            state.role = null;
            state.company = null;
            state.isLoadingRole = true;
            state.isRefetchRole = false;
            state.categories = Array<CategoryModel>();
            state.isLoadingCategories = true;
            state.isRefetchCategories = false;
            state.managers = Array<UserRoleModel>();
            state.isLoadingManagers = false;
            state.isRefetchManagers = false;
          })
        );
      },
    }),
    {
      name: LOCAL_DATASTORE_NAME,
      storage: createJSONStorage(() => ZustandLocalStorageCrypted("dat_cpn")),
    }
  )
);
