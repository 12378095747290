import React from "react";
import LoadingPage from "../../pages/loading";
import { useRolesStore } from "../../configs/stores/roles";

import { UserModel, UserRoleModel } from "../../models/models";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "@tanstack/react-query";
import { useOneEffect } from "../../hooks/useOnEffect";

type AuthBasicsContextProps = {
  managerRoles: Array<UserRoleModel>;
  manager: UserModel;
  isLoading: Boolean;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<
    QueryObserverResult<
      {
        [x: string]: any;
        success: Boolean;
        message: String;
        data: any;
      },
      unknown
    >
  >;
};

export const AuthBasicsContext = React.createContext<AuthBasicsContextProps>(
  {} as AuthBasicsContextProps
);

const AuthBasicsProvider = ({ children = <div /> }) => {
  const isLoading = useRolesStore((e) => e.isLoadingRoles);
  const getRoles = useRolesStore((e) => e.getRoles);
  // const refetchRoles = useRolesStore((e) => e.refetchRoles);
  useOneEffect(() => {
    if (isLoading) {
      getRoles();
    } else {
      // refetchRoles();
    }
  }, []);
  return (
    <React.Fragment>
      {!isLoading && <div className="flex flex-col w-full">{children}</div>}
      {isLoading && (
        <div className="flex flex-1">
          <LoadingPage message={"Chargement des données en cours..."} />
        </div>
      )}
    </React.Fragment>
  );
};

export default AuthBasicsProvider;
