import React from "react";
import PageDescription from "../../../../../../components/descriptionCard/pageDescription";
import NoData from "../../../../../../assets/noData.svg";
import SearchInTable from "../../../../../../components/searchInput/searchInTable";
import { useCategoryStore } from "../../../../../../configs/stores/category";
import ConvenienceItem from "./item";
import { useOneEffect } from "../../../../../../hooks/useOnEffect";
import RefreshButton from "../../../../../../components/buttons/refeshButton";

const CategoryConveniences = () => {
  const [searchInput, setsearchInput] = React.useState("");
  const conveniences = useCategoryStore((e) => e.conveniencies);
  const refresh = useCategoryStore((e) => e.refetchCategoryOnly);
  const isRefreshing = useCategoryStore((e) => e.isRefetchConveniencies);
  const category = useCategoryStore((e) => e.category);
  const isLoading = useCategoryStore((e) => e.isLoadingConveniencies);
  const getConveniences = useCategoryStore((e) => e.getConveniencies);
  const refetchConveniences = useCategoryStore((e) => e.refetchConveniencies);
  useOneEffect(() => {
    if (isLoading) {
      getConveniences();
    } else {
      refetchConveniences();
    }
  }, []);
  return (
    <div className="flex h-auto flex-col space-y-6">
      <span className="flex text-[30px] phone:text-[25px] font-medium text-darkgrey">
        COMMODITES
      </span>
      <PageDescription
        title="Description de la page"
        description="Bienvenue sur la page de gestion des commodités ! 
        Cet espace est dédié aux administrateurs pour gérer les commodités."
      />
      <div className="flex flex-row flex-wrap items-end gap-4 text-[17px] mb-4">
        <SearchInTable
          placeholder="Entrer le nom de la commodité rechercher..."
          onChange={setsearchInput}
        />
        <RefreshButton
          refresh={refresh}
          isLoading={isRefreshing}
          title="Rafraichir la liste"
        />
      </div>
      <div className="flex flex-col space-y-5 w-full max-w-[800px] phone:max-w-[100vw]">
        {conveniences
          .filter((e) =>
            searchInput.length !== 0
              ? e.convenience_name
                  .toLocaleLowerCase()
                  .includes(searchInput.toLocaleLowerCase())
              : true
          )
          .filter(
            (e) =>
              category?.category_convenience
                .map((c) => c.convenience_id.toString())
                .includes(e.convenience_id.toString()) ?? true
          )
          .map((item, index) => {
            return (
              <ConvenienceItem
                key={item.convenience_id.toString()}
                convenience={item}
              />
            );
          })}
        {conveniences
          .filter((e) =>
            searchInput.length !== 0
              ? e.convenience_name
                  .toLocaleLowerCase()
                  .includes(searchInput.toLocaleLowerCase())
              : true
          )
          .filter(
            (e) =>
              !category?.category_convenience
                .map((c) => c.convenience_id.toString())
                .includes(e.convenience_id.toString()) ?? true
          )
          .map((item, index) => {
            return (
              <ConvenienceItem
                key={item.convenience_id.toString()}
                convenience={item}
              />
            );
          })}
        {conveniences.length === 0 && (
          <div className="flex w-full py-10 pl-10 items-center flex-row ">
            <img src={NoData} className="flex w-[45%]" alt="NoData" />
          </div>
        )}
      </div>
    </div>
  );
};

export default CategoryConveniences;
