import { getUrl, postUrl } from "../../apiRequest";

export function newCompany(data: any) {
  return postUrl("/auth/new", data);
}

export function updateCompanyLogo(id: string, data: any) {
  return postUrl("/auth/update/logo?id=" + id, data);
}

export function getManagerRoles() {
  return getUrl("/role/all");
}

export function getManagerRole(id: string) {
  return getUrl("/role/one?id=" + id);
}
