import React from "react";
import Logo from "../../assets/logo.png";
import AccoutHead from "./account";
import Drawer from "./drawer";
import CompanyHeader from "./company";
import DrawerContent from "./drawerContent";

const Header = () => {
  const [open, setOpen] = React.useState(false);

  const openDrawer = () => setOpen(true);
  const closeDrawer = () => setOpen(false);
  return (
    <React.Fragment>
      <div className="flex flex-row items-center h-[70px] phone:h-[60px] bg-white shadow-sm px-5">
        <div className="hidden phone:flex">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={"2"}
            stroke="black"
            className={"hidden phone:flex w-7 h-7"}
            onClick={openDrawer}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
        </div>
        <a className="flex phone:hidden h-[60%]" href="/">
          <img className="flex h-full" src={Logo} alt="Logo" />
        </a>
        <div className="flex flex-1">
          <CompanyHeader />
        </div>
        <AccoutHead />
      </div>
      <Drawer isOpen={open} onClose={closeDrawer}>
        <DrawerContent close={closeDrawer} />
      </Drawer>
    </React.Fragment>
  );
};

export default Header;
