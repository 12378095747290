import React from "react";
import ModalCustom from "../../modal";
import TextInput from "../../../inputs/textInput";
import { Button } from "@mui/material";
import { ToastErrorNotifier, primaryColor } from "../../../../constants";
import { generateSlug } from "../../../../functions/generateSlug";
import { useCategoryStore } from "../../../../configs/stores/category";
import clsx from "clsx";

const NewPlace = ({ handleClose = () => {}, open = false }) => {
  const newPlace = useCategoryStore((e) => e.newPlace);
  const category = useCategoryStore((e) => e.category);
  const [isLoading, setisLoading] = React.useState(false);
  const [formdata, setFormdata] = React.useState({
    place_name: "",
    place_description: "",
    place_weekend: 0,
    place_week: 0,
  });
  const [image, setImage] = React.useState<{
    file?: File;
    image?: string | ArrayBuffer;
  }>();
  const [closable, setClosable] = React.useState(true);
  function pickImage() {
    document.getElementById("fileType")?.click();
  }
  function handleChange(e: any) {
    const image = e.target.files[0];
    if (!image) return;
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onloadend = () => {
      !!reader.result && setImage({ file: image, image: reader.result });
    };
    reader.onerror = () => {
      console.error("something went wrong!");
    };
  }
  function onValueChange(slug = "") {
    return (value: any) => {
      setFormdata((e) => ({ ...e, [slug]: value }));
    };
  }
  function close() {
    setImage({});
    setFormdata({
      place_name: "",
      place_description: "",
      place_weekend: 0,
      place_week: 0,
    });
    handleClose();
  }
  function checkForm() {
    let result = false;
    if (!image?.file) {
      result = true;
      ToastErrorNotifier({
        message: "Veuillez selectionner une image",
        position: "top-right",
      });
    }
    if (formdata.place_name.length === 0) {
      result = true;
      ToastErrorNotifier({
        message: "Nommez la place",
        position: "top-right",
      });
    }
    if (
      formdata.place_description.length === 0 ||
      formdata.place_description.split(" ").length < 10
    ) {
      result = true;
      ToastErrorNotifier({
        message: "Decrivez la carte, minimum 10 mots",
        position: "top-right",
      });
    }
    if (formdata.place_week === 0) {
      result = true;
      ToastErrorNotifier({
        message: "Entrez un nombre superieur ou egal a 1",
        position: "top-right",
      });
    }
    if (formdata.place_weekend === 0) {
      result = true;
      ToastErrorNotifier({
        message: "Entrez un nombre superieur ou egal a 1",
        position: "top-right",
      });
    }
    return result;
  }
  function saveForm() {
    if (checkForm()) {
      return;
    }
    setisLoading(true);
    setClosable(false);
    newPlace({
      data: {
        place_name: formdata.place_name,
        place_category: category?.category_id,
        place_slug: generateSlug(formdata.place_name),
        place_description: formdata.place_description,
        place_week: formdata.place_week,
        place_weekend: formdata.place_weekend,
      },
      image,
    }).then(async (reponse) => {
      if (reponse?.success) {
        setTimeout(() => {
          close();
        }, 300);
      }
      setClosable(true);
      setisLoading(false);
    });
  }
  return (
    <ModalCustom open={open} handleClose={closable ? close : undefined}>
      <div className="flex flex-col w-[50vw] max-w-[1200px] max-h-[90vh] phone:w-[calc(100vw-30px)] overflow-y-auto phone:space-y-3 space-y-5 px-4 pb-4 items-center">
        <span className="flex text-[20px] text-darkgrey font-medium">
          Ajouter nouvelle place
        </span>
        <div className="flex w-full flex-row phone:flex-col phone:space-x-0 phone:space-y-3 space-x-5">
          <div
            className="flex h-[300px] w-[300px] phone:h-[150px] phone:w-[150px] relative bg-whitegrey/25 hover:bg-whitegrey/10 rounded-xl border-[2px] cursor-pointer"
            onClick={pickImage}
          >
            {!!image?.image ? (
              <>
                <img
                  className="flex flex-1 object-cover rounded-xl bg-black/10 p-2"
                  alt={image.image.toString()}
                  src={image.image.toString()}
                />
                <span className="flex text-[16px] phone:text-[13px] text-white absolute top-[15px] left-[15px] bg-black/20 hover:bg-black/30 active:bg-black/25 transition-colors duration-100 px-3 py-1 rounded-md">
                  Modifier l'image
                </span>
              </>
            ) : (
              <div className="flex flex-1 flex-col gap-4 items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  className="flex aspect-[4/3] h-[30%] fill-white stroke-black/40"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                  />
                </svg>
                <span className="flex text-center phone:text-[13px] phone:mx-3">
                  Ajouter une image de la place
                </span>
              </div>
            )}
          </div>
          <div className="flex flex-col flex-1  pt-1 justify-center gap-3 max-h-[70vh] overflow-y-auto">
            <TextInput
              label="Nom de la place"
              placeholder="Entrer le nom de la place"
              value={formdata.place_name}
              onTextChange={onValueChange("place_name")}
            />
            <TextInput
              label="Description de la place"
              placeholder="Decriver de la place"
              multiline
              minlines={1}
              maxlines={1}
              value={formdata.place_description}
              onTextChange={onValueChange("place_description")}
            />
            <div className="flex flex-col space-y-4 phone:space-y-2 pb-2 px-1">
              <div className="flex flex-col">
                <span className="flex">Places disponibles :</span>
              </div>
              <NumberInput
                title="En semaine"
                onChangeValue={onValueChange("place_week")}
              />
              <NumberInput
                title="Week-end"
                onChangeValue={onValueChange("place_weekend")}
              />
            </div>
          </div>
        </div>
        <Button
          style={{
            backgroundColor: primaryColor,
            paddingBlock: 10,
            color: "white",
          }}
          sx={{
            px: 8,
          }}
          onClick={saveForm}
        >
          {!isLoading ? (
            <span className="flex font-medium">Enregistrer</span>
          ) : (
            <>
              <svg
                className="animate-spin h-6 w-6 text-black/50"
                xmlns="http://www.w3.org/2000/svg"
                fill={"transparent"}
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke={"white"}
                  strokeWidth="2"
                ></circle>
                <path
                  className="opacity-75"
                  fill={"white"}
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <span className="flex ml-3 normal-case">
                Patientez un instant
              </span>
            </>
          )}
        </Button>
        <input
          id="fileType"
          type="file"
          accept="image/*" //accept="image/*"
          className="hidden"
          onChange={handleChange}
        />
      </div>
    </ModalCustom>
  );
};

type NumberInputProps = {
  title: string;
  onChangeValue: (value: string) => void;
};

function NumberInput(props: NumberInputProps) {
  const { title = "", onChangeValue = (e) => {} } = props;
  const [isFocused, setIsFocused] = React.useState(false);
  function onFocus() {
    setIsFocused(true);
  }
  function onBlur() {
    setIsFocused(false);
  }
  return (
    <div
      className={clsx(
        "flex border rounded-md",
        isFocused ? "ring-primary ring-2 border-transparent" : ""
      )}
    >
      <div
        style={{
          borderColor: isFocused ? primaryColor : undefined,
          color: isFocused ? primaryColor : "black",
        }}
        className="flex flex-1 border-r-2 items-center justify-center"
      >
        <span className="flex">{title}</span>
      </div>
      <input
        type="number"
        min={0}
        max={10000}
        onFocus={onFocus}
        onBlur={onBlur}
        className="flex p-4 phone:py-3 w-[60%] rounded-xl border-none outline-none focus:border-none focus:outline-none"
        placeholder={title}
        defaultValue={"0"}
        onChange={(e) => {
          onChangeValue(e.target.value);
        }}
      />
    </div>
  );
}

export default NewPlace;
