import { deleteUrl, getUrl, postUrl } from "../../apiRequest";

export function getCategoryById(categoryId = "") {
  return getUrl("/category/one?id=" + categoryId);
}

export function oneCategoryByCompanyIdAndCategoryType(
  companyId = "",
  categoryType = ""
) {
  return getUrl(
    "/category/one-by-companyandcategorytype?id=" +
      companyId +
      "&categorytype=" +
      categoryType
  );
}

export function deleteCategory(id: any) {
  return deleteUrl("/category/delete?id=" + id);
}

export function updateCategoryDescription(data: any = {}) {
  return postUrl("/category/update/description", data);
}

export function updateCategory(data: any = {}) {
  return postUrl("/category/update", data);
}

export function getCategoryReservations(id: any) {
  return getUrl("/category/reservation/all?id=" + id);
}

/// IMAGE
export function getCategoryImages(id: any) {
  return getUrl("/category/image/all?id=" + id);
}

export function newCategoryImage(data: any) {
  return postUrl("/category/image/new", data);
}

export function updateCategoryImage(id: string, data: any) {
  return postUrl("/category/image/update?id=" + id, data);
}

export function deleteCategoryImage(id: any) {
  return deleteUrl("/category/image/one?id=" + id);
}

/// SERVICE
export function getCategoryServices(id: any) {
  return getUrl("/category/service/all?id=" + id);
}

export function newCategoryService(data: any) {
  return postUrl("/category/service/new", data);
}

export function deleteCategoryService(id: any) {
  return deleteUrl("/category/service/one?id=" + id);
}

export function updateCategoryServiceImage(id: string, data: any) {
  return postUrl("/category/service/update/image?id=" + id, data);
}

export function updateCategoryService(id: string, data: any) {
  return postUrl("/category/service/update/one?id=" + id, data);
}

/// MENUCARD
export function getCategoryMenuCards(id: any) {
  return getUrl("/category/menucard/all?id=" + id);
}

export function newCategoryMenuCard(data: any) {
  return postUrl("/category/menucard/new", data);
}

export function deleteCategoryMenuCard(id: any) {
  return deleteUrl("/category/menucard/one?id=" + id);
}

export function updateCategoryMenuCardImage(id: string, data: any) {
  return postUrl("/category/menucard/update/image?id=" + id, data);
}

export function updateCategoryMenuCard(id: string, data: any) {
  return postUrl("/category/menucard/update/one?id=" + id, data);
}

/// PLACE
export function newCategoryPlace(data: any) {
  return postUrl("/category/place/new", data);
}
export function getCategoryPlaces(id: any) {
  return getUrl("/category/place/all?id=" + id);
}
export function updateCategoryPlace(id: string, data: any) {
  return postUrl("/category/place/update/one?id=" + id, data);
}
export function updateCategoryPlaceImage(id: string, data: any) {
  return postUrl("/category/place/update/image?id=" + id, data);
}
export function deleteCategoryPlace(id: any) {
  return deleteUrl("/category/place/one?id=" + id);
}

/// MENU
export function newCategoryMenu(data: any) {
  return postUrl("/category/menu/new", data);
}
export function getCategoryMenus(id: any) {
  return getUrl("/category/menu/all?id=" + id);
}
export function getCategoryMenusByCategoryId(id: any) {
  return getUrl("/category/menu/all-by-category?id=" + id);
}
export function updateCategoryMenu(id: string, data: any) {
  return postUrl("/category/menu/update/one?id=" + id, data);
}
export function updateCategoryMenuImage(id: string, data: any) {
  return postUrl("/category/menu/update/image?id=" + id, data);
}
export function deleteCategoryMenu(id: any) {
  return deleteUrl("/category/menu/one?id=" + id);
}

/// CONVENIENCE
export function getCategoryConveniences(id: any) {
  return getUrl("/category/convenience/all?id=" + id);
}
export function newCategoryConvenience(id: string, categoryId: string) {
  return getUrl("/category/convenience/new?id=" + id + "&category=" + categoryId);
}
export function deleteCategoryConvenience(id: string, categoryId: string) {
  return getUrl("/category/convenience/delete?id=" + id + "&category=" + categoryId);
}
