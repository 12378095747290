import React from "react";
import { Navigate } from "react-router-dom";
import { useAuthStore } from "../../configs/stores/auth";

function Home() {
  const isAuthenticated = useAuthStore((e) => e.isAuthenticated);
  if (isAuthenticated) {
    return <Navigate to={"companies"} />;
  } else if (!isAuthenticated) {
    return <Navigate to={"login"} />;
  }
  return <div />;
}

export default Home;
