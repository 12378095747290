import React from "react";
import { BillingModel } from "../../../../../../models/models";
import ModalCustom from "../../../../../../components/modals/modal";
import CheckedModal from "../../../../../../components/modals/models/checked";
import LoadingModal from "../../../../../../components/modals/models/loading";
import { updateCompanyCategory } from "../../../../../../contexts/dashboard/functions";
import { useCategoryStore } from "../../../../../../configs/stores/category";

type Props = {
  billing: BillingModel;
};

const GridItemBilling = ({ billing }: Props) => {
  const category = useCategoryStore((e) => e.category);
  const [isLoading, setisLoading] = React.useState(false);
  const [openDesc, setOpenDesc] = React.useState(false);
  function closeDesc() {
    setOpenDesc(false);
  }
  function updateBilling() {
    setisLoading(true);
    updateCompanyCategory({
      category_id: category?.category_id,
      category_billing: billing.billing_id,
    }).then(() => {
      setisLoading(false);
    });
  }
  return (
    <React.Fragment>
      <div className="flex select-none bg-white p-5 flex-col gap-5 rounded-lg hover:shadow-md transition-shadow duration-500">
        <div className="flex flex-col gap-3">
          <div className="flex flex-col">
            <span className="flex text-[14px] font-normal">
              Nom de la facturation
            </span>
            <span className="flex text-[18px] font-semibold">
              {billing.billing_name}
            </span>
          </div>
          <div className="flex flex-col">
            <span className="flex text-[14px] font-normal">
              Description de la facturation
            </span>
            <span
              onClick={() => setOpenDesc(true)}
              className="flex mt-1 text-[13px] font-medium underline cursor-pointer w-fit transition-colors duration-100 text-primary hover:text-hover active:text-active"
            >
              Afficher la description
            </span>
          </div>
          <div className="flex flex-col">
            <span className="flex text-[14px] font-normal">
              Mode de remboursement
            </span>
            {["fixed"].includes(billing.billing_cashout_mode.toString()) && (
              <span className="flex text-[16px] font-semibold">
                Fixe : {billing.billing_cashout_rate}{" "}
                {billing.billing_currency.currency_isocode}
              </span>
            )}
            {["percent"].includes(billing.billing_cashout_mode.toString()) && (
              <span className="flex text-[16px] font-semibold">
                Ration sur la reservation: {billing.billing_cashout_rate}
                {" %"}
              </span>
            )}
          </div>
          <div className="flex flex-col">
            <span className="flex text-[14px] font-normal">
              Formule du cout de la reservation
            </span>
            <span className="flex text-[16px] font-medium">
              Ration par service ajoute : {billing.billing_pricing_percent}
              {" %"}
            </span>
            <span className="flex text-[16px] font-medium">
              Prix de base : {billing.billing_pricing_price}{" "}
              {billing.billing_currency.currency_isocode}
            </span>
          </div>
          <div className="flex flex-col">
            <span className="flex text-[14px] font-normal">
              Periode de validite
            </span>
            {["infinity"].includes(billing.billing_validity.toString()) && (
              <span className="flex text-[16px] font-medium">Infinie</span>
            )}
          </div>
          <div className="flex flex-col">
            <span className="flex text-[14px] font-normal">
              Type de categorie concerne
            </span>
            <span className="flex text-[16px] font-medium">
              {billing.billing_forall
                ? "Tous les types de categorie"
                : billing.billing_related.map(
                    (e) => e.categorytype_name + ", "
                  )}
            </span>
          </div>
        </div>
        {category?.category_billing.billing_id === billing.billing_id ? (
          <div className="group flex transit duration-300 font-medium justify-center items-center py-3 rounded-md bg-slate-300/30 border">
            <span className="flex text-black">Votre formule</span>
          </div>
        ) : (
          <button
            onClick={updateBilling}
            className="group flex transit duration-300 font-medium justify-center items-center py-3 rounded-md bg-primary border hover:bg-hover active:bg-active"
          >
            <span className="flex text-white">Sélectionner</span>
          </button>
        )}
      </div>
      <ModalCustom open={openDesc} handleClose={closeDesc}>
        <CheckedModal
          confirm={closeDesc}
          modalTitle="Description de la formule"
          message={billing.billing_description.toString()}
          close={closeDesc}
        />
      </ModalCustom>
      <LoadingModal
        open={isLoading}
        handleClose={() => setisLoading(false)}
        message="Patientez un instant..."
      />
    </React.Fragment>
  );
};

export default GridItemBilling;
