import React from "react";
import NewCategoryModel from "../../../../components/modals/models/newcategory";
import { useBasicsStore } from "../../../../configs/stores/basics";
import { useCompanyStore } from "../../../../configs/stores/company";

export default function CategoriesBloc() {
  const { categoryTypes } = useBasicsStore();
  const categories = useCompanyStore((e) => e.categories);
  const [open, setOpen] = React.useState(false);
  function HandleClose() {
    setOpen(false);
  }
  return (
    <div className="flex flex-row flex-wrap gap-5">
      {categories.map((item, index) => {
        return (
          <div
            key={index}
            className="flex flex-row items-center bg-white p-3 px-5 gap-3 rounded-md phone:w-full w-[250px]"
          >
            <div className="flex flex-row gap-4 items-center leading-none">
              <div className="flex flex-col items-center gap-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  className="w-4 h-4 fill-primary"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="flex text-[12px] font-medium text-primary">
                  {/* {item.evaluation.evaluation} */}0/5
                </span>
              </div>
              <div className="flex flex-col space-y-1 leading-none">
                <span className="flex text-[18px] font-medium text-darkgrey text-center">
                  {item.category_type?.categorytype_name}
                </span>
                <span className="flex text-[12px] phone:text-[14px] cursor-pointer w-fit text-darkgrey/70">
                  {/* {item.evaluation.length} */}0 evaluations
                </span>
              </div>
            </div>
          </div>
        );
      })}
      {categoryTypes.filter(
        (e) =>
          !categories
            .map((item) => item.category_type.categorytype_id)
            .includes(e.categorytype_id)
      ).length !== 0 && (
        <button
          onClick={() => setOpen(true)}
          className="flex cursor-pointer flex-row gap-3 w-[250px] phone:max-w-[250px] phone:w-auto p-3 px-5 rounded-md items-center text-white fill-white bg-primary active:bg-active hover:bg-hover"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="w-6 h-6"
          >
            <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
          </svg>
          <span className="flex font-medium text-[17px]">
            Ajouter une categorie
          </span>
        </button>
      )}
      <NewCategoryModel handleClose={HandleClose} open={open} />
    </div>
  );
}
