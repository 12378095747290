import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { produce } from "immer";
import { ZustandLocalStorageCrypted } from "./utils";
const LOCAL_DATASTORE_NAME = "coinchicpro-category-reservationTab";

type ContextProps = {
  searchInput: string;
  updateSearchInput: (value: string) => void;
  tabState: string;
  updateTabState: (value: string) => void;
};

export const useCategoryReservationStore = create<
  ContextProps,
  [["zustand/persist", ContextProps]]
>(
  persist(
    (set, get) => ({
      searchInput: "",
      updateSearchInput: (value) => {
        set(
          produce((state: ContextProps) => {
            state.searchInput = value;
          })
        );
      },
      tabState: "all",
      updateTabState: (value) => {
        set(
          produce((state: ContextProps) => {
            state.tabState = value;
          })
        );
      },
    }),
    {
      name: LOCAL_DATASTORE_NAME,
      storage: createJSONStorage(() => ZustandLocalStorageCrypted("dat_cgr")),
    }
  )
);
