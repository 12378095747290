import React from "react";
import ModalCustom from "../../modal";
import SimpleSelect from "../../../selects";
import { useBasicsStore } from "../../../../configs/stores/basics";
import { useCompanyStore } from "../../../../configs/stores/company";
import { ToastErrorNotifier } from "../../../../constants";

const NewCategoryModel = ({ handleClose = () => {}, open = false }) => {
  const [closable, setClosable] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [formdata, setFormData] = React.useState({
    type: "",
  });
  const categoryTypes = useBasicsStore((e) => e.categoryTypes);
  const company = useCompanyStore((e) => e.company);
  const categories = useCompanyStore((e) => e.categories);
  const newCategory = useCompanyStore((e) => e.newCategory);
  function close() {
    handleClose();
  }
  function checkForm() {
    let error = false;
    if (formdata.type.length === 0) {
      error = true;
      ToastErrorNotifier({
        message: "Veuillez sélectionner la catégorie à ajouter !",
      });
    }
    return error;
  }
  function submitForm() {
    if (checkForm()) {
      return;
    }
    setClosable(false);
    setIsLoading(true);
    newCategory({
      category_company: company?.company_id,
      category_type: formdata.type,
    }).then((reponse) => {
      if (reponse.success) {
        setFormData({
          type: "",
        });
        setIsLoading(false);
        setClosable(true);
        close();
      } else {
        ToastErrorNotifier({
          message: reponse.message.toString(),
        });
        setIsLoading(false);
        setClosable(true);
      }
    });
  }
  React.useEffect(() => {
    if (!open) {
      setFormData({
        type: "",
      });
      setIsLoading(false);
    }
  }, [open]);

  return (
    <ModalCustom open={open} handleClose={closable ? close : undefined}>
      <div className="flex relative flex-col px-[20px] pb-5 max-h-[90vh] w-[80vw] max-w-[350px] space-y-3">
        <span className="flex text-[22px] text-darkgrey font-medium">
          Ajouter nouvelle categorie
        </span>
        <div className="flex mt-[15px] mb-[40px] w-full flex-col space-y-5">
          <SimpleSelect
            label={"Nouvelle categorie"}
            options={categoryTypes
              .filter(
                (e) =>
                  !categories
                    .map((item) => item.category_type.categorytype_id)
                    .includes(e.categorytype_id)
              )
              .map((e) => ({
                label: e.categorytype_name,
                value: e.categorytype_id,
              }))}
            onChange={(value) => {
              if (value) {
                setFormData((e) => ({ ...e, type: value.value }));
              }
            }}
          />
          <button
            onClick={isLoading ? () => {} : submitForm}
            className="flex py-[10px] pb-[12px] items-center justify-center text-[18px] transition-colors duration-100 font-medium bg-primary text-white hover:bg-hover active:bg-active rounded-md"
          >
            {!isLoading ? (
              <span className="flex">Enregistrer</span>
            ) : (
              <svg
                className="animate-spin h-[26px] w-[26px] text-black/50"
                xmlns="http://www.w3.org/2000/svg"
                fill={"transparent"}
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke={"white"}
                  strokeWidth="2"
                ></circle>
                <path
                  className="opacity-75"
                  fill={"white"}
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            )}
          </button>
        </div>
      </div>
    </ModalCustom>
  );
};

export default NewCategoryModel;
