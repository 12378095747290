import React from "react";
import ModalCustom from "../modal";
import { ImageModel } from "../../../models/models";

const ViewImageModal = ({
  handleClose = () => {},
  open = false,
  image = Array<ImageModel>()[0],
}) => {
  return (
    <ModalCustom open={open} handleClose={handleClose}>
      <div className="flex flex-col h-[70vh] aspect-[4/3] max-w-[1500px]">
        <img
          src={image.image_link.toString()}
          alt={image.image_link.toString()}
          className="flex flex-1 object-contain"
        />
      </div>
    </ModalCustom>
  );
};

export default ViewImageModal;
