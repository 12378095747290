import React from "react";
import CategoryHeader from "./header";
import SoldeBloc, { SoldeBlocMobile } from "./solde";
import ReservationChartBloc from "./reservationChartBloc";
import { useCategoryStore } from "../../../../../../configs/stores/category";
import ReservationState from "./reservationState";

const CategoryWelcome = () => {
  const category = useCategoryStore((e) => e.category);
  // const refetchAll = useCategoryStore((e) => e.refetchAll);
  React.useEffect(() => {
    // refetchAll();
    // let timer = setInterval(() => {
    //   refetchAll();
    // }, 5 * 60_000);
    // return () => {
    //   clearInterval(timer);
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="flex h-auto flex-col space-y-5">
      <span className="flex text-[30px] phone:text-[25px] font-medium text-darkgrey">
        Accueil
      </span>
      <CategoryHeader />
      <div className="flex flex-row justify-between items-center bg-white p-4 px-7 rounded-md">
        <span className="flex text-[20px] font-medium">
          {category?.category_type.categorytype_name}
        </span>
        <div className="flex flex-row gap-3">
          <div className="flex flex-row gap-2"></div>
          <div className="flex flex-row gap-2"></div>
        </div>
      </div>
      {!!category && category.category_reservation_active === false && (
        <ReservationState />
      )}
      <span className="flex mt-1 text-[18px] font-medium text-darkgrey">
        Recapitulatif des reservations
      </span>
      <div className="flex flex-row flex-wrap gap-6">
        <ReservationChartBloc />
      </div>
      <div className="flex phone:hidden flex-row space-x-6">
        <SoldeBloc text="Total solde net" value="0 XOF" />
        <SoldeBloc text="Total solde distribué" value="0 XOF" />
      </div>
      <div className="hidden phone:flex flex-col space-y-5">
        <SoldeBlocMobile text="Total solde net" value="0 XOF" />
        <SoldeBlocMobile text="Total solde distribué" value="0 XOF" />
      </div>
    </div>
  );
};

export default CategoryWelcome;
