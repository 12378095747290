import React from "react";
import CategoryDatasLoadingModel from "../../components/modals/models/categoryDatasLoading";
import { useParams } from "react-router-dom";
import { useCategoryStore } from "../../configs/stores/category";
import { useOneEffect } from "../../hooks/useOnEffect";
import { useCompanyStore } from "../../configs/stores/company";

const CategoryProvider = ({ children = <div /> }) => {
  const { categoryTypeSlug } = useParams();
  const company = useCompanyStore((e) => e.company);
  const getCategory = useCategoryStore((e) => e.getCategory);
  const isLoadingCategory = useCategoryStore((e) => e.isLoadingCategory);
  useOneEffect(() => {
    if (isLoadingCategory) {
      categoryTypeSlug &&
        company &&
        getCategory(company.company_id.toString(), categoryTypeSlug);
    } else {
      // refetchRole();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <React.Fragment>
      {!isLoadingCategory ? (
        children
      ) : (
        <div className="flex flex-1 justify-center items-center w-full">
          <span className="flex">
            ... Chargement des donnees de la categorie !
          </span>
        </div>
      )}
      <CategoryDatasLoadingModel open={isLoadingCategory} />
    </React.Fragment>
  );
};

export default CategoryProvider;
