import React from "react";
import SimpleSelect from "../../../../components/selects";
import TextInput from "../../../../components/inputs/textInput";
import ReactMapGL, { Marker, NavigationControl } from "react-map-gl";
import { Button } from "@mui/material";
import {
  ToastErrorNotifier,
  ToastSuccessNotifier,
  primaryColor,
} from "../../../../constants";
import { useBasicsStore } from "../../../../configs/stores/basics";
import { useCompanyStore } from "../../../../configs/stores/company";

const GeolocalisationForm = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { countries } = useBasicsStore();
  const company = useCompanyStore((e) => e.company);
  const updateGeolocation = useCompanyStore((e) => e.updateGeolocation);
  const [formData, setFormData] = React.useState({
    country: company?.company_country?.locality_id ?? "",
    precision: company?.company_locality_precision ?? "",
    geolocalisation: {
      lon: parseFloat(company?.company_geolocation.lng.toString() ?? "0"),
      lat: parseFloat(company?.company_geolocation.lat.toString() ?? "0"),
      zoom: parseFloat(company?.company_geolocation.zoom.toString() ?? "0"),
    },
  });
  function onValueChange(slug: string) {
    return (value: any) => {
      setFormData((frm) => ({ ...frm, [slug]: value ?? "" }));
    };
  }
  function updateData() {
    setIsLoading(true);
    updateGeolocation({
      company_id: company?.company_id,
      company_country: formData.country,
      company_locality_precision: formData.precision,
      company_geolocation: {
        lng: formData.geolocalisation.lon,
        lat: formData.geolocalisation.lat,
        zoom: formData.geolocalisation.zoom,
      },
    }).then(async (e) => {
      if (e.success) {
        ToastSuccessNotifier({ message: "Mise à jour reussie" });
      } else {
        ToastErrorNotifier({ message: e.message.toString() });
      }
      setIsLoading(false);
    });
  }
  return (
    <div className="flex flex-col bg-white rounded-md w-[500px] phone:w-[calc(100vw-30px)] p-8 px-6">
      <div className="flex flex-col w-full space-y-6">
        <SimpleSelect
          label={"Pays de localisation"}
          placeholder={"Sélectionner le pays"}
          defaultOptions={
            company?.company_country
              ? {
                  label: company.company_country?.locality_name ?? null,
                  value: company.company_country?.locality_id,
                }
              : undefined
          }
          options={countries.map((e) => ({
            label: e.locality_name,
            value: e.locality_id,
          }))}
          onChange={(value) => {
            onValueChange("country")(value?.value ?? "");
          }}
        />
        <div className="flex flex-col space-y-1">
          <TextInput
            label="Préciser la loccallisation de l'établissement"
            placeholder="Ville, Commune, Quartier, Rue ou Vosinage"
            value={formData.precision.toString()}
            onTextChange={onValueChange("precision")}
          />
          <span className="flex text-[12px]">
            Exemple: Abidjan, Yopougon, Selmer, à 200 metres de ...
          </span>
        </div>
        <div
          style={{
            height: 300,
          }}
          className="flex w-full h-auto relative"
        >
          <ReactMapGL
            initialViewState={{
              longitude: formData.geolocalisation.lon,
              latitude: formData.geolocalisation.lat,
              zoom: formData.geolocalisation.zoom,
            }}
            onMove={(e) =>
              onValueChange("geolocalisation")({
                lat: e.target.getCenter().lat,
                lon: e.target.getCenter().lng,
                zoom: e.target.getZoom(),
              })
            }
            onZoom={(e) =>
              onValueChange("geolocalisation")({
                lat: e.target.getCenter().lat,
                lon: e.target.getCenter().lng,
                zoom: e.target.getZoom(),
              })
            }
            onClick={(e) => {
              e.target.setCenter(e.lngLat);
              onValueChange("geolocalisation")({
                lat: e.lngLat.lat,
                lon: e.lngLat.lng,
                zoom: e.target.getZoom(),
              });
            }}
            style={{ width: "100%", height: "100%", borderRadius: 8 }}
            mapboxAccessToken={process.env.REACT_APP_MapboxAccessToken}
            mapStyle="mapbox://styles/mapbox/streets-v9"
            attributionControl={false}
          >
            <Marker
              color={primaryColor}
              longitude={formData.geolocalisation.lon}
              latitude={formData.geolocalisation.lat}
              anchor="bottom"
            />
            <NavigationControl />
          </ReactMapGL>
          <div className="flex absolute z-10 px-3 mt-2 ml-2 rounded-md py-1 bg-white">
            <span className="flex text-[15px] font-medium">
              Géolocaliser votre établissement
            </span>
          </div>
        </div>
        <Button
          style={{
            backgroundColor: primaryColor,
            paddingBlock: 10,
            color: "white",
          }}
          onClick={updateData}
        >
          {!isLoading ? (
            <span className="flex text-[15px] normal-case font-semibold">
              Mettre à jour
            </span>
          ) : (
            <>
              <svg
                className="animate-spin h-6 w-6 text-black/50"
                xmlns="http://www.w3.org/2000/svg"
                fill={"transparent"}
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke={"white"}
                  strokeWidth="2"
                ></circle>
                <path
                  className="opacity-75"
                  fill={"white"}
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <span className="flex text-[15px] ml-3 normal-case font-semibold">
                Patientez un instant...
              </span>
            </>
          )}
        </Button>
      </div>
    </div>
  );
};

export default GeolocalisationForm;
