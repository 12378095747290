import React from "react";
import PageDescription from "../../../../../../components/descriptionCard/pageDescription";
import RefreshButton from "../../../../../../components/buttons/refeshButton";
import { useOneEffect } from "../../../../../../hooks/useOnEffect";
import { useCategoryStore } from "../../../../../../configs/stores/category";
import SearchInTable from "../../../../../../components/searchInput/searchInTable";
import ReservationTabs from "./tabs";
import { useCategoryReservationStore } from "../../../../../../configs/stores/reservations";
import ReservationState from "./reservationState";

const CategoryReservations = () => {
  const updateSearchInput = useCategoryReservationStore(
    (e) => e.updateSearchInput
  );
  const refetchReservations = useCategoryStore((e) => e.refetchReservations);
  const getReservations = useCategoryStore((e) => e.getReservations);
  const isLoadingReservations = useCategoryStore(
    (e) => e.isLoadingReservations
  );
  const isRefetchReservations = useCategoryStore(
    (e) => e.isRefetchReservations
  );
  useOneEffect(() => {
    if (!isLoadingReservations) {
      getReservations();
    } else {
      refetchReservations();
      setInterval(() => refetchReservations(), 3 * 60 * 1000);
    }
  }, []);
  return (
    <div className="flex h-auto flex-col space-y-6">
      <span className="flex text-[30px] phone:text-[25px] font-medium text-darkgrey">
        RESERVATIONS
      </span>
      <PageDescription
        title="Description de la page"
        description="Bienvenue sur la page de gestion des réservations ! 
        Cet espace est dédié aux administrateurs pour gérer les différentes réservations."
      />
      <ReservationState />
      <div className="flex flex-row flex-wrap items-end gap-4 text-[17px]">
        <SearchInTable
          placeholder="Entrer le nom du client rechercher..."
          onChange={updateSearchInput}
        />
        <RefreshButton
          refresh={refetchReservations}
          isLoading={isRefetchReservations}
        />
      </div>
      <ReservationTabs />
    </div>
  );
};

export default CategoryReservations;
