import React from "react";
import NewCategory from "./newCategory";
import AllCategories from "./all";
import CategoriesLoading from "./loading";
import { useCompanyStore } from "../../../configs/stores/company";

const CompanyCategories = () => {
  const categories = useCompanyStore((e)=>e.categories)
  const isLoadingCategories = useCompanyStore((e)=>e.isLoadingCategories)
  return (
    <div className="flex flex-1 flex-col space-y-2">
      {isLoadingCategories && <CategoriesLoading />}
      {categories.length !== 0 && !isLoadingCategories && <AllCategories />}
      {categories.length === 0 && !isLoadingCategories && <NewCategory />}
    </div>
  );
};

export default CompanyCategories;
