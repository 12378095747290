import React from "react";
import LoadingPage from "../../pages/loading";
import { Navigate, useParams } from "react-router-dom";
import { useCompanyStore } from "../../configs/stores/company";
import { useOneEffect } from "../../hooks/useOnEffect";

const CompanyProvider = ({ children = <div /> }) => {
  const { companySlug } = useParams();
  const isLoading = useCompanyStore((e) => e.isLoadingRole);
  const getRole = useCompanyStore((e) => e.getRole);
  // const refetchRole = useCompanyStore((e) => e.refetchRole);
  useOneEffect(() => {
    if (isLoading) {
      companySlug && getRole(companySlug);
    } else {
      // refetchRole();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <React.Fragment>
      {!isLoading && <div className="flex flex-1">{children}</div>}
      {isLoading && (
        <div className="flex flex-1">
          <LoadingPage
            message={"Chargement des données de l'etablissement en cours..."}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export const CompanyHomePage = () => {
  const company = useCompanyStore((e) => e.company);
  if (company) {
    return <Navigate to={`/company/${company.company_slug}`} />;
  } else {
    return <Navigate to={"/companies"} />;
  }
};

export default CompanyProvider;
