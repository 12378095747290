import React from "react";
import TextInput from "../../../../../../components/inputs/textInput";
import { Button } from "@mui/material";
import {
  ToastErrorNotifier,
  ToastSuccessNotifier,
  primaryColor,
} from "../../../../../../constants";
import { useCompanyStore } from "../../../../../../configs/stores/company";
import { useCategoryStore } from "../../../../../../configs/stores/category";

const UpdateDescription = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const refetchCategories = useCompanyStore((e) => e.refetchCategories);
  const category = useCategoryStore((e) => e.category);
  const updateCategoryDescription = useCategoryStore(
    (e) => e.updateCategoryDescription
  );
  const [formData, setFormData] = React.useState({
    category_description: category?.category_description.toString() ?? "",
  });
  function onValueChange(slug: string) {
    return (value: any) => {
      setFormData((frm) => ({ ...frm, [slug]: value ?? "" }));
    };
  }
  function updateData() {
    setIsLoading(true);
    updateCategoryDescription({
      category_id: category?.category_id,
      category_description: formData.category_description,
    }).then(async (e) => {
      if (e.success) {
        await refetchCategories();
        ToastSuccessNotifier({ message: "Mise à jour reussie" });
      } else {
        ToastErrorNotifier({ message: e.message.toString() });
      }
      setIsLoading(false);
    });
  }
  return (
    <React.Fragment>
      <div className="flex flex-col bg-white rounded-md w-[500px] phone:w-[calc(100vw-30px)] p-8 px-6 space-y-5">
        <div className="flex flex-col space-y-1">
          <TextInput
            label="Description de la categorie"
            placeholder="Descriver la categorie"
            maxlines={10}
            minlines={5}
            multiline
            value={formData.category_description.toString()}
            onTextChange={onValueChange("category_description")}
          />
        </div>
        <Button
          style={{
            backgroundColor: primaryColor,
            paddingBlock: 10,
            color: "white",
          }}
          onClick={updateData}
        >
          {!isLoading ? (
            <span className="flex text-[15px] normal-case font-semibold">
              Mettre à jour
            </span>
          ) : (
            <>
              <svg
                className="animate-spin h-6 w-6 text-black/50"
                xmlns="http://www.w3.org/2000/svg"
                fill={"transparent"}
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke={"white"}
                  strokeWidth="2"
                ></circle>
                <path
                  className="opacity-75"
                  fill={"white"}
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <span className="flex text-[15px] ml-3 normal-case font-semibold">
                Patientez un instant...
              </span>
            </>
          )}
        </Button>
      </div>
    </React.Fragment>
  );
};

export default UpdateDescription;
