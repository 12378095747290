import React from "react";
import LogoPhone from "../../assets/logoicon.png";
import { useCompanyStore } from "../../configs/stores/company";
import { useCategoryStore } from "../../configs/stores/category";
import CompanyCategories from "./drawer/categories";
import CategoryNav from "./drawer/categoryNav";

type Props = {
  close: () => void;
};

function DrawerContent({ close }: Props) {
  const role = useCompanyStore((e) => e.role);
  const category = useCategoryStore((e) => e.category);
  return (
    <div className="flex w-full flex-col">
      <div className="flex flex-row h-[60px] space-x-3 items-center px-4 border-b border-slate-500">
        <a className="hidden phone:flex h-[60%]" href="/">
          <img className="flex h-full" src={LogoPhone} alt="Logo" />
        </a>
        <span className="line-clamp-1 text-[24px] text-black font-semibold">
          {role?.role_company.company_name}
        </span>
      </div>
      <div className="flex flex-col">
        {!category && <CompanyCategories />}
        {!!category && <CategoryNav />}
      </div>
    </div>
  );
}

export default DrawerContent;
