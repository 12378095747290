import React from "react";
import LoadingPage from "../../pages/loading";
import { useBasicsStore } from "../../configs/stores/basics";
import { useOneEffect } from "../../hooks/useOnEffect";

const BasicsProvider = ({ children = <div /> }) => {
  const isLoading = useBasicsStore((e) => e.isLoading);
  const getAll = useBasicsStore((e) => e.getAll);
  // const refreshAll = useBasicsStore((e) => e.refreshAll);
  useOneEffect(() => {
    if (isLoading) {
      getAll();
    } else {
      // refreshAll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <React.Fragment>
      <div className="flex w-full min-h-screen bg-whitegrey">
        {isLoading ? (
          <div className="flex flex-1 flex-col bg-whitegrey">
            <LoadingPage message={"Chargement en cours..."} />
          </div>
        ) : (
          children
        )}
      </div>
    </React.Fragment>
  );
};

export default BasicsProvider;
