import React from "react";
import { Popover } from "@mui/material";
import CompanyNavItem from "./item";
import { useCompanyStore } from "../../../configs/stores/company";
import { CompanyRoutes } from "../../../contexts/dashboard/defaults/subroutes";
import { useCategoryStore } from "../../../configs/stores/category";

const CompanyNavBar = () => {
  const company = useCompanyStore((e) => e.company);
  const selectedRoute = useCategoryStore((e) => e.selectedRoute);
  const [isOpenChildrenList, setIsOpenChildrenList] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleAnchor = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsOpenChildrenList(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const companyRouteSelected =
    CompanyRoutes.filter((c) => c.name === selectedRoute).length !== 0
      ? selectedRoute
      : null;
  React.useEffect(()=>{
    handleClose()
  },[selectedRoute])
  return (
    <React.Fragment>
      <div
        onClick={(e) => {
          handleAnchor(e);
          setIsOpenChildrenList(!isOpenChildrenList);
        }}
        className={`flex flex-row border-y py-[14px] pl-4 pr-2 items-center space-x-2 ${
          companyRouteSelected && "bg-primary"
        } `}
      >
        <div className="flex justify-center items-center">
          {!!company?.company_logo ? (
            <img
              className="flex h-11 2xl:h-[50px] aspect-square rounded-full bg-slate-600"
              src={company.company_logo.image_link.toString()}
              alt={company.company_logo.image_link.toString()}
            />
          ) : (
            <div className="flex min-h-11 h-11 min-w-11 w-11 rounded-full justify-center items-center bg-[#ccc]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill={"white"}
                className="w-6 h-6"
              >
                <path
                  fillRule="evenodd"
                  d="M3 2.25a.75.75 0 000 1.5v16.5h-.75a.75.75 0 000 1.5H15v-18a.75.75 0 000-1.5H3zM6.75 19.5v-2.25a.75.75 0 01.75-.75h3a.75.75 0 01.75.75v2.25a.75.75 0 01-.75.75h-3a.75.75 0 01-.75-.75zM6 6.75A.75.75 0 016.75 6h.75a.75.75 0 010 1.5h-.75A.75.75 0 016 6.75zM6.75 9a.75.75 0 000 1.5h.75a.75.75 0 000-1.5h-.75zM6 12.75a.75.75 0 01.75-.75h.75a.75.75 0 010 1.5h-.75a.75.75 0 01-.75-.75zM10.5 6a.75.75 0 000 1.5h.75a.75.75 0 000-1.5h-.75zm-.75 3.75A.75.75 0 0110.5 9h.75a.75.75 0 010 1.5h-.75a.75.75 0 01-.75-.75zM10.5 12a.75.75 0 000 1.5h.75a.75.75 0 000-1.5h-.75zM16.5 6.75v15h5.25a.75.75 0 000-1.5H21v-12a.75.75 0 000-1.5h-4.5zm1.5 4.5a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75h-.008a.75.75 0 01-.75-.75v-.008zm.75 2.25a.75.75 0 00-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 00.75-.75v-.008a.75.75 0 00-.75-.75h-.008zM18 17.25a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75h-.008a.75.75 0 01-.75-.75v-.008z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          )}
        </div>
        <div className="flex flex-1 flex-col">
          <span
            className={`flex text-[16px] font-semibold leading-tight line-clamp-1 text-ellipsis ${
              companyRouteSelected ? "text-white" : "text-darkgrey"
            }`}
          >
            {company?.company_name}
          </span>
          {!!companyRouteSelected ? (
            <span
              className={`flex text-[12px] leading-[1.5] underline-offset-2 underline text-white font-bold`}
            >
              {companyRouteSelected}
            </span>
          ) : (
            <span
              className={`flex text-[12px] leading-[1.5] underline-offset-2 underline text-primary`}
            >
              Afficher autres options
            </span>
          )}
        </div>
        <button
          className={`flex p-2 rounded-full transition-all group-hover:bg-slate-200/60
          ${true ? "bg-slate-200/70  group-hover:bg-slate-200/70" : ""}`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-3 h-3"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8.25 4.5l7.5 7.5-7.5 7.5"
            />
          </svg>
        </button>
      </div>
      <Popover
        id={id}
        open={isOpenChildrenList}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          marginTop: 1,
          marginLeft: 1,
        }}
        style={{
          boxShadow: "10px 5px 5px red",
        }}
        elevation={3}
      >
        <div className="flex flex-col my-1 min-w-[240px]">
          {CompanyRoutes.map((item, index) => {
            return (
              <CompanyNavItem
                key={index + (item.name?.toString() ?? "")}
                item={item}
                close={handleClose}
              />
            );
          })}
        </div>
      </Popover>
    </React.Fragment>
  );
};

export default CompanyNavBar;
