import React from "react";
import AccountLogOut from "./logout";

const AccountPopup = ({ close = () => {} }) => {
  return (
    <div className="flex flex-col w-[200px] p-[6px] gap-1 text-[15px] text-darkgrey/80 font-medium rounded-sm bg-white">
      <AccountLogOut />
    </div>
  );
};

export default AccountPopup;
