import { SubRouteModel } from "../../../contexts/dashboard/defaults/subroutes";
import { NavLink } from "react-router-dom";
import { useCategoryStore } from "../../../configs/stores/category";

type Props = {
  item: SubRouteModel;
};

const CategoryNavItem = ({ item }: Props) => {
  const category = useCategoryStore((e) => e.category);
  const selectRoute = useCategoryStore((e) => e.selectRoute);
  return (
    <NavLink
      to={category?.category_type?.categorytype_slug + ("/" + item?.path)}
      end={item?.path === ""}
      className={({ isActive }) => {
        const def =
          "group flex flex-col text-[17px] pl-5 pr-[10px] py-3 transition-all font-medium ";
        if (isActive) {
          selectRoute(item.name ?? "");
          return def + "text-white bg-primary duration-0";
        }
        return def + "hover:bg-whitegrey/70 hover:text-darkgrey duration-100";
      }}
    >
      <div className="flex">{item?.name}</div>
    </NavLink>
  );
};

export default CategoryNavItem;
