import React from "react";
import Logo from "../../../assets/logo.png";
import AccoutHead from "./account";
import ModalCustom from "../../../components/modals/modal";
import NewCompanyModal from "../../../components/modals/models/newcompany";

const HeaderCompanies = () => {
  const [isOpenNewCompany, setIsOpenNewCompany] = React.useState(false);
  function closeNewCompany() {
    setIsOpenNewCompany(false);
  }
  return (
    <React.Fragment>
      <div className="flex flex-row items-center min-h-[65px] max-h-[75px] h-[10%] bg-transparent px-5 space-x-5">
        <a className="flex h-[60%] w-fit" href="/">
          <img className="flex h-full max-w-[160px]" src={Logo} alt="Logo" />
        </a>
        <div className="flex flex-1 "/>
        <button
          className="flex px-5 flex-row mr-4 space-x-2 h-[66%] items-center bg-white/70 hover:bg-white/80 active:bg-white active:shadow-sm border border-whitegrey/70 rounded-[40px] text-primary"
          onClick={() => setIsOpenNewCompany(true)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            strokeWidth={3}
            className="w-5 h-5"
          >
            <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
          </svg>
          <span className="flex font-medium">Ajouter un établissement</span>
        </button>
        <AccoutHead />
      </div>
      <ModalCustom
        handleClose={closeNewCompany}
        open={isOpenNewCompany}
        zeroPadding
      >
        <NewCompanyModal close={closeNewCompany}/>
      </ModalCustom>
    </React.Fragment>
  );
};

export default HeaderCompanies;
