import React from "react";
import NewCategoryModel from "../../modals/models/newcategory";

const NewCategory = () => {
  const [open, setOpen] = React.useState(false);
  function HandleClose() {
    setOpen(false);
  }
  return (
    <React.Fragment>
      <div className="flex flex-col flex-1 items-center justify-center gap-5 pb-10 max-h-[700px]">
        <span className="flex text-center text-[20px] leading-snug font-medium w-[80%]">
          Ajouter une nouvelle categorie
        </span>
        <button
          onClick={() => setOpen(true)}
          className="flex px-6 py-2 pb-[10px] text-[16px] transition-colors duration-100 font-medium bg-primary text-white hover:bg-hover active:bg-active rounded-md"
        >
          <span className="flex">Ajouter</span>
        </button>
      </div>
      <NewCategoryModel handleClose={HandleClose} open={open} />
    </React.Fragment>
  );
};

export default NewCategory;
