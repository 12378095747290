import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import faker from "faker";
import { Months } from "../../../../../constants";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      padding: 30,
      display: false,
      position: "top",
      align: "start",
    },
    title: {
      display: false,
      align: "start",
      position: "bottom",
      text: "Chart.js Line Chart",
    },
  },
};

const labels = Array.from({
  length: Months[new Date().getMonth() + 1].length,
}).map((e, i) => (i + 1).toString());

export const data = {
  labels,
  datasets: [
    {
      label: "Achat",
      data: labels.map(() => faker.datatype.number({ min: 0, max: 0 })),
      borderColor: "rgb(240 132 0)",
      backgroundColor: "rgb(240 132 0)",
    },
    {
      label: "Paiement effectue",
      data: labels.map(() => faker.datatype.number({ min: 0, max: 0 })),
      borderColor: "#00FF00",
      backgroundColor: "#00FF00",
    },
  ],
};

const ChartBloc = () => {
  return (
    <div className="flex relative gap-3 flex-row  w-full flex-1 -ml-1">
      <Line options={options} data={data} />
    </div>
  );
};

export default ChartBloc;
