import React from "react";
import Logo from "../../assets/logoicon.png";
import CompanyItem from "./companyItem";
import ModalCustom from "../../components/modals/modal";
import NewCompanyModal from "../../components/modals/models/newcompany";
import { useRolesStore } from "../../configs/stores/roles";

const CompaniesContainer = () => {
  const managerRoles = useRolesStore((e) => e.roles);
  const [search, setSearch] = React.useState("");
  const [isOpenNewCompany, setIsOpenNewCompany] = React.useState(
    // managerRoles.length === 0
    false
  );
  function closeNewCompany() {
    setIsOpenNewCompany(false);
  }
  return (
    <div className="flex flex-1 justify-center items-center">
      <div className="flex flex-col space-y-5 pt-12 p-5 2xl:px-10 min-w-[450px] w-[50%] max-w-[900px] bg-white rounded-md shadow-md items-center">
        <img className="flex w-[12%] max-w-[80px]" src={Logo} alt="Logo" />
        <span className="flex text-[21px] 2xl:text-[25px] leading-snug text-center font-medium max-w-[80%]">
          Bienvenue sur votre espace personnel dédié à la gestion de vos
          établissements
        </span>
        <div
          style={{
            display: managerRoles.length !== 0 ? "flex" : "none",
          }}
          className="flex flex-row w-full space-x-4"
        >
          <div className="flex items-center flex-row space-x-3 rounded-md py-3 px-3 bg-whitegrey/60 border-1 border-darkgrey flex-1 max-w-[900px]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5 stroke-darkgrey/50"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
              />
            </svg>
            <input
              className="flex flex-1 h-full outline-none bg-transparent"
              placeholder={"Rechercher un établissement"}
              onChange={(e) => {
                setSearch(e.currentTarget?.value ?? "");
              }}
            />
          </div>
          <button
            className="flex px-4 flex-row space-x-2 py-[10px] items-center hover:bg-whitegrey/20 active:bg-whitegrey/25 border border-darkgrey/90 rounded-md text-darkgrey/90"
            onClick={() => setIsOpenNewCompany(true)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              strokeWidth={3}
              className="w-5 h-5"
            >
              <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
            </svg>
            <span className="flex font-medium">Ajouter</span>
          </button>
        </div>
        {managerRoles.length !== 0 && (
          <div className="flex flex-col w-full gap-1">
            <div className="flex flex-row justify-between">
              <div className="flex flex-row">
                <span className="flex pl-3">Etablissement</span>
              </div>
              <div className="flex flex-row gap-4">
                <span
                  id="CompaniesContainerSpanStatus"
                  className="flex pr-[10px]"
                >
                  Status
                </span>
              </div>
            </div>
            <span className="flex flex-col space-y-1 overflow-y-auto max-h-[23vh] py-1 px-1">
              {managerRoles
                .filter((f) =>
                  search.length !== 0
                    ? f.role_company.company_name
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    : true
                )
                .map((role, index) => {
                  return <CompanyItem key={index} role={role} />;
                })}
              {managerRoles.filter((f) =>
                search.length !== 0
                  ? f.role_company.company_name
                      .toLowerCase()
                      .includes(search.toLowerCase())
                  : true
              ).length === 0 && (
                <div className="flex h-[55px] items-center pl-3 bg-whitegrey/60 rounded-md">
                  <span className="flex">
                    Desole, vous n'avez aucun établissement inscrit a ce nom
                  </span>
                </div>
              )}
            </span>
          </div>
        )}
        {managerRoles.length === 0 && (
          <button
            className="flex px-5 py-[10px] items-center mt-4 mb-10 space-x-3 transition-colors bg-primary hover:bg-hover active:bg-active rounded-md text-white"
            onClick={() => setIsOpenNewCompany(true)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              strokeWidth={3}
              className="w-5 h-5"
            >
              <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
            </svg>
            <span className="flex font-medium">Ajouter un établissement</span>
          </button>
        )}
      </div>
      <ModalCustom
        handleClose={closeNewCompany}
        open={isOpenNewCompany}
        zeroPadding
      >
        <NewCompanyModal close={closeNewCompany} />
      </ModalCustom>
    </div>
  );
};

export default CompaniesContainer;
